import React, { useMemo } from "react";
import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
} from "react-router-dom";
import { RequestApi } from "./common/Constant";
import Layout from "./common/components/Layout/Layout";
import CreateCustomerPage from "./features/CreateCustomerPage";
import CustomerList from "./features/CustomerList";
import Dashboard from "./features/Dashboard";
import FinishedReports from "./features/FinishedReports/FinishedReports";
import GeneratedLeads from "./features/GeneratedLeads/GeneratedLeads";
import GeneratedOpportunities from "./features/GeneratedOpportunities/GeneratedOpportunities";
import TestPage from "./features/TestPage";
import UploadFinishedReportsForAllAudits from "./features/UploadFinishedReportsForAllAudits";
import { useRequest } from "./services/agent";
import UserManagement from "./features/UserManagement";
import CreateNewUser from "./features/CreateNewUser";
import { Role } from "./common/models/roles";
import UnAuthorized from "./features/UnAuthorized";
import path from "path/win32";
import { useMsal } from "@azure/msal-react";
import { PageConstants } from "./common/PageConstants";
import PermissionService from "./services/PermissionService";
import { OperationPermissions } from "./common/models/operationPermission";
import { RoutingConstants } from "./common/RoutingConstants";
import LinkProjectUpload from "./features/LinkProjectUpload";
import UpdateOngoingProject from "./features/UpdateOngoingProject";
import Information from "./features/Information";
import OngoingProject from "./features/OngoingProject";
import { UserRoleConstants } from "./common/UserRoleConstants";
import FseUploadProjectData from "./features/FseUploadProjectData";
import AddProjectData from "./features/AddProjectData";

export default function Router() {
  const request = useRequest();

  async function selectListLoader() {
    const countryName = window.sessionStorage.getItem("con");
    const isFSEUser =
      getUserRoleId() == UserRoleConstants.FIELD_SERVICE_ENGINEER;
    const makeRequest = () => {
      return request({
        url: isFSEUser
          ? RequestApi.GET_UPLOAD_ONGOING_REPORTS_SELECT_LIST
          : RequestApi.GET_UPLOAD_FINISHED_REPORTS_SELECT_LIST,
        method: isFSEUser ? "get" : "post",
        data: { countryName },
      });
    };

    try {
      const resp = await makeRequest();
      return resp.data;
    } catch (err: any) {
      if (err.response && err.response.status === 401) {
        const newResponse = await makeRequest();
        return newResponse.data;
      }

      return null;
    }
  }
  async function ongoingProjectLoader() {
    const makeRequest = () => {
      return request({
        url: RequestApi.GET_UPLOAD_ONGOING_REPORTS_SELECT_LIST,
        method: "get",
      });
    };

    try {
      const resp = await makeRequest();
      return resp.data;
    } catch (err: any) {
      if (err.response && err.response.status === 401) {
        const newResponse = await makeRequest();
        return newResponse.data;
      }

      return null;
    }
  }

  async function userManagementLoader() {
    const makeRequest = () => {
      return request({
        url: RequestApi.GET_USER_INPUT_LIST,
        method: "get",
      });
    };

    try {
      const resp = await makeRequest();
      return resp.data;
    } catch (err: any) {
      if (err.response && err.response.status === 401) {
        const newResponse = await makeRequest();
        return newResponse.data;
      }

      return null;
    }
  }

  const ProtectedRoute = ({ viewPermission }: any) => {
    const msal = useMsal();
    const userName = msal.accounts[0]?.username;
    if (userName && viewPermission) {
      return (
        <>
          <Layout />
        </>
      );
    } else {
      // Redirect to UnAuthorize page
      return <UnAuthorized />;
    }
  };

  const getUserRoleId = () => {
    const decodedData = atob(sessionStorage.getItem("ud") || "");
    const data = decodedData ? JSON.parse(decodedData) : "";
    return data.role?.key;
  };

  const viewPermission =
    getUserRoleId() === UserRoleConstants.FIELD_SERVICE_ENGINEER
      ? PermissionService(PageConstants.OngoingProject).view
      : PermissionService(PageConstants.Dashboard).view
      ? PermissionService(PageConstants.Dashboard).view
      : PermissionService(PageConstants.NewProject).view;

  const router = createBrowserRouter([
    {
      path: "/",
      children: [
        {
          element: <ProtectedRoute viewPermission={viewPermission} />,
          children: [
            {
              path: "",
              element:
                getUserRoleId() === UserRoleConstants.FIELD_SERVICE_ENGINEER ? (
                  <Navigate to={`/${RoutingConstants.OngoingProject}`} />
                ) : PermissionService(PageConstants.Dashboard).view ? (
                  <Dashboard />
                ) : (
                  <Navigate to={`/${RoutingConstants.NewProject}`} />
                ),
              index:
                getUserRoleId() === UserRoleConstants.FIELD_SERVICE_ENGINEER
                  ? PermissionService(PageConstants.OngoingProject).view
                  : PermissionService(PageConstants.Dashboard).view,
            },
          ],
        },
        {
          path: RoutingConstants.NewProject,
          element: (
            <ProtectedRoute
              viewPermission={PermissionService(PageConstants.NewProject).view}
            />
          ),
          children: [
            {
              path: "",
              element: <CustomerList />,
            },
          ],
        },
        {
          path: "crud-test",
          element: <ProtectedRoute viewPermission={undefined} />,
          children: [
            {
              path: "",
              element: <TestPage />,
            },
          ],
        },
        {
          path: RoutingConstants.FinishedReports,
          element: (
            <ProtectedRoute
              viewPermission={
                PermissionService(PageConstants.FinishedReports).view
              }
            />
          ),
          children: [
            {
              path: "",
              element: <FinishedReports />,
            },
          ],
        },
        {
          path: RoutingConstants.GeneratedLeads,
          element: (
            <ProtectedRoute
              viewPermission={
                PermissionService(PageConstants.GeneratedLeads).view
              }
            />
          ),
          children: [
            {
              path: "",
              element: <GeneratedLeads />,
            },
          ],
        },
        {
          path: RoutingConstants.GeneratedOpportunities,
          element: (
            <ProtectedRoute
              viewPermission={
                PermissionService(PageConstants.GeneratedOpportunities).view
              }
            />
          ),
          children: [
            {
              path: "",
              element: <GeneratedOpportunities />,
            },
          ],
        },
        {
          path: RoutingConstants.Information,
          element: (
            <ProtectedRoute
              viewPermission={
                PermissionService(PageConstants.GeneratedOpportunities).view
              }
            />
          ),
          children: [
            {
              path: "",
              element: <Information />,
            },
          ],
        },
        {
          path: RoutingConstants.OngoingProject,
          children: [
            {
              element: (
                <ProtectedRoute
                  viewPermission={
                    PermissionService(PageConstants.OngoingProject).view
                  }
                />
              ),
              children: [
                {
                  path: "",
                  index: true,
                  element: <OngoingProject />,
                },
              ],
            },
            {
              path: RoutingConstants.UpdateOngoingProject + "/:auditId",
              element: (
                <ProtectedRoute
                  viewPermission={
                    PermissionService(PageConstants.OngoingProject).view
                  }
                />
              ),
              children: [
                {
                  path: "",
                  element:
                    getUserRoleId() ===
                    UserRoleConstants.FIELD_SERVICE_ENGINEER ? (
                      <FseUploadProjectData />
                    ) : (
                      <UpdateOngoingProject />
                    ),
                  loader: ongoingProjectLoader,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "/" + RoutingConstants.NewProject,
      children: [
        {
          element: (
            <ProtectedRoute
              viewPermission={PermissionService(PageConstants.NewProject).view}
            />
          ),
          children: [
            {
              path: "",
              index: true,
              element: <CustomerList />,
            },
          ],
        },
        {
          path: RoutingConstants.CustomerCreate,
          element: (
            <ProtectedRoute
              viewPermission={PermissionService(PageConstants.NewProject).view}
            />
          ),
          children: [
            {
              path: "",
              element: <CreateCustomerPage />,
            },
          ],
        },
        {
          path: RoutingConstants.UploadFinishedReport,
          element: (
            <ProtectedRoute
              viewPermission={
                PermissionService(PageConstants.FinishedReports).view
              }
            />
          ),
          children: [
            {
              path: "",
              element: <UploadFinishedReportsForAllAudits />,
              loader: selectListLoader,
            },
          ],
        },
        {
          path: RoutingConstants.UpdateFinishedReport + "/:auditId",
          element: (
            <ProtectedRoute
              viewPermission={
                PermissionService(PageConstants.FinishedReports).view
              }
            />
          ),
          children: [
            {
              path: "",
              element: <UploadFinishedReportsForAllAudits />,
              loader: selectListLoader,
            },
          ],
        },
        {
          path: RoutingConstants.LinkProjectUpload,
          element: (
            <ProtectedRoute
              viewPermission={PermissionService(PageConstants.NewProject).view}
            />
          ),
          children: [
            {
              path: "",
              element: <LinkProjectUpload />,
              loader: selectListLoader,
            },
          ],
        },
        {
          path: RoutingConstants.LinkProjectUpdate + "/:auditId",
          element: (
            <ProtectedRoute
              viewPermission={PermissionService(PageConstants.NewProject).view}
            />
          ),
          children: [
            {
              path: "",
              element: <LinkProjectUpload />,
              loader: selectListLoader,
            },
          ],
        },
        {
          path: RoutingConstants.UploadOngoingProject,
          element: (
            <ProtectedRoute
              viewPermission={PermissionService(PageConstants.NewProject).view}
            />
          ),
          children: [
            {
              path: "",
              element: <FseUploadProjectData />,
              loader: selectListLoader,
            },
          ],
        },
        {
          path: RoutingConstants.AddProjectData,
          element: (
            <ProtectedRoute
              viewPermission={PermissionService(PageConstants.NewProject).view}
            />
          ),
          children: [
            {
              path: "",
              element: <AddProjectData />,
              loader: selectListLoader,
            },
          ],
        },
      ],
    },
    {
      path: "/" + RoutingConstants.UserManagement,
      children: [
        {
          element: (
            <ProtectedRoute
              viewPermission={
                PermissionService(PageConstants.UserManagement).view
              }
            />
          ),
          children: [
            {
              path: "",
              index: true,
              element: <UserManagement />,
              loader: userManagementLoader,
            },
          ],
        },
        {
          path: RoutingConstants.NewUser,
          element: (
            <ProtectedRoute
              viewPermission={
                PermissionService(PageConstants.UserManagement).view
              }
            />
          ),
          children: [
            {
              path: "",
              element: <CreateNewUser />,
              loader: userManagementLoader,
            },
          ],
        },
        {
          path: RoutingConstants.EditUser + "/:userId",
          element: (
            <ProtectedRoute
              viewPermission={
                PermissionService(PageConstants.UserManagement).view
              }
            />
          ),
          children: [
            {
              path: "",
              element: <CreateNewUser />,
              loader: userManagementLoader,
            },
          ],
        },
      ],
    },
    {
      path: "/" + RoutingConstants.UnAuthorize,
      element: <UnAuthorized />,
    },
  ]);

  return <RouterProvider router={router} />;
}
