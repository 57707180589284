export const GlobalVariable = Object.freeze({
  BASE_API_URL: process.env.REACT_APP_API_URL + "api",
});
console.log("BASE_API_URL : " + GlobalVariable.BASE_API_URL);

console.log("BASE_API_URL : " + GlobalVariable.BASE_API_URL);

export const RequestApi = Object.freeze({
  GET_UPLOAD_FINISHED_REPORTS_SELECT_LIST:
    GlobalVariable.BASE_API_URL + "/UploadFinishedReports/GetSelectList",

  GET_RECOMMENDATIONS_SELECT_LIST:
    GlobalVariable.BASE_API_URL +
    "/UploadFinishedReports/GetRecommendationsSelectList",

  POST_UPLOAD_FINISHED_REPORTS:
    GlobalVariable.BASE_API_URL + "/UploadFinishedReports/Insert",

  GET_UPLOAD_FINISHED_REPORT_LIST:
    GlobalVariable.BASE_API_URL +
    "/UploadFinishedReports/GetUploadFinishedReportsList",

  GET_ADDED_RECOMMENDATION_LIST:
    GlobalVariable.BASE_API_URL +
    "/UploadFinishedReports/GetAuditRecommendationList",

  GET_AIR_AUDIT_FINISHED_REPORT_LIST:
    GlobalVariable.BASE_API_URL +
    "/FinishedReports/GetAirAuditFinishedReportsList",

  GET_VIBRATION_AUDIT_FINISHED_REPORT_LIST:
    GlobalVariable.BASE_API_URL +
    "/FinishedReports/GetVibrationAuditFinishedReportsList",

  GET_FINISHED_REPORT_DETAILS_BY_ID:
    GlobalVariable.BASE_API_URL +
    "/FinishedReports/GetFinishedReportDetailsById",

  GET_LINK_PROJECT_FINISHED_REPORT_DETAILS_BY_ID:
    GlobalVariable.BASE_API_URL +
    "/FinishedReports/GetLinkProjectFinishedReportDetailsById",

  POST_UPDATE_FINISHED_REPORTS:
    GlobalVariable.BASE_API_URL + "/FinishedReports/Update",

  SEND_RECOMMENDATIONS_TO_C4C:
    GlobalVariable.BASE_API_URL + "/FinishedReports/SendRecommendationsToC4C",

  GET_AUDIT_REPORT_LIST:
    GlobalVariable.BASE_API_URL + "/UploadFinishedReports/GetAuditReportList",

  POST_INSERT_RECOMMENDATION_BY_AUDITID:
    GlobalVariable.BASE_API_URL + "/FinishedReports/InsertRecommendation",
  DELETE_AUDIT_DETAILS_BY_AUDITID:
    GlobalVariable.BASE_API_URL + "/FinishedReports/DeleteAuditDetailsById",

  Get_ALLCOUNTRIES: GlobalVariable.BASE_API_URL + "/Countries/GetAllCountries",

  GET_USER_LIST: GlobalVariable.BASE_API_URL + "/User/GetUsersList",

  ADD_USER: GlobalVariable.BASE_API_URL + "/User/Add",

  UPDATE_USER: GlobalVariable.BASE_API_URL + "/User/Update",

  GET_USER_BY_ID: GlobalVariable.BASE_API_URL + "/User/GetUserById?Id=",

  GET_AIR_AUDIT_GENERATED_LEADS:
    GlobalVariable.BASE_API_URL +
    "/GeneratedLeads/GetAirAuditGeneratedLeadsList",

  GET_VIBRATION_GENERATED_LEADS:
    GlobalVariable.BASE_API_URL +
    "/GeneratedLeads/GetVibrationAuditGeneratedLeadsList",

  GET_AIR_AUDIT_GENERATED_OPPORTUNITIES:
    GlobalVariable.BASE_API_URL +
    "/GeneratedOpportunies/GetAirAuditGeneratedOpportuniesList",

  GET_VIBRATION_GENERATED_OPPORTUNITIES:
    GlobalVariable.BASE_API_URL +
    "/GeneratedOpportunies/GetVibrationAuditGeneratedOpportuniesList",

  GET_OPPORTUNITY_RECOMMENDATIONS:
    GlobalVariable.BASE_API_URL +
    "/GeneratedOpportunies/GetOpportunityRecommendations",

  GET_OPPORTUNITY_RECOMMENDATION_BY_ID:
    GlobalVariable.BASE_API_URL +
    "/GeneratedOpportunies/GetOpportunityRecommendationsDetailsById",

  ADD_OPPORTUNITY_RECOMMENDATION:
    GlobalVariable.BASE_API_URL + "/GeneratedOpportunies/CreateRecommendations",

  UPDATE_OPPORTUNITY_RECOMMENDATION:
    GlobalVariable.BASE_API_URL + "/GeneratedOpportunies/UpdateRecommendations",

  DELETE_OPPORTUNITY_RECOMMENDATION_BY_ID:
    GlobalVariable.BASE_API_URL +
    "/GeneratedOpportunies/DeleteOpportunityRecommendationsDetailsById",

  GET_USER_INPUT_LIST:
    GlobalVariable.BASE_API_URL + "/User/GetUsersManagementList",

  USER_LOGIN: GlobalVariable.BASE_API_URL + "/User/login",
  UPDATE_AUDIT_RECOMMENDATION:
    GlobalVariable.BASE_API_URL + "/FinishedReports/UpdateRecommendation",

  GET_ALL_Release_Notes:
    GlobalVariable.BASE_API_URL + "/ReleaseNotes/GetAllReleaseNotes",

  GET_LINK_PROJECT_LIST:
    GlobalVariable.BASE_API_URL + "/UploadFinishedReports/GetLinkProjectList",

  GET_AIR_NET_AUDIT_FINISHED_REPORT_LIST:
    GlobalVariable.BASE_API_URL +
    "/FinishedReports/GetAirNetAuditFinishedReportsList",
  ADD_Create_Project:
    GlobalVariable.BASE_API_URL + "/UploadFinishedReports/InsertLinkProject",
  POST_LINK_RECOMMENDATION:
    GlobalVariable.BASE_API_URL +
    "/UploadFinishedReports/LinkRecommendationWithOppOrLead",
  UPDATE_LINK_Project:
    GlobalVariable.BASE_API_URL + "/UploadFinishedReports/UpdateLinkProject",
  GET_AIR_NET_AUDIT_GENERATED_LEADS:
    GlobalVariable.BASE_API_URL +
    "/GeneratedLeads/GetAirNetAuditGeneratedLeadsList",
  GET_AIR_NET_AUDIT_GENERATED_OPPORTUNITIES:
    GlobalVariable.BASE_API_URL +
    "/GeneratedOpportunies/GetAirNetAuditGeneratedOpportuniesList",
  GET_AIR_AUDIT_ONGOING_REPORT_LIST:
    GlobalVariable.BASE_API_URL +
    "/OngoingReport/GetAirAuditOngoingReportsList",

  ADD_CUSTOMER: GlobalVariable.BASE_API_URL + "/OngoingReport/AddCustomer",
  GET_ENERGY_RECOVERY_AUDIT_FINISHED_REPORT_LIST:
    GlobalVariable.BASE_API_URL +
    "/FinishedReports/GetEnergyRecoveryAuditFinishedReportsList",
  GET_ENERGY_RECOVERY_AUDIT_GENERATED_LEADS:
    GlobalVariable.BASE_API_URL +
    "/GeneratedLeads/GetEnergyRecoveryAuditGeneratedLeadsList",
  GET_ENERGY_RECOVERY_AUDIT_GENERATED_OPPORTUNITIES:
    GlobalVariable.BASE_API_URL +
    "/GeneratedOpportunies/GetEnergyRecoveryAuditGeneratedOpportuniesList",
  ADD_ENERGY_SPECIALIST:
    GlobalVariable.BASE_API_URL + "/OngoingReport/AssignEnergySpecialist",
  GET_FAM_CODES_LIST:
    GlobalVariable.BASE_API_URL + "/OngoingReport/GetFamCodesList",
  ADD_FSE_AUDIT_DATA:
    GlobalVariable.BASE_API_URL + "/OngoingReport/AddAuditData",
  ADD_NON_FSE_AUDIT_DATA:
    GlobalVariable.BASE_API_URL + "/OngoingReport/AddAuditDataByNonFSE",
  GET_UPLOAD_ONGOING_REPORTS_SELECT_LIST:
    GlobalVariable.BASE_API_URL + "/OngoingReport/GetSelectList",
  EDIT_FEE_AUDIT_DATA:
    GlobalVariable.BASE_API_URL + "/OngoingReport/UpdateOngoingReport",
  GET_FSE_AUDIT_DATA_BY_ID:
    GlobalVariable.BASE_API_URL + "/OngoingReport/GetOngoingProjectById",
  EDIT_ONGOING_PROJECT_BY_NON_FSE:
    GlobalVariable.BASE_API_URL + "/OngoingReport/UpdateOngoingReportForNonFSE",
  GET_NON_FSE_USERS:
    GlobalVariable.BASE_API_URL + "/OngoingReport/GetNonFSEUsersList",
  GET_ONGOING_PROJECT_FOR_NON_FSE_BY_ID:
    GlobalVariable.BASE_API_URL +
    "/OngoingReport/GetOngoingProjectByIdForNonFSE",
  Get_Latest_Release_Note:
    GlobalVariable.BASE_API_URL + "/ReleaseNotes/GetLatestReleaseNote",
  POST_DOWNLOAD_FILE: GlobalVariable.BASE_API_URL + "/Common/Downloadfile",
  UPDATE_LEAD_FOLLOW_UP_DATE:
    GlobalVariable.BASE_API_URL + "/GeneratedLeads/SetLeadFollowUp",
  UPDATE_OPPORTUNITY_FOLLOW_UP_DATE:
    GlobalVariable.BASE_API_URL +
    "/GeneratedOpportunies/SetOpportunityFollowUp",
  POST_GET_ALL_NOTIFICATION:
    GlobalVariable.BASE_API_URL + "/Notification/GetAll",
  GET_UNREAD_NOTIFICATION_COUNT:
    GlobalVariable.BASE_API_URL + "/Notification/GetUnreadNotificationCount",
  DELETE_CLEAR_ALL_NOTIFICATION:
    GlobalVariable.BASE_API_URL + "/Notification/ClearAll",
  POST_MARK_NOTIFICATION_AS_READ:
    GlobalVariable.BASE_API_URL + "/Notification/MarkAsRead",
  DOWNLOAD_LEADS_WITH_NO_STATUS_CHANGE:
    GlobalVariable.BASE_API_URL + "/Notification/DownloadLeadReports",
  DOWNLOAD_OPPORTUNITIES_WITH_NO_STATUS_CHANGE:
    GlobalVariable.BASE_API_URL + "/Notification/DownloadOpportunityReports",
});

export const graphApi = Object.freeze({
  bearer: "Bearer ",
  photoUrlPath: "/photo/$value",
  load: "load",
  url: {
    base: "https://graph.microsoft.com",
    myPhoto: "https://graph.microsoft.com/v1.0/me/photos/240x240/$value",
  },
  permission: {
    userRead: "User.Read",
  },
});
