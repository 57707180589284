import {
  Link,
  useLoaderData,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { RequestApi } from "../common/Constant";
import React, {
  useEffect,
  useState,
  KeyboardEvent,
  useMemo,
  useRef,
} from "react";
import State from "../common/interface/DragDrop";
import * as Yup from "yup";
import {
  FormProvider,
  useForm,
  useFieldArray,
  Controller,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ConfirmationDialog, {
  CustomConfirmationDialogProps,
} from "../common/components/CustomComponents/ConfirmationDialog";
import { ReactComponent as UploadReportIcon } from "../assets/icons/upload_report.svg";
import {
  Accordion,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid,
  ListItemText,
  ListSubheader,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";
import classes from "./UploadFinishedReports.module.css";
import BackArrowIcon from "./../assets/icons/back_arrow-icon.svg";
import AddIcon from "./../assets/icons/Add_Circle_icon.svg";
import VectorIcon from "./../assets/icons/Vector.svg";
import DropdownModel from "../common/interface/DropdownModel";
import CustomInput from "../common/components/CustomComponents/CustomInput";
import CustomSelect from "../common/components/CustomComponents/CustomSelect";
import CustomRange from "../common/components/CustomComponents/CustomRange";
import CustomAlertTest from "../common/components/CustomComponents/CustomAlert";
import { GridBreak } from "../common/components/CustomComponents/GridBreak";
import { useMsal } from "@azure/msal-react";
import ExcelFileIcon from "./../assets/icons/excel.svg";
import PDFFileIcon from "./../assets/icons/pdf_icon.svg";
import WordFileIcon from "./../assets/icons/word_doc.svg";
import PPTFileIcon from "./../assets/icons/ppt_icon.svg";
// import CloseIcon from  "./../assets/icons/close.svg" //"./../../../assets/icons/close.svg";
import DragDrop from "../common/components/CustomComponents/DragDrop";
import { useSelector } from "react-redux";
import { RootState } from "../common/store";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ReactComponent as ExpandMoreIcon } from "../assets/icons/down-arrow.svg";
import EditIcon from "../assets/icons/edit-icon.svg";
import DeleteIcon from "../assets/icons/delete-icon.svg";
import CloseIcon from "../assets/icons/close.svg";
import Exclamation from "../assets/icons/Red_Exclaimation_mark.svg";
import UploadIcon from "../assets/icons/Upload_icon.svg";
import { useRequest } from "../services/agent";
import { useNavigate } from "react-router-dom";
import ConfirmDelete from "../common/components/CustomComponents/ConfirmDelete";
import downloadIcon from "../assets/icons/download_light_gray.svg";
import AddRecommendations from "./AddRecommendations";
import {
  AuditRecommendations,
  addAllRecommendations,
  deleteRecommendations,
  deleteAllRecommendations,
  hideSaveAlert,
} from "../common/store/addRecommendationsDataSlice";
import dayjs from "dayjs";
import { useAppDispatch } from "../common/hooks/hooks";
import CustomConfirmationDialog from "../common/components/CustomComponents/ConfirmationDialog";
import loaderDataSlice, {
  hideLoader,
  showLoader,
} from "../common/store/loaderDataSlice";
import { showConfirmation } from "../common/store/confirmationBoxDataSlice";
import { json } from "body-parser";
import AddRecommendationsdailog from "../common/components/CustomComponents/AddRecommendationsdailog";
import {
  AuditAirNetTypes,
  AuditAirScanTypes,
  AuditEnergyRecoveryTypes,
  AuditTypes,
  AuditVibrationTypes,
  ShowAuditTypesOnTabConstants,
} from "../common/AuditTypeConstants";
import { PageConstants } from "../common/PageConstants";
import { OperationPermissions } from "../common/models/operationPermission";
import PermissionService from "../services/PermissionService";
import { RoutingConstants } from "../common/RoutingConstants";
import { ProjectSourceConstants } from "../common/ProjectSourceConstants";
import CustomMultiSelectNew from "../common/components/CustomComponents/CustomMultiSelectNew";
import CustomInputSelect from "../common/components/CustomComponents/CustomInputSelect";
// let AuditGroupTypes: any = [];

export type recommendationTableColumns =
  | "investmentName"
  | "recommendationName"
  | "description"
  | "aproxInvestment"
  | "minimumEstimatedSaving"
  | "paybackMonths"
  | "followUpDate"
  | "comments";
export interface RcommendationsColumn {
  id: recommendationTableColumns; //keyof typeof tableColumns;
  label: string;
  minWidth?: number;
  align?: "right" | "left" | "center";
  format?: (value: number) => string;
}

interface IAuditClassification {
  auditGroup: string;
  classification: DropdownModel[];
}

const defaultIsSubCategory = { isAirQuality: false, isEnergyPotential: false };

const UploadFinishedReportsForAllAudits = () => {
  const selectList = useLoaderData() as any;
  const [selectedProject, setSelectedProject] = useState<string | undefined>();
  const [multiSelectOptions, setMultiSelectOptions] = useState<any[]>([]);
  const [isAirscan, setIsAirscan] = useState<boolean>(true);
  const [isAirQuality, setIsAirQuality] = useState<boolean>(false);
  const [isOtherAuditType, setIsOtherAuditType] = useState<boolean>(true);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const navigation = useNavigate();
  const [error, setErrorMsg] = React.useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const request = useRequest();
  const [selectedSubCategory, setSelectedSubCategory] = useState<any[]>([]);
  const [isSubCategory, setIsSubCategory] = useState(defaultIsSubCategory);

  const [savedFiles, setSavedFiles] = useState([]);
  const [deletedFileIds, setDeletedFileIds] = useState<number[]>([]);

  const params = useParams();
  const { auditId } = params;
  const isEditReportPage = auditId && !isNaN(+auditId) && +auditId > 0;

  // let { c4cId, customerName } = useSelector(
  //   (state: RootState) => state.uploadFinishedReportsReducer
  // );

  const [c4cId, setC4CId] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const finishedReportPermission: OperationPermissions = PermissionService(
    PageConstants.FinishedReports
  );
  const [soldTo, setSoldTo] = useState("");
  const [searchParams] = useSearchParams();
  let unchecked = false;
  function setFromURL() {
    setC4CId(window.sessionStorage.getItem("ci") || "");
    setCustomerName(window.sessionStorage.getItem("cn") || "");
    setCity(window.sessionStorage.getItem("ct") || "");
    setCountry(window.sessionStorage.getItem("con") || "");
    setAddress(window.sessionStorage.getItem("ad") || "");
    setSoldTo(window.sessionStorage.getItem("st") || "");
  }
  useEffect(() => {
    if (!isEditReportPage) {
      dispatchRecommendations(deleteAllRecommendations());
    }
  }, []);
  function setDefaultValues() {
    if (selectList?.famCodeList?.length === 1) {
      methods.setValue("famCodeId", selectList?.famCodeList[0].valueInt);
    }
    if (selectList?.energyUnitList?.length === 1) {
      methods.setValue("energyUnitId", selectList?.energyUnitList[0].valueInt);
    }
  }

  let custDetails: CustomerData = {
    customerName: window.sessionStorage.getItem("cn") || "",
    c4CId: window.sessionStorage.getItem("ci") || "",
    city: window.sessionStorage.getItem("ct") || "",
    country: window.sessionStorage.getItem("con") || "",
    address: window.sessionStorage.getItem("ad") || "",
    soldTo: window.sessionStorage.getItem("st") || "",
  };

  useEffect(() => {
    if (isEditReportPage) {
      LoadAuditDetailsById();
    } else {
      setFromURL();
      setDefaultValues();
      methods.setValue(
        "responsiblePersonId",
        msal.accounts[0].name == undefined ? "" : msal.accounts[0].name
      );
    }
  }, []);

  const AuditGroupTypes = useMemo(() => {
    return (selectList?.typeOfAuditList || [])
      .filter((auditType: any) => {
        if (
          auditType.auditGroup?.toLowerCase() ==
            ShowAuditTypesOnTabConstants.Enery_Recovery.toLowerCase() ||
          auditType.auditGroup?.toLowerCase() ==
            ShowAuditTypesOnTabConstants.Airnet.toLowerCase() ||
          auditType.auditGroup?.toLowerCase() ==
            ShowAuditTypesOnTabConstants.Vibration_Audit.toLowerCase()
        ) {
          return;
        }

        return auditType;
      })
      .map((auditType: any) => {
        const auditGroupLower = auditType.auditGroup?.toLowerCase();
        let updatedAuditGroup = auditType.auditGroup;

        if (
          auditGroupLower ===
          ShowAuditTypesOnTabConstants.Air_Scan.toLowerCase()
        ) {
          updatedAuditGroup = ShowAuditTypesOnTabConstants.Air_Scan;
        } else if (
          auditGroupLower === ShowAuditTypesOnTabConstants.Airnet.toLowerCase()
        ) {
          updatedAuditGroup = ShowAuditTypesOnTabConstants.Airnet;
        }

        return {
          ...auditType,
          auditGroup: updatedAuditGroup,
        };
      }) as IAuditType[];
  }, [selectList?.typeOfAuditList]);

  const normalize = (value: string | undefined): string =>
    value?.toLocaleLowerCase().trim() || "";

  const isAirScan = useMemo(
    () =>
      normalize(selectedProject) ===
      normalize(ShowAuditTypesOnTabConstants.Air_Scan),
    [selectedProject]
  );

  const isAirnet = useMemo(
    () =>
      normalize(selectedProject) ===
      normalize(ShowAuditTypesOnTabConstants.Airnet),
    [selectedProject]
  );

  const isEnergyRecovery = useMemo(
    () =>
      normalize(selectedProject) ===
      normalize(ShowAuditTypesOnTabConstants.Enery_Recovery),
    [selectedProject]
  );

  const isVibration = useMemo(
    () =>
      normalize(selectedProject) ===
      normalize(ShowAuditTypesOnTabConstants.Vibration_Audit),
    [selectedProject]
  );

  let mappedData: DropdownModel[] = AuditGroupTypes?.map(
    (item: IAuditType, index) => {
      return { text: item.auditGroup, valueInt: index, value: "" };
    }
  );
  const findAuditGroup = (ids: number[]): string | null => {
    console.log(ids);
    const groups = {
      [ShowAuditTypesOnTabConstants.Air_Scan]: Object.values(
        AuditAirScanTypes
      ).filter((value) => typeof value === "number") as number[],
      [ShowAuditTypesOnTabConstants.Vibration_Audit]: Object.values(
        AuditVibrationTypes
      ).filter((value) => typeof value === "number") as number[],
      [ShowAuditTypesOnTabConstants.Airnet]: Object.values(
        AuditAirNetTypes
      ).filter((value) => typeof value === "number") as number[],
      [ShowAuditTypesOnTabConstants.Enery_Recovery]: Object.values(
        AuditEnergyRecoveryTypes
      ).filter((value) => typeof value === "number") as number[],
    };

    for (const id of ids) {
      for (const [auditGroup, values] of Object.entries(groups)) {
        if (values.includes(id)) {
          return auditGroup;
        }
      }
    }

    return null;
  };

  const handleAuditGroup = (
    auditGroup: string,
    typeOfAuditId: number[]
  ): void => {
    console.log(auditGroup);
    let selectedText;
    let IsSubCategory = defaultIsSubCategory;
    if (typeOfAuditId[0] == AuditAirScanTypes.Air_Quality) {
      IsSubCategory = { ...IsSubCategory, isAirQuality: true };
    } else {
      IsSubCategory = { ...IsSubCategory };
    }
    setIsSubCategory(IsSubCategory);

    switch (normalize(auditGroup)) {
      case normalize(ShowAuditTypesOnTabConstants.Air_Scan):
        selectedText = mappedData?.find(
          (e) => e.text === ShowAuditTypesOnTabConstants.Air_Scan
        );
        setSelectedProject(selectedText?.text);
        methods.setValue(
          "categoryOfProject",
          selectedText?.valueInt.toString() || ""
        );
        methods.setValue("typeOfAuditId", typeOfAuditId);
        setSelectedSubCategory(typeOfAuditId.map((e) => e.toString()));

        console.log("first");
        break;

      case normalize(ShowAuditTypesOnTabConstants.Vibration_Audit):
        selectedText = mappedData?.find(
          (e) => e.text === ShowAuditTypesOnTabConstants.Vibration_Audit
        );
        setSelectedProject(selectedText?.text);
        methods.setValue(
          "categoryOfProject",
          selectedText?.valueInt.toString() || ""
        );
        methods.setValue("subCategorySingleSelect", typeOfAuditId[0]);
        break;

      case normalize(ShowAuditTypesOnTabConstants.Enery_Recovery):
        break;

      case normalize(ShowAuditTypesOnTabConstants.Airnet):
        break;

      default:
        break;
    }
  };

  const LoadAuditDetailsById = (): void => {
    request({
      url: `${RequestApi.GET_FINISHED_REPORT_DETAILS_BY_ID}?id=` + auditId,
      method: "get",
    })
      .then((resp) => {
        window.sessionStorage.setItem("cn", resp.data.customerName);
        window.sessionStorage.setItem("ci", resp.data.c4CId);
        if (resp.data.city == null) {
          window.sessionStorage.setItem("ct", "");
        } else {
          window.sessionStorage.setItem("ct", resp.data.city);
        }

        window.sessionStorage.setItem("con", resp.data.country);
        if (resp.data.soldTo == null) {
          window.sessionStorage.setItem("st", "");
        } else {
          window.sessionStorage.setItem("st", resp.data.soldTo);
        }

        window.sessionStorage.setItem("ad", resp.data.address);

        methods.setValue("id", resp.data.id);
        methods.setValue("c4cId", resp.data.c4CId);
        methods.setValue("auditNumber", resp.data.auditNumber);
        methods.setValue("customerName", resp.data.customerName);
        methods.setValue("auditName", resp.data.auditName);
        methods.setValue("brandId", +resp.data.brandId);
        methods.setValue("co2Factor", resp.data.cO2Factor);
        methods.setValue("co2Unit", resp.data.cO2UnitId);
        methods.setValue("criticalResult", resp.data.criticalResult);
        methods.setValue("electricityCost", resp.data.electricityCost);
        methods.setValue("energyUnitId", resp.data.energyUnitId);
        methods.setValue("loadedHours", resp.data.loadedHours);
        methods.setValue("lowResult", resp.data.lowResult);
        methods.setValue("machineModel", resp.data.machineModel);
        methods.setValue("mediumResult", resp.data.mediumResult);
        methods.setValue("runningHours", resp.data.runningHours);
        methods.setValue("serialNumber", resp.data.serialNumber);

        AuditGroupTypes.map((item: IAuditType) => {
          var filtered = item.subGroups?.filter((item) => {
            return item.valueInt === resp.data.typeOfAuditId[0];
          });

          if (filtered?.length > 0) {
            let selectedGroup = mappedData?.find(
              (e) => e.text === item.auditGroup
            );
            methods.setValue(
              "categoryOfProject",
              selectedGroup?.valueInt.toString() || ""
            );
            handleChange(selectedGroup?.valueInt.toString());
            var filteredValue = item.subGroups?.filter((item1) =>
              resp.data.typeOfAuditId.some(
                (item2: any) => item1.valueInt === item2
              )
            );
            let val = filteredValue?.map((item: any, index) => {
              return { cat: item.valueInt, key: item.text };
            });

            if (
              selectedGroup?.text.toLowerCase() !==
                ShowAuditTypesOnTabConstants.Airnet.toLowerCase() &&
              filteredValue !== undefined
            )
              setMultiSelectOptions(filteredValue);
            if (
              selectedGroup?.text.toLowerCase() ==
                ShowAuditTypesOnTabConstants.Air_Scan.toLowerCase() &&
              val !== undefined
            ) {
              if (
                val?.length == 1 &&
                val[0]?.cat == AuditAirScanTypes.Air_Quality
              ) {
                setIsSubCategory({
                  ...defaultIsSubCategory,
                  isAirQuality: true,
                });
              }
              setSelectedSubCategory(val);
            }
          }
        });
        methods.setValue("typeOfAuditId", resp.data.typeOfAuditId);
        methods.setValue("subCategorySingleSelect", resp.data.typeOfAuditId[0]);

        methods.setValue("famCodeId", resp.data.famCodeId);
        resp.data.typeOfAuditId.map((id: number) => {
          if (id === 5) {
            setIsAirscan(false);
          }
          return id;
        });
        filterTypesOfMeasurements(resp.data.typeOfAuditId);
        methods.setValue(
          "typesOfMeasurements",
          resp.data.typeOfMeasurementIds.map((id: number) => id.toString())
        );
        methods.trigger("typesOfMeasurements");
        methods.setValue(
          "measurementsWithQuantity",
          resp.data.typeOfMeasurementDetails
        );
        methods.trigger("measurementsWithQuantity");
        dispatchRecommendations(
          addAllRecommendations(resp.data.recomendations)
        );
        // setRecommendationList(resp.data.recomendations);
        setSavedFiles(resp.data.reports);
        methods.setValue("responsiblePersonId", resp.data.responsible);
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_RESPONSE") {
          setAlert("error", "Request Failed", true);
        } else {
          setAlert("error", "Request Failed", true);
        }
      });
  };

  const [alertSeverity, setAlertSeverity] = useState<"success" | "error">(
    "success"
  );
  const [expandRecommendationList, setExpandRecommendationList] =
    React.useState(true);

  const dispatchRecommendations = useAppDispatch();

  const [typeOfMeasurementsList, setTypeOfMeasurements] = useState<
    DropdownModel[]
  >([]);

  const msal = useMsal();
  let userName = msal.accounts[0]?.name;
  const userId = msal.accounts[0]?.localAccountId as string;

  const [commonDialogProperties, setCommonDialogProperties] =
    React.useState<CustomConfirmationDialogProps>({
      icon: "false",
      title: "",
      content: "",
      onClose: onCloseCancelSave,
      onConfirm: onConfirmCancelSave,
    });

  interface IAuditType {
    auditGroup: string;
    subGroups: DropdownModel[];
  }

  interface CustomerData {
    c4CId: string;
    customerName: string;
    country: string;
    city: string;
    address: string;
    soldTo: string;
  }

  const columns: readonly RcommendationsColumn[] = [
    {
      id: "investmentName" as recommendationTableColumns,
      label: "Category of Product",
      align: "left",
      minWidth: 170,
    },
    {
      id: "recommendationName",
      label: "Type of Product",
      align: "left",
      minWidth: 120,
    },
    {
      id: "description",
      label: "Description of Product",
      minWidth: 250,
      align: "left",
      format: (value: number) => value.toLocaleString("en-US"),
    },
    {
      id: "aproxInvestment",
      label: `Approx. Investment(${
        selectList && selectList?.currencyList?.length > 0
          ? selectList?.currencyList[0]?.text
          : null
      })`,
      minWidth: 170,
      align: "left",
      format: (value: number) => value.toLocaleString("en-US"),
    },
    {
      id: "minimumEstimatedSaving",
      label: "Minimum estimated savings(kWh/annum)",
      minWidth: 170,
      align: "left",
      format: (value: number) => value.toFixed(2),
    },
    {
      id: "comments",
      label: "Comments",
      minWidth: 250,
      align: "left",
    },
  ];

  const [recommendationList, setRecommendationList] = useState<
    AuditRecommendations[]
  >([]);
  let recommendationData = useSelector(
    (state: RootState) => state.auditRecommendationsReducer.recommendations
  );
  let showRecommendationsSaveAlert = useSelector(
    (state: RootState) => state.auditRecommendationsReducer.showSavedAlert
  );

  const [customerData, setCustomerData] = useState<CustomerData>();
  let rows: AuditRecommendations[] = [];

  useEffect(() => {
    setRecommendationList(recommendationData);
    setExpandRecommendationList(true);
    setCustomerData(custDetails);
    if (showRecommendationsSaveAlert) {
      const toasterMsg = `Recommendation Details ${
        isEdit ? "Updated" : "Saved"
      } Successfully`;
      setAlert("success", toasterMsg, true);
      dispatchRecommendations(hideSaveAlert());
    }
  }, [recommendationData]);

  //DragDrop Files Start
  type Action =
    | { type: "SET_DROP_DEPTH"; dropDepth: number }
    | { type: "SET_IN_DROP_ZONE"; inDropZone: boolean }
    | { type: "REMOVED_FILE_TO_LIST"; fileName: string }
    | { type: "ADD_FILE_TO_LIST"; files: File[] };

  const reducer = (state: State, action: Action): State => {
    switch (action.type) {
      case "SET_DROP_DEPTH":
        return { ...state, dropDepth: action.dropDepth };
      case "SET_IN_DROP_ZONE":
        return { ...state, inDropZone: action.inDropZone };
      case "ADD_FILE_TO_LIST":
        return { ...state, fileList: state.fileList.concat(action.files) };
      case "REMOVED_FILE_TO_LIST":
        return {
          ...state,
          fileList: state.fileList.filter((f) => f.name !== action.fileName),
        };
      default:
        return state;
    }
  };
  const [fileData, dispatch] = React.useReducer(reducer, {
    dropDepth: 0,
    inDropZone: false,
    fileList: [],
  });

  //DragDrop Files End

  const validationSchema = Yup.object().shape({
    id: Yup.number().positive(),
    typeOfAuditId: isAirScan
      ? Yup.array().min(1, "This field is required")
      : Yup.array().notRequired(),

    // typeOfAuditId: Yup.array()
    //   .of(Yup.string().required("This Field is required"))
    //   .required("This Field is required")
    //   .test(
    //     "Type of Audit is Required",
    //     (value) => Array.isArray(value) && value.length > 0
    //   ),
    c4cId: Yup.string(),
    customerName: Yup.string(),
    auditNumber: Yup.string(),
    subCategorySingleSelect: isAirScan
      ? Yup.number().nullable()
      : Yup.number().required("This field is required"),
    categoryOfProject: Yup.string()
      .min(1, "This field is required")
      .required("This field is required"),
    auditName: Yup.string()
      .required("This field is required.")
      .max(80, "Maximum 80 Characters are allowed")
      .min(2, "Must include minumum 2 characters")
      .matches(/^[a-z\d\-_\s]+$/i, "Special Characters are not allowed"),
    responsiblePersonId: Yup.string().required("This field is required."),
    electricityCost:
      !isVibration && !isSubCategory.isAirQuality
        ? Yup.number()
            .min(0, "The value must be 0 or more.")
            .required("This field is required.")
        : Yup.number().positive().notRequired(),
    // currencyId:
    //   isAirscan && (!isAirQuality || isOtherAuditType)
    //     ? Yup.number()
    //         .min(1, 'This field is required')
    //         .required('This field is required')
    //     : Yup.number().notRequired(),
    energyUnitId:
      !isVibration && !isSubCategory.isAirQuality
        ? Yup.number()
            .min(1, "This field is required")
            .required("This field is required")
        : Yup.number().notRequired(),
    co2Factor:
      !isVibration && !isSubCategory.isAirQuality
        ? Yup.string().required("This field is required")
        : Yup.string().notRequired(),
    classificationOfProject: isAirnet
      ? Yup.number().required("This field is required")
      : Yup.number().nullable(),

    co2Unit:
      !isVibration && !isSubCategory.isAirQuality
        ? Yup.number()
            .min(1, "This field is required")
            .required("This field is required")
        : Yup.number().notRequired(),
    brandId: isVibration
      ? Yup.number()
          .min(1, "This field is required")
          .required("This field is required")
      : Yup.number().notRequired(),
    serialNumber: isVibration
      ? Yup.string().required("This field is required")
      : Yup.string().notRequired(),
    machineModel: isVibration
      ? Yup.string().required("This field is required")
      : Yup.string().notRequired(),
    runningHours: isVibration
      ? Yup.number().required("This field is required")
      : Yup.number().notRequired(),
    loadedHours: isVibration
      ? Yup.number().required("This field is required")
      : Yup.number().notRequired(),
    lowResult: isVibration
      ? Yup.number().required("This field is required")
      : Yup.number().notRequired(),
    mediumResult: isVibration
      ? Yup.number().required("This field is required")
      : Yup.number().notRequired(),
    criticalResult: isVibration
      ? Yup.number().required("This field is required")
      : Yup.number().notRequired(),
    famCodeId: Yup.number()
      .min(1, "This field is required")
      .required("This field is required"),
    // typesOfMeasurements: Yup.array()
    //   .of(Yup.string())
    //   .required("This field is required")
    //   .test(
    //     "Type of Measurement is Required",
    //     (value) => Array.isArray(value) && value.length > 0
    //   ),
    typesOfMeasurements: Yup.array().min(1, "This field is required"),
    measurementsWithQuantity: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Name is required"),
        id: Yup.number(),
        typeOfMeasurementId: Yup.number(),
        qty: Yup.string()
          .required("Quantity is required")
          .matches(/^\d+$/, "Only numbers are allowed"),
      })
    ),
    uploadedFiles:
      savedFiles?.length === 0
        ? Yup.array().min(1, "This field is required")
        : Yup.array(),
    //customerData: Yup.array().required('This field is required'),
  });

  const isFieldRequired = (fieldName: string): boolean => {
    const field = validationSchema.describe().fields[fieldName] as any;
    return field.tests.some((item: any) => item.name === "required") ?? false;
  };

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      typesOfMeasurements: [] as string[],
      typeOfAuditId: [] as string[],
      co2Unit: 1,
      //responsiblePersonId: msal.accounts[0]?.name,
    },
    mode: "all",
    reValidateMode: "onBlur",
  });

  const control = methods.control;

  const { fields, append, remove } = useFieldArray({
    name: "measurementsWithQuantity",
    control,
  });

  let selectedTypesOfMeasurements = methods.watch(
    "typesOfMeasurements"
  ) as string[];

  let selectedAuditTypes = methods.watch("typeOfAuditId") as string[];

  function setAlert(severity: any, message: string, OCflag: boolean) {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(OCflag);
    handleCloseDialog();
  }

  const convertFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const base64String =
          reader.result === null || undefined ? "," : reader.result;
        let parts = base64String.toString().split(",");
        let cleanedString = "";
        if (parts.length > 1) {
          cleanedString = parts[1];
        }
        resolve(cleanedString.toString()); // Resolve the promise with the Base64 string
      };

      reader.onerror = (error) => {
        reject(error); // Reject the promise if an error occurs
      };

      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    mapFiles();
  }, [fileData]);

  async function mapFiles() {
    let filecollection: File[] = [];
    methods.setValue("uploadedFiles", []);

    if (fileData.fileList && fileData.fileList.length > 0) {
      for (let i = 0; i < fileData.fileList.length; i++) {
        const file = fileData.fileList[i];
        filecollection.push(file);
      }
      methods.setValue("uploadedFiles", filecollection);

      methods.trigger("uploadedFiles");
    }
  }

  useEffect(() => {
    const newVal = selectedTypesOfMeasurements.length;

    if (newVal === 0) {
      remove();
    } else {
      let oldMeasurementIds: string[] = [];
      fields.filter((filter, i) => {
        if (filter.name === undefined) {
          remove(i);
        } else if (
          selectedTypesOfMeasurements.includes(
            String(filter.typeOfMeasurementId)
          )
        ) {
          oldMeasurementIds.push(String(filter.typeOfMeasurementId));
        } else {
          remove(i);
          let lst = methods.getValues("measurementsWithQuantity");
          let index = lst?.findIndex((itm) => {
            return itm.typeOfMeasurementId === filter.typeOfMeasurementId;
          });

          if (index !== undefined && index !== -1) {
            methods.setValue("measurementsWithQuantity", lst?.splice(index, 1));
          }
        }
      });

      let newMeasurementIds = selectedTypesOfMeasurements.filter(
        (x) => !oldMeasurementIds.includes(x)
      );

      if (newMeasurementIds.length !== 0) {
        for (let i = 0; i < newMeasurementIds.length; i++) {
          const name = getTypeOfMeasurementSelectedText(
            newMeasurementIds[i].toString()
          );
          if (name.length > 0) {
            append({
              name,
              qty: "1",
              typeOfMeasurementId: +newMeasurementIds[i],
            });
          }
        }
      }
    }
  }, [selectedTypesOfMeasurements]);

  async function DownloadFile(blobName: string, fileName: string) {
    var body = {
      blobName: blobName,
      fileName: fileName,
    };

    request({
      url: `${RequestApi.POST_DOWNLOAD_FILE}`,
      method: "post",
      data: body,
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setAlert("success", "Downloaded successfully", true);
      })
      .catch((err) => {
        console.log(err, "errr");
        setAlert("error", "Request Failed", true);
      });
  }

  async function onSubmit(data: any) {
    if (recommendationList.length === 0) {
      setAlert("error", "Atleast one recommendation is required", true);
      return;
    }
    if (
      !isAirscan &&
      data.criticalResult === 0 &&
      data.lowResult === 0 &&
      data.mediumResult === 0
    ) {
      setAlert(
        "error",
        "Atleast one Result value should be more than zero",
        true
      );
      return;
    }

    const famcode = selectList?.famCodeList?.filter((item: any) => {
      return item.valueInt === data.famCodeId;
    })[0]?.text;

    if (data.typeOfAuditId && data.typeOfAuditId.length > 0) {
      let isEneryPotentialSelected =
        data.typeOfAuditId?.find(
          (e: any) => +e === AuditAirScanTypes.Energy_Potential_PreSales
        ) !== undefined;
      if (isEneryPotentialSelected) {
        data.typeOfAuditId = [];
        data.typeOfAuditId[0] = AuditAirScanTypes.Energy_Potential_PreSales;
      }
    }
    console.log(data);
    const obj = {
      id: data.id ? data.id : 0,
      typeOfAuditId: isAirScan
        ? data?.typeOfAuditId
        : [data?.subCategorySingleSelect],
      auditName: data.auditName,
      // auditClassificationId: data?.classificationOfProject ?? 0,
      c4CId: c4cId ? c4cId : data.c4cId,
      electricityCost: !isVibration ? data.electricityCost : 0,
      currencyId: selectList?.currencyList[0]?.valueInt,
      energyUnitId: !isVibration ? data.energyUnitId : 0,
      cO2Factor: !isVibration ? data.co2Factor : "0",
      cO2UnitId: !isVibration ? data.co2Unit : 0,
      brandId: isVibration ? data.brandId : 0,
      serialNumber: isVibration ? data.serialNumber : "",
      machineModel: isVibration ? data.machineModel : "",
      runningHours: isVibration ? data.runningHours : 0,
      loadedHours: isVibration ? data.loadedHours : 0,
      criticalResult: isVibration ? data.criticalResult : 0,
      mediumResult: isVibration ? data.mediumResult : 0,
      lowResult: isVibration ? data.lowResult : 0,
      typeOfMeasurementIds: data.typesOfMeasurements,
      reports: data.uploadedFiles,
      typeOfMeasurementDetails: data.measurementsWithQuantity,
      recomendations: recommendationList,
      DeletedReportIds: deletedFileIds,
      Customer: customerData,
      famCodeId: data.famCodeId,
      famCode: famcode,
      projectSource: ProjectSourceConstants.UploadProject,
    };

    console.log(data);

    const formData = new FormData();
    for (let i = 0; i < data.uploadedFiles.length; i++) {
      formData.append("reports", data.uploadedFiles[i]);
    }

    let url = RequestApi.POST_UPLOAD_FINISHED_REPORTS;
    if (isEditReportPage) {
      url = RequestApi.POST_UPDATE_FINISHED_REPORTS;
    }

    setErrorMsg(false);
    setLoading(true);
    request({
      url: url,
      method: "post",
      data: { request: obj, Data: formData },
      headers: {
        "Content-Type": "multipart/form-data",
      },
      maxContentLength: 200 * 1024 * 1024,
    })
      .then((resp) => {
        setLoading(false);
        setSaveOpen(true);
        if (isEditReportPage) {
          setMessage(`Project uploaded successfully!`);
        } else {
          setMessage(
            `Project uploaded successfully!  Project ID : ${resp.data.auditNumber}`
          );
        }

        dispatchRecommendations(deleteAllRecommendations());
      })
      .catch((err) => {
        if (err.response.status === 409) {
          setLoading(false);
        } else {
          setAlert("error", "Request Failed", true);
          setLoading(false);
        }
      });
  }

  const uploadReportIcon = (
    <div className={classes["upload-icon"]}>
      <SvgIcon component={UploadReportIcon}></SvgIcon>
    </div>
  );

  const ExpandIcon = <SvgIcon component={ExpandMoreIcon}></SvgIcon>;

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const showAlert = () => {
    setAlertOpen(true);
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const filterTypesOfMeasurements = (typeOfAudits: any[]) => {
    console.log(typeOfAudits);
    var typeOfMeasurements = selectList?.typeOfMeasurementsList?.filter(
      (itm: any) => {
        return typeOfAudits.some((e) => {
          return itm.auditTypeId === e;
        });
      }
    );

    let selectMeasurementList: DropdownModel[] = typeOfMeasurements?.map(
      (item: any) => {
        return { text: item.name, valueInt: item.id };
      }
    );
    setTypeOfMeasurements(selectMeasurementList);
    console.log(typeOfMeasurements);
    //to retain selected measurements if it corresponds to selected auditIds

    const availableSelectedMeasurements = methods.getValues(
      "typesOfMeasurements"
    );
    const measurementsForSelectedAudits = availableSelectedMeasurements?.filter(
      (value) => {
        const isAvailable =
          selectMeasurementList.filter(
            (measurement) =>
              measurement.valueInt.toString() === value.toString()
          ).length === 1;
        if (isAvailable) return value;
      }
    );
    console.log(measurementsForSelectedAudits);
    methods.setValue("typesOfMeasurements", measurementsForSelectedAudits);
  };

  const removeSavedFiles = (id: number) => {
    setDeletedFileIds((oldDeletedFileIds) => [...oldDeletedFileIds, id]);

    let tempSavedFiles = savedFiles;
    const index = tempSavedFiles.findIndex((item: any) => {
      return item?.id === id;
    });

    if (index > -1) {
      tempSavedFiles.splice(index, 1);
      setSavedFiles(tempSavedFiles);
    }
  };

  function navigateToFinishedReport() {
    navigation("/" + RoutingConstants.FinishedReports);
  }

  const handleTypeOfMeasurementChange = (
    event: SelectChangeEvent<typeof selectedTypesOfMeasurements>
  ) => {
    const {
      target: { value },
    } = event;
    let a = typeof value === "string" ? value.split(",") : value;
    var index = a?.indexOf("0");
    if (index && index >= 0) {
      a?.splice(index, 1);
    }
    console.log("A" + a);
    methods.setValue("typesOfMeasurements", a);
    methods.trigger("typesOfMeasurements");
  };

  const getTypeOfMeasurementSelectedText = (id: string): string => {
    let selectedText = typeOfMeasurementsList?.find((e) => e.valueInt === +id);
    if (selectedText) {
      return selectedText.text;
    } else {
      return "";
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [open, setOpen] = React.useState(false);
  const [selectedRecommendation, setSelectedRecommendation] =
    React.useState<AuditRecommendations>();
  const [isEdit, setEdit] = useState<boolean>(false);

  const handleClickOpen = (
    event: any,
    recommendation?: AuditRecommendations
  ) => {
    event.stopPropagation();
    setOpen(true);

    if (recommendation) {
      setSelectedRecommendation(recommendation);
      setEdit(true);
      return;
    }

    setEdit(false);
  };

  const handleClose = () => {
    setSelectedRecommendation(undefined);
    setOpen(false);
  };

  const [openCancel, setCancelOpen] = React.useState(false);

  function handleCancelClickOpen(event: any) {
    let customConfirmationDialogProps: CustomConfirmationDialogProps = {
      title: "Are You Sure!",
      content:
        "If you proceed, you will lose all your entered data, unsaved changes may be lost.",
      icon: Exclamation,
      onClose: onCloseCancelSave,
      onConfirm: onConfirmCancelSave,
    };
    dispatchRecommendations(showConfirmation(customConfirmationDialogProps));
    dispatchRecommendations(showLoader());
  }

  function onCloseCancelSave() {
    dispatchRecommendations(hideLoader());
  }

  function onConfirmCancelSave() {
    dispatchRecommendations(hideLoader());
    navigateBack();
  }

  const navigateBack = () => {
    if (isEditReportPage) {
      navigation("/" + RoutingConstants.FinishedReports);
    } else {
      navigation("/" + RoutingConstants.NewProject);
    }
  };

  function deleteRecommendationConfirm(recommendation: AuditRecommendations) {
    let customConfirmationDialogProps: CustomConfirmationDialogProps = {
      title: "Are You Sure!",
      content: "If you proceed, the recommendation will be deleted.",
      icon: Exclamation,
      onClose: onCloseCancelSave,
      onConfirm: () => deleteRecommendation(recommendation),
      isConfirmForDelete: true,
    };
    dispatchRecommendations(showConfirmation(customConfirmationDialogProps));
    dispatchRecommendations(showLoader());
  }

  const deleteRecommendation = (recommendation: AuditRecommendations): void => {
    dispatchRecommendations(deleteRecommendations(recommendation));
    dispatchRecommendations(hideLoader());
    setAlert("error", "Recommendations Deleted Successfully", true);
  };
  const multiselectRef = useRef<any | undefined>();

  const resetSelection = () => {
    multiselectRef?.current?.resetSelectedValues();
  };
  const AuditClassificationTypes = useMemo(
    () => selectList?.auditClassificationList as IAuditClassification[],
    [selectList?.auditClassificationList]
  );
  let classification = AuditClassificationTypes.find(
    (e) =>
      e.auditGroup.toLowerCase().trim() ===
      AuditTypes.AirNet.toLowerCase().trim()
  )?.classification;

  let ClassificationProject: DropdownModel[] =
    classification?.map((item: any) => {
      return { text: item?.text, valueInt: item.valueInt, value: "" };
    }) ?? [];

  const currency = useMemo(
    () => selectList?.currencyList as DropdownModel[],
    [selectList?.currencyList]
  )[0]?.text;

  const getCO2UnitList = useMemo(
    () => selectList?.cO2UnitList as DropdownModel[],
    [selectList?.cO2UnitList]
  );

  function handleSingleSelectChange(data: any) {
    methods.setValue("subCategorySingleSelect", data);
    console.log(data);
    filterTypesOfMeasurements([parseInt(data)]);
  }

  function handleChange(data: any) {
    resetSelection();
    filterTypesOfMeasurements([]);
    setSelectedSubCategory([]);
    methods.setValue("typeOfAuditId", []);
    methods.setValue("subCategorySingleSelect", undefined);
    methods.setValue("typesOfMeasurements", []);

    let selectedText = mappedData?.find((e) => e.valueInt === +data);
    setSelectedProject(selectedText?.text);

    if (selectedText !== undefined) {
      let subCategory = AuditGroupTypes?.find(
        (e) => e.auditGroup === selectedText?.text
      )?.subGroups;
      let val = subCategory?.map((item: any, index) => {
        return { cat: item.valueInt, key: item.text };
      });

      if (
        selectedText.text.toLowerCase() ==
        ShowAuditTypesOnTabConstants.Airnet.toLowerCase()
      ) {
        subCategory && setMultiSelectOptions(subCategory);
      }
      if (
        selectedText.text.toLowerCase() ==
        ShowAuditTypesOnTabConstants.Air_Scan.toLowerCase()
      ) {
        val && setMultiSelectOptions(val);
      }
      if (
        selectedText.text.toLowerCase() ==
        ShowAuditTypesOnTabConstants.Enery_Recovery.toLowerCase()
      ) {
        subCategory && setMultiSelectOptions(subCategory);
      }
      if (
        selectedText.text.toLowerCase() ==
        ShowAuditTypesOnTabConstants.Vibration_Audit.toLowerCase()
      ) {
        subCategory && setMultiSelectOptions(subCategory);
      }
    }
    console.log(data);
  }

  function handleMultiSelectChange(subCategoryList: any, addSubCategory: any) {
    let result: any[] = [];
    let IsSubCategory = defaultIsSubCategory;

    if (addSubCategory.cat == AuditAirScanTypes.Energy_Potential_PreSales) {
      if (
        selectedSubCategory?.length > 0 &&
        selectedSubCategory?.length > subCategoryList?.length
      ) {
        result = [];
        setSelectedSubCategory([]);
      } else {
        result = [addSubCategory.cat];
        setSelectedSubCategory([addSubCategory]);
      }
    } else {
      if (
        subCategoryList[0]?.cat == AuditAirScanTypes.Energy_Potential_PreSales
      ) {
        result = [addSubCategory.cat];
        if (addSubCategory.cat == AuditAirScanTypes.Air_Quality) {
          IsSubCategory = { ...IsSubCategory, isAirQuality: true };
        } else {
          IsSubCategory = { ...IsSubCategory };
        }
        setSelectedSubCategory([addSubCategory]);
      } else {
        if (
          subCategoryList.length == 1 &&
          subCategoryList[0]?.cat == AuditAirScanTypes.Air_Quality
        ) {
          IsSubCategory = { ...IsSubCategory, isAirQuality: true };
        } else {
          IsSubCategory = { ...IsSubCategory };
        }
        result = subCategoryList.map((item: any) => item.cat);
        filterTypesOfMeasurements(result);
        setSelectedSubCategory([...subCategoryList]);
      }
    }
    filterTypesOfMeasurements(result);
    setIsSubCategory(IsSubCategory);
    methods.setValue("typeOfAuditId", result);

    methods.trigger("typeOfAuditId");
  }

  function SaveReportDailog(props: SimpleDialogProps) {
    const { onClose, open } = props;
    const handleClose = () => {
      setOpen(false);
    };
    const Close = () => {
      onClose();
    };

    return (
      <>
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              width: "400px",
              borderRadius: "20px",
            },
          }}
          onClose={handleClose}
          open={open}
        >
          <div
            className="iconimg"
            style={{ paddingTop: "1rem" }}
          >
            <img
              onClick={Close}
              src={UploadIcon}
              height="35"
              alt="Back"
              loading="lazy"
              decoding="async"
            ></img>
          </div>

          <div style={{ padding: "10px 20px 20px 20px", textAlign: "center" }}>
            <ConfirmDelete
              message={message}
              style={{
                fontFamily: "inherit",
                textWrap: "balance",
                // width: "400px",
              }}
              textCss="bold-message-report"
              CloseDeleteDailog={Close}
              CloseRecommendationDailog={navigateToFinishedReport}
              btnText="Okay"
              btnCss="cancel-button custom-buttons"
              hideCancel={false}
              variantCSS="outlined"
            ></ConfirmDelete>
          </div>
        </Dialog>
      </>
    );
  }

  const [openSave, setSaveOpen] = React.useState(false);
  const [message, setMessage] = useState<string>("");

  const handleSaveClose = () => {
    setSaveOpen(false);
  };

  const customerFields = [
    {
      name: "Account ID",
      data: "c4CId",
    },
    {
      name: "Customer Name",
      data: "customerName",
    },
    {
      name: "Country",
      data: "country",
    },
    {
      name: "Address",
      data: "address",
    },
  ];

  type CustomerDataKeys = keyof typeof custDetails;

  function handleCo2InputChange(data: any) {
    methods.setValue("co2Factor", data.target.value);
  }
  function handleElectricCostInputChange(data: any) {
    methods.setValue("electricityCost", data.target.value);
  }

  const customInputSelections = [
    {
      function: handleCo2InputChange,
      label: "CO2 Factor",
      option: getCO2UnitList,
      name: "co2Factor",
      numberOnly: true,
      unitName: "co2Unit",
      hide: isSubCategory.isAirQuality,
    },
    {
      function: handleElectricCostInputChange,
      label: "Electricity Cost",
      option: [currency + "/kWh"],
      name: "electricityCost",
      numberOnly: true,
      unitName: "energyUnitId",
      hide: isSubCategory.isAirQuality || isEnergyRecovery,
    },
  ];

  return (
    <>
      <CustomAlertTest
        severity={alertSeverity}
        open={alertOpen}
        message={alertMessage}
        onClose={handleAlertClose}
      />
      <Box
        px={2}
        py={1}
        mb={1}
        style={{ backgroundColor: "#fff", borderRadius: "8px", border: "none" }}
      >
        <div className={classes["page-title-container"]}>
          <div
            className={classes["page-title"]}
            style={{ minHeight: "35px" }}
          >
            <img
              src={BackArrowIcon}
              height="20"
              alt="Back"
              style={{ cursor: "pointer" }}
              onClick={() => navigateBack()}
              loading="lazy"
              decoding="async"
            ></img>
            <div className={classes["upload-report-title"]}>
              {" "}
              {isEditReportPage ? "Update" : "Upload"} finished projects vs
              Information
            </div>
          </div>
          {methods.getValues("auditNumber") && (
            <div
              className={`${classes["name-auditId-container"]} ${classes["bg-blue"]}`}
            >
              <div
                className={classes["customer-name"]}
                style={{ padding: "8px" }}
              >
                <span className={classes["fw-600"]}>Project ID:</span>{" "}
                {methods.getValues("auditNumber")}
              </div>
            </div>
          )}
        </div>
      </Box>
      <Box
        className={classes["form-container"]}
        style={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          height: "calc(100vh - 185px)",
        }}
      >
        <Box
          sx={{
            border: "unset",
            boxShadow: "none",
            marginBottom: "1.5rem",
          }}
        >
          <Typography
            fontWeight={600}
            fontSize={"1.125rem"}
            marginBottom={".75rem"}
          >
            Customer Details
          </Typography>
          <Box
            sx={{ border: "unset", boxShadow: "none" }}
            display={"grid"}
            gridTemplateColumns={"10% 26% 14% 28% 22%"}
          >
            {customerFields.map((field, index) => {
              const value = custDetails[field.data as CustomerDataKeys];
              return (
                <Box
                  sx={{
                    border: "unset",
                    boxShadow: "none",
                    borderRight: "1px solid #9F9F9F",
                  }}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"1rem"}
                  paddingRight={"1.5rem"}
                  width={"100%"}
                  paddingLeft={index === 0 ? "unset" : "1.5rem"}
                >
                  <Typography fontWeight={600}>{field.name}</Typography>
                  <Typography>{value}</Typography>
                </Box>
              );
            })}
            <Box
              sx={{ border: "unset", boxShadow: "none" }}
              display={"flex"}
              flexDirection={"column"}
              gap={"1rem"}
              width={"100%"}
              paddingLeft={"1.5rem"}
            >
              <Typography fontWeight={600}>Responsible Person</Typography>
              <Typography>
                {methods.getValues("responsiblePersonId")}
              </Typography>
            </Box>
          </Box>
        </Box>

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid
              className="grid-container"
              container
              spacing={2}
              rowSpacing={2}
            >
              <CustomSelect
                name={"categoryOfProject"}
                label={"Category of Project"}
                setValue={methods.setValue}
                isRequired
                options={mappedData}
                disabled={isEditReportPage ? true : false}
                md={4}
                customHandleChange={handleChange}
              />
              {isAirScan ? (
                <CustomMultiSelectNew
                  name={"typeOfAuditId"}
                  label={"Sub Category Of Project"}
                  md={4}
                  options={multiSelectOptions}
                  disabled={isEditReportPage ? true : false}
                  icon={VectorIcon}
                  multiselectRef={multiselectRef}
                  handleChange={handleMultiSelectChange}
                  placeholder={"Select Sub Category Of Project"}
                  selectedValues={selectedSubCategory}
                />
              ) : (
                <CustomSelect
                  name={"subCategorySingleSelect"}
                  label={"Sub Category Of Project"}
                  setValue={methods.setValue}
                  isRequired
                  options={multiSelectOptions}
                  md={4}
                  customHandleChange={(data: any) => {
                    handleSingleSelectChange(data);
                  }}
                  disabled={isEditReportPage ? true : false}
                />
              )}
              {isAirnet && (
                <CustomSelect
                  name={"classificationOfProject"}
                  label={"Classification of Project"}
                  setValue={methods.setValue}
                  isRequired
                  options={ClassificationProject}
                  md={4}
                />
              )}
              <CustomInput
                isRequired={true}
                name="auditName"
                label="Project Name"
                variant="outlined"
                fullWidth
              />
              <CustomSelect
                disabled={isEditReportPage ? true : false}
                isRequired={true}
                name="famCodeId"
                setValue={methods.setValue}
                label="FAM Code"
                options={selectList?.famCodeList as DropdownModel[]}
              />
              {!isVibration && !isSubCategory.isAirQuality && (
                <>
                  {customInputSelections?.map((item, index) => (
                    <CustomInputSelect
                      label={item.label}
                      options={item.option ?? []}
                      md={4}
                      name={item.name}
                      handleInputChange={item.function}
                      numberOnly={item.numberOnly}
                      isRequired={isAirnet || isEnergyRecovery ? false : true}
                      unitName={item.unitName && item.unitName}
                    />
                  ))}
                  <GridBreak></GridBreak>
                </>
              )}
              {isVibration && (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    lg={12}
                    direction="row"
                  >
                    <Typography
                      className="page-group-title"
                      variant="inherit"
                      align="left"
                      color="#000"
                    >
                      Compressor Information
                    </Typography>
                  </Grid>
                  <CustomSelect
                    isRequired={true}
                    setValue={methods.setValue}
                    name="brandId"
                    label="Brand"
                    options={selectList?.brandList as DropdownModel[]}
                  />
                  <CustomInput
                    isRequired={true}
                    name="serialNumber"
                    label="Serial Number"
                    variant="outlined"
                    fullWidth
                  />
                  <CustomInput
                    isRequired={true}
                    name="machineModel"
                    label="Machine Model"
                    variant="outlined"
                    fullWidth
                  />
                  <CustomInput
                    isRequired={true}
                    name="runningHours"
                    label="Running Hours"
                    variant="outlined"
                    fullWidth
                  />
                  <CustomInput
                    isRequired={true}
                    name="loadedHours"
                    label="Loaded Hours"
                    variant="outlined"
                    fullWidth
                  />
                  <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    lg={12}
                    item
                    container
                  >
                    <Typography className="page-group-title">
                      Result{" "}
                      <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                    </Typography>
                  </Grid>
                  {/* </Grid> */}
                  <CustomRange
                    name="criticalResult"
                    min={0}
                    label="Critical"
                    type="critical"
                  ></CustomRange>
                  <CustomRange
                    name="mediumResult"
                    min={0}
                    label="Medium"
                    type="medium"
                  ></CustomRange>
                  <CustomRange
                    name="lowResult"
                    min={0}
                    label="Low"
                    type="low"
                  ></CustomRange>
                </>
              )}
            </Grid>
            <Grid
              className="grid-container"
              container
              spacing={2}
              rowSpacing={2}
            >
              <Grid
                item
                xs={12}
                md={12}
                xl={4}
                lg={4}
                container
                direction="row"
              >
                <FormControl fullWidth>
                  <FormLabel className="labels">
                    Type of Measurements
                    <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                  </FormLabel>
                  <Controller
                    name={"typesOfMeasurements"} // for the gender field
                    control={control} // obtained from the useForm hook
                    render={({ field }) => (
                      <Select
                        style={{ height: "33px" }}
                        {...methods.register("typesOfMeasurements")}
                        multiple
                        displayEmpty
                        value={field.value}
                        onChange={handleTypeOfMeasurementChange}
                        fullWidth
                        MenuProps={MenuProps}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return (
                              <p className="grey-text">
                                Select Type of Measurements
                              </p>
                            );
                          }
                          return selected
                            .map((p: any) =>
                              getTypeOfMeasurementSelectedText(p)
                            )
                            .join(", ")
                            .substring(0, 50);
                        }}
                      >
                        <MenuItem
                          key="0"
                          value=""
                          disabled={true}
                        >
                          Select Type of Measurements{" "}
                        </MenuItem>
                        {typeOfMeasurementsList?.map((option) => (
                          <MenuItem
                            disableRipple
                            key={option.valueInt}
                            value={option.valueInt.toString()}
                          >
                            <Checkbox
                              checked={selectedTypesOfMeasurements?.includes(
                                option.valueInt.toString()
                              )}
                            />
                            <ListItemText primary={option.text} />
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <Typography
                    sx={{
                      color: "var(--primary-color)",
                      fontSize: "14px",
                      fontStyle: "italic",
                      fontWeight: "400",
                    }}
                  >
                    You can select multiple measurement
                  </Typography>
                  <Typography
                    variant="inherit"
                    align="left"
                    color="red"
                    fontSize={"12px"}
                  >
                    {methods.formState.errors["typesOfMeasurements"]
                      ? (methods.formState.errors["typesOfMeasurements"]
                          ?.message as string)
                      : undefined}
                  </Typography>
                </FormControl>
              </Grid>
              <Grid
                className="containergrid"
                xs={12}
                sm={12}
                md={12}
                xl={8}
                lg={8}
                item
                container
              >
                <Box
                  border={"none"}
                  maxHeight={"400px"}
                  width={"100%"}
                  boxShadow={"none"}
                >
                  <Grid
                    container
                    spacing={2}
                    rowSpacing={2}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <>
                      <Grid
                        xs={6}
                        sm={6}
                        md={6}
                        xl={6}
                        lg={6}
                        item
                      >
                        {fields.length > 0 && fields[0].typeOfMeasurementId && (
                          <FormLabel className="labels">Measurements</FormLabel>
                        )}
                      </Grid>
                      <Grid
                        xs={6}
                        sm={6}
                        md={6}
                        xl={6}
                        lg={6}
                        item
                      >
                        {fields.length > 0 && fields[0].typeOfMeasurementId && (
                          <FormLabel
                            className="labels"
                            sx={{ position: "relative", left: "-2px" }}
                          >
                            Quantity
                          </FormLabel>
                        )}
                      </Grid>
                      <Box
                        border={"none"}
                        maxHeight={"400px"}
                        height={"100%"}
                        sx={{ overflowY: "auto" }}
                        width={"100%"}
                        boxShadow={"none"}
                      >
                        <Grid
                          container
                          spacing={2}
                          rowSpacing={2}
                          paddingLeft={"24px"}
                          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        >
                          {fields.map(
                            (item, i) =>
                              item.name !== undefined && (
                                <>
                                  <Grid
                                    xs={6}
                                    sm={6}
                                    md={6}
                                    xl={6}
                                    lg={6}
                                    item
                                  >
                                    <TextField
                                      variant="outlined"
                                      label=""
                                      {...methods.register(
                                        `measurementsWithQuantity.${i}.name`
                                      )}
                                      name={`measurementsWithQuantity.${i}.name`}
                                      disabled
                                    ></TextField>

                                    <Typography
                                      variant="inherit"
                                      align="left"
                                      color="red"
                                      fontSize={"12px"}
                                    >
                                      {
                                        methods.formState.errors
                                          .measurementsWithQuantity?.[i]?.name
                                          ?.message
                                      }
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    xs={6}
                                    sm={6}
                                    md={6}
                                    xl={6}
                                    lg={6}
                                    item
                                  >
                                    <TextField
                                      variant="outlined"
                                      label=""
                                      {...methods.register(
                                        `measurementsWithQuantity.${i}.qty`
                                      )}
                                      name={`measurementsWithQuantity.${i}.qty`}
                                      onKeyDown={(
                                        event: KeyboardEvent<HTMLInputElement>
                                      ) => {
                                        const isNumeric = /^[0-9]*$/.test(
                                          event.key
                                        );
                                        if (
                                          (!isNumeric &&
                                            event.key !== "Tab" &&
                                            event.key !== "." &&
                                            event.key !== "Backspace") ||
                                          (event.key === "." &&
                                            (
                                              event.target as HTMLInputElement
                                            ).value.includes("."))
                                        ) {
                                          event.preventDefault();
                                        }
                                      }}
                                    ></TextField>
                                    <Typography
                                      variant="inherit"
                                      align="left"
                                      color="red"
                                      fontSize={"12px"}
                                    >
                                      {
                                        methods.formState.errors
                                          .measurementsWithQuantity?.[i]?.qty
                                          ?.message
                                      }
                                    </Typography>
                                  </Grid>
                                </>
                              )
                          )}
                        </Grid>
                      </Box>
                    </>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Grid
              className="grid-container"
              container
              spacing={2}
              rowSpacing={2}
            >
              <Grid
                marginTop={"25px"}
                item
                xs={12}
                sm={12}
                md={12}
                xl={12}
                lg={12}
                direction="row"
              >
                <Accordion
                  expanded={expandRecommendationList}
                  sx={{
                    "& .MuiAccordionSummary-root.Mui-expanded": {
                      minHeight: "49px",
                      //"padding-left": "0 !important",
                      //"padding-right": "0 !important",
                    },
                  }}
                >
                  <AccordionSummary
                    onClick={() => {
                      setExpandRecommendationList((x) => !x);
                    }}
                    sx={{
                      "& .Mui-expanded": {
                        margin: "10px 0 !important",
                      },
                    }}
                    className={classes["accordion-div"]}
                    expandIcon={ExpandIcon}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes["title"]}>
                      Add Product Recommendation
                    </Typography>
                    <img
                      src={AddIcon}
                      height="22"
                      alt="Back"
                      loading="lazy"
                      decoding="async"
                      onClick={(event) => handleClickOpen(event)}
                    ></img>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: "0px" }}>
                    <Paper sx={{ width: "100%", overflow: "hidden" }}>
                      <TableContainer
                        className={classes["table-container-inside-tab"]}
                      >
                        <Table
                          stickyHeader
                          aria-label="sticky table"
                          sx={{
                            "& .MuiTableRow-hover:hover": {
                              "background-color": "transparent !important",
                            },
                            "& .MuiTableCell-root": {
                              borderLeft: "1px solid #E6E6E6",
                              zIndex: "0px",
                            },
                          }}
                        >
                          <TableHead>
                            <TableRow>
                              {columns.map((column) => (
                                <TableCell
                                  className={`${classes["table-header"]} ${
                                    column.label === "Name"
                                      ? classes["stickyHeader-left"]
                                      : ""
                                  } ${
                                    column.label === "action"
                                      ? classes["stickyHeader-right"]
                                      : ""
                                  }`}
                                  key={column.id}
                                  align={column.align}
                                  style={{ minWidth: column.minWidth }}
                                >
                                  <span className={classes["table-title"]}>
                                    {column.label}
                                  </span>
                                </TableCell>
                              ))}
                              <TableCell
                                style={{ minWidth: "100px" }}
                                className={`${classes["table-header"]} ${classes["stickyHeader-right"]}`}
                              >
                                <span className={classes["table-title"]}>
                                  Action
                                </span>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {recommendationList
                              // .slice(
                              //   page * rowsPerPage,
                              //   page * rowsPerPage + rowsPerPage
                              // )
                              .map((row) => {
                                return (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.id}
                                  >
                                    {columns.map((column) => {
                                      const columnName =
                                        column.id as recommendationTableColumns;
                                      const value = row[columnName];

                                      let formattedDate;
                                      if (columnName === "followUpDate") {
                                        formattedDate = dayjs(value).isValid()
                                          ? dayjs(value).format("DD/MM/YYYY")
                                          : "";
                                      }

                                      return (
                                        <TableCell
                                          className={`${
                                            column.label === "action"
                                              ? classes["sticky-right"]
                                              : ""
                                          }`}
                                        >
                                          {column.id === "followUpDate" ? (
                                            formattedDate
                                          ) : column.id === "description" ||
                                            column.id === "comments" ? (
                                            <Tooltip
                                              title={value}
                                              placement="right"
                                              slotProps={{
                                                popper: {
                                                  modifiers: [
                                                    {
                                                      name: "offset",
                                                      options: {
                                                        offset: [0, -25],
                                                      },
                                                    },
                                                  ],
                                                },
                                              }}
                                            >
                                              <p
                                                className={
                                                  classes[
                                                    "description-overflow"
                                                  ]
                                                }
                                              >
                                                {value}
                                              </p>
                                            </Tooltip>
                                          ) : column.format &&
                                            typeof value === "number" ? (
                                            column.format(value)
                                          ) : (
                                            value
                                          )}
                                        </TableCell>
                                      );
                                    })}
                                    <TableCell
                                      key={row.id}
                                      className={classes["sticky-right"]}
                                    >
                                      <img
                                        className={
                                          row.sentToC4C ||
                                          (row?.leadId !== undefined &&
                                            (row.leadId > 0 ||
                                              (row.opportunityId !==
                                                undefined &&
                                                row.opportunityId > 0)))
                                            ? "disabled-icons"
                                            : "action-icons"
                                        }
                                        style={{ marginRight: "10px" }}
                                        src={EditIcon}
                                        height="24"
                                        alt="Request Project"
                                        //  onClick={() =>
                                        //    editRecommendation(row)
                                        //  }
                                        loading="lazy"
                                        decoding="async"
                                        onClick={(event) =>
                                          handleClickOpen(event, row)
                                        }
                                      ></img>
                                      <img
                                        className={
                                          row.sentToC4C ||
                                          (row?.leadId !== undefined &&
                                            (row.leadId > 0 ||
                                              (row.opportunityId !==
                                                undefined &&
                                                row.opportunityId > 0)))
                                            ? "disabled-icons"
                                            : "action-icons"
                                        }
                                        src={DeleteIcon}
                                        height="24"
                                        alt="Add New"
                                        loading="lazy"
                                        decoding="async"
                                        onClick={() =>
                                          deleteRecommendationConfirm(row)
                                        }
                                      ></img>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      {/* <TablePagination
                              rowsPerPageOptions={[10, 25, 100]}
                              component="div"
                              count={rows.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                            /> */}
                    </Paper>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                xl={12}
                lg={12}
                direction="row"
              >
                <div className="customflex">
                  <Typography
                    className="page-group-title"
                    variant="inherit"
                    align="left"
                    color="#000"
                  >
                    Upload Files{" "}
                    <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                  </Typography>
                  <span className="example-spacer"> </span>
                  <p className="messageuplodtxt"> Maximum file size 100MB</p>
                </div>
              </Grid>
              <Grid
                className="custompading"
                item
                xs={12}
                sm={12}
                md={4}
                xl={4}
                lg={4}
                direction="row"
              >
                <DragDrop
                  data={fileData}
                  availableFileCount={savedFiles.length}
                  dispatch={dispatch}
                  fileTypes=".pdf,.xls,.xlsx, .doc, .docx, .ppt, .pptx"
                  message="Supported formats DOC, DOCX, PDF, PPT, PPTX, XLS, XLSX"
                  maxFileCount={5}
                  savedFiles={savedFiles}
                ></DragDrop>

                <Typography
                  variant="inherit"
                  align="left"
                  color="red"
                  fontSize={"12px"}
                >
                  {methods.formState.errors.uploadedFiles?.message}
                </Typography>
                {savedFiles.map((f: any) => {
                  let icon = null;

                  icon = ExcelFileIcon;

                  if (f.attachedFileName.includes(".pdf")) {
                    icon = PDFFileIcon;
                  }
                  if (
                    f.attachedFileName.includes(".doc") ||
                    f.attachedFileName.includes(".docx")
                  ) {
                    icon = WordFileIcon;
                  }
                  if (
                    f.attachedFileName.includes(".ppt") ||
                    f.attachedFileName.includes(".pptx")
                  ) {
                    icon = PPTFileIcon;
                  }

                  return (
                    <Grid
                      container
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      xl={12}
                      lg={12}
                      direction="row"
                    >
                      <div className={classes["dropped-files"]}>
                        <div className="display-data-left">
                          <div className={classes["img-container"]}>
                            <img
                              src={icon}
                              height="30"
                              width="30"
                              alt="Excel"
                              loading="lazy"
                              decoding="async"
                            ></img>
                          </div>

                          <div
                            style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                          >
                            <Tooltip
                              title={f.attachedFileName}
                              placement="right"
                              slotProps={{
                                popper: {
                                  modifiers: [
                                    {
                                      name: "offset",
                                      options: {
                                        offset: [0, 10],
                                      },
                                    },
                                  ],
                                },
                                tooltip: {
                                  sx: {
                                    fontSize: "13px",
                                  },
                                },
                              }}
                            >
                              <div
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {f.attachedFileName}
                              </div>
                            </Tooltip>
                            <div>{f.fileSize}</div>
                          </div>

                          {/* <CloseIcon /> */}
                        </div>
                        <div className="display-data-right">
                          {f.id > 0 ? (
                            <img
                              src={downloadIcon}
                              className={classes["remove-file"]}
                              height="30"
                              width="30"
                              alt="Close"
                              loading="lazy"
                              decoding="async"
                              onClick={(e) =>
                                DownloadFile(f.blobName, f.attachedFileName)
                              }
                            ></img>
                          ) : (
                            ""
                          )}
                          <img
                            src={CloseIcon}
                            className={classes["remove-file"]}
                            height="30"
                            width="30"
                            alt="Close"
                            loading="lazy"
                            decoding="async"
                            onClick={(e) => removeSavedFiles(f.id)}
                          ></img>
                        </div>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
              <Grid
                item
                container
                xs={12}
                sm={12}
                md={12}
                xl={12}
                lg={12}
                direction="row"
                justifyContent="flex-end"
              >
                <Button
                  sx={{ textTransform: "none" }}
                  className="cancel-button custom-buttons"
                  disableRipple
                  style={{
                    fontSize: "18px",
                    fontWeight: "400",
                    marginRight: "15px",
                  }}
                  variant="outlined"
                  onClick={handleCancelClickOpen}
                >
                  Cancel
                </Button>
                <Button
                  style={{ fontSize: "18px", fontWeight: "400" }}
                  type="submit"
                  className="custom-buttons"
                  startIcon={uploadReportIcon}
                  sx={{ textTransform: "none" }}
                  variant="contained"
                  disableRipple
                  disabled={
                    isEditReportPage
                      ? !finishedReportPermission.update
                      : !finishedReportPermission.create
                  }
                  size="medium"
                  // onClick={CreateNewCustomer}
                >
                  {/* <img
                    src={NewCustomerIcon}
                    height="24px"
                    width="24px"
                    alt="Notifications"
                  ></img> */}
                  {isEditReportPage ? "Update" : "Upload"} Project
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Box>
      <AddRecommendationsdailog
        open={open}
        onClose={handleClose}
        isEdit={isEdit}
        selectedRecommendation={selectedRecommendation}
        fromOpportunity={false}
        currency={selectList?.currencyList}
        auditRecommendationId={0}
        isAirNet={isAirnet}
      />
      {/* <CancelReportDailog open={openCancel} onClose={handleCancelClose} /> */}
      <SaveReportDailog
        open={openSave}
        onClose={handleSaveClose}
      />
      {/* <DeleteRecommendationDailog
          open={openDelete}
          onClose={handleDeleteClose}
        /> */}

      {/* <alertDialog open={open} onClose={handleClose} /> */}
    </>
  );
};

export default UploadFinishedReportsForAllAudits;

interface AddRecommendationsProps {
  open: boolean;
  isEdit: boolean;
  selectedRecommendation: AuditRecommendations | undefined;
  onClose: () => any;
}

interface SimpleDialogProps {
  open: boolean;
  //selectedValue: string;
  onClose: () => any;
}

export async function loader() {
  //console.log("loader");
  const response = await fetch(
    RequestApi.GET_UPLOAD_FINISHED_REPORTS_SELECT_LIST,
    {
      method: "GET",
    }
  );
  if (!response.ok) {
    throw { message: "error occured" };
    //....
  } else {
    const resData = await response.json();
    return resData;
  }
}
