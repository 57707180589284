import { NotificationTypes } from "./NotificationDialog";
import BlueDot from "../../assets/icons/BlueDot.svg";
import classes from "./Notification.module.css";

interface INotificationProps {
  title: string;
  message: string;
  onClick: () => void;
  isRead: boolean;
  daysAgo: number;
  icon: string;
}

const getTimeAgo = (daysAgo: number = 0): string => {
  return daysAgo === 0
    ? "Today"
    : daysAgo === 1
    ? "1 day ago"
    : `${daysAgo} days ago`;
};

const styles = {
  card: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    marginBottom: "10px",
    borderRadius: "5px",
    fontFamily: "Source Sans Pro",
  },
  icon: {
    marginRight: "16px",
  },
  content: {
    flex: 1,
  },
  labelContainer: {
    marginBottom: "5px",
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
    justifyContent: "space-between",
  },
  label: {
    color: "#0078A1",
    marginRight: "6px",
  },
  time: {
    color: "#373D41",
  },
  message: {
    color: "#0D0D0D",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
  },
};

const getTitle = (title: string) => {
  switch (title) {
    case NotificationTypes.ResponsiblePersonAdded:
      return NotificationTypes.NewProjectData;
    case NotificationTypes.OpportunityFollowUp:
    case NotificationTypes.LeadFollowUp:
      return NotificationTypes.FollowUpDateAchieved;
    case NotificationTypes.LeadStatusNotChanged:
    case NotificationTypes.OpportunityStatusNotChanged:
      return NotificationTypes.StatusNotChanged;
    default:
      return title;
  }
};

const NotificationCard = ({
  icon,
  message,
  title,
  onClick,
  isRead,
  daysAgo,
}: INotificationProps) => {
  return (
    <div
      className={classes["card"]}
      onClick={onClick}
    >
      <div style={styles.icon}>
        <img
          src={icon}
          height="35"
          alt="Back"
          loading="lazy"
          decoding="async"
        ></img>
      </div>
      <div style={styles.content}>
        <div style={styles.labelContainer}>
          <div>
            <span style={styles.label}>{getTitle(title)}</span>
            <span style={styles.time}>{getTimeAgo(daysAgo)}</span>
          </div>
          {!isRead && (
            <img
              src={BlueDot}
              height="8"
              alt="Back"
              loading="lazy"
              decoding="async"
            />
          )}
        </div>
        <div style={styles.message}>{message}</div>
      </div>
    </div>
  );
};

export default NotificationCard;
