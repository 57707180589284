import { Dialog, TextFieldProps, Typography } from "@mui/material";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import calenderClose from "../../../assets/icons/calender_Close.svg";
import calenderSelect from "../../../assets/icons/calender_Select.svg";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

type CustomDatePickerProps = TextFieldProps & {
  state: {
    isOpen: boolean;
    isEdit: boolean;
    lastFollowUp: string;
    recommendationId: number;
  };
  setState: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      isEdit: boolean;
      lastFollowUp: string;
      recommendationId: number;
    }>
  >;
  SetFollowUpDate: (
    auditRecommendationId: number,
    followUpDate: string
  ) => void;
};

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  state,
  setState,
  SetFollowUpDate,
}) => {
  const [selectedValue, setSelectedValue] = useState(state?.lastFollowUp);
  const handleDateChange = (newDate: any) => {
    if (newDate) {
      const formattedDate = dayjs(newDate).format("YYYY-MM-DD");
      setSelectedValue(formattedDate);
    } else {
      setSelectedValue("");
    }
  };

  useEffect(() => {
    setSelectedValue(state?.lastFollowUp);
  }, [state?.isOpen]);
  return (
    <Dialog
      open={state.isOpen}
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "16px",
          width: "400px",
          padding: "16px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <Typography
        variant="h6"
        sx={{
          marginBottom: "8px",
          color: "#2E2E30",
          fontSize: "24px",
          fontWeight: 600,
          textAlign: "left",
          paddingLeft: "8px",
        }}
      >
        {state?.isEdit ? "Update Follow Up Date" : "Set Follow Up Date"}
      </Typography>
      {state?.isEdit && (
        <Typography
          variant="h6"
          sx={{
            marginBottom: "8px",
            color: "#2E2E30",
            fontSize: "16px",
            fontWeight: 600,
            textAlign: "left",
            paddingLeft: "8px",
            borderBottom: "1px solid #BBBDC0",
          }}
        >
          Set Date -{" "}
          <span style={{ fontSize: "16px", fontWeight: 400 }}>
            {" "}
            {dayjs(state.lastFollowUp).format("DD MMMM YYYY")}
          </span>
        </Typography>
      )}

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
          minDate={dayjs()}
          maxDate={dayjs().add(1, "year")}
          views={["year", "month", "day"]}
          dayOfWeekFormatter={(day: string) => {
            const fullDayNames: { [key: string]: string } = {
              Su: "Sun",
              Mo: "Mon",
              Tu: "Tue",
              We: "Wed",
              Th: "Thu",
              Fr: "Fri",
              Sa: "Sat",
            };
            return fullDayNames[day] || day;
          }}
          sx={{
            width: "100%",
            fontSize: ".875rem",
            bgcolor: "#fff",
            height: "320px",
            borderRadius: "16px",
            "& .MuiPickersCalendarHeader-root": {
              padding: "0px",
              margin: "0px",
              paddingLeft: "10px",
              color: "#2E2E30",
              fontFamily: "Segoe UI",
              fontSize: "18px",
              lineHeight: "23px",
              fontWeight: 600,
            },
            "& .MuiPickersDay-root": {
              borderRadius: "50%",
              width: "40px",
              height: "40px",
              color: "#1D2536",
              fontFamily: "Segoe UI",
              fontWeight: 450,
              fontSize: "16px",
              lineHeight: "20px",
            },
            "& .MuiPickersDay-root:hover": {
              backgroundColor: "#dfebef",
            },
            "& .MuiPickersDay-today": {
              bgcolor: selectedValue ? "transparent" : " #dfebef",
              border: "none !important",
              color: selectedValue ? "1D2536" : "#0078A1",
            },
            "& .MuiPickersDay-root.Mui-selected": {
              bgcolor: "#dfebef",
              border: "none",
              color: "#0078A1",
            },
            "& .MuiPickersCalendarHeader-switchViewButton": {
              display: "none",
            },
            "& .MuiDayCalendar-header": {
              justifyContent: "space-between !important",
            },
            "& .MuiDayCalendar-monthContainer": {
              left: "0 !importamt",
            },
            "& .MuiDayCalendar-weekContainer": {
              justifyContent: "space-between !important",
            },
            "& .MuiDayCalendar-weekDayLabel": {
              fontFamily: "Segoe UI",
              color: "#1D2536",
              fontWeight: 600,
            },
            "& .MuiPickersSlideTransition-root.MuiDayCalendar-slideTransition":
              {
                minHeight: "260px",
              },
          }}
          value={selectedValue ? dayjs(selectedValue) : null}
          onChange={handleDateChange}
        />
      </LocalizationProvider>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "16px",
          gap: "16px",
        }}
      >
        <img
          src={calenderClose}
          alt="Close"
          loading="lazy"
          decoding="async"
          className="arrow-pointer"
          onClick={() => {
            setState({
              ...state,
              isOpen: false,
            });
          }}
        />
        <img
          src={calenderSelect}
          alt="Select"
          loading="lazy"
          decoding="async"
          className="arrow-pointer"
          onClick={() => {
            SetFollowUpDate(state.recommendationId, selectedValue);
          }}
        />
      </div>
    </Dialog>
  );
};

export default CustomDatePicker;
