import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  DialogTitle,
  Dialog,
  Grid,
  Button,
  Box,
  Tooltip,
} from "@mui/material";
import { useState, useEffect } from "react";
import { TableColumn } from "../../interface/TableColumn";
import CloseIcon from "../../../assets/icons/close.svg";
import classes from "./RecommendationListTable.module.css";
import SendIcon from "../../../assets/icons/send.svg";
import LinkIcon from "../../../assets/icons/link.svg";
import CheckCircle from "../../../assets/icons/Check_Circle.svg";
import ClearCircle from "../../../assets/icons/Clear_Circle.svg";
import Ellipse_green from "../../../assets/icons/Ellipse_green.svg";
import FileSentC4CIcon from "../../../assets/icons/file_sent_c4c.svg";
import Exclamation from "../../../assets/icons/Red_Exclaimation_mark.svg";
import EditIcon from "../../../assets/icons/edit-icon.svg";
import LinkedRecommendation from "../../../assets/icons/Linked_Success.svg";
import ActiveRecommendation from "../../../assets/icons/Active_Recommendation.svg";
import ConfirmDelete from "./ConfirmDelete";
import CustomAlert from "./CustomAlert";
import { useNavigate } from "react-router-dom";
import { CustomConfirmationDialogProps } from "./ConfirmationDialog";
import { showConfirmation } from "../../store/confirmationBoxDataSlice";
import { hideLoader, showLoader } from "../../store/loaderDataSlice";
import { useAppDispatch } from "../../hooks/hooks";
import { useMsal } from "@azure/msal-react";
import { useRequest } from "../../../services/agent";
import { RequestApi } from "../../Constant";
import noDataFound from "../../../assets/images/NoDataFound.png";
import { AuditRecommendations } from "../../store/addRecommendationsDataSlice";
import AddRecommendationsdailog from "./AddRecommendationsdailog";
import { SavedSuccessDailog } from "../../../features/EditSavingsInformation";
import LinkLeadOrOpportunityDialog from "./LinkLeadOrOpportunityDialog";

interface TableData {
  columns: TableColumn[];
  data: any;
  CloseDailog: () => any;
  auditId: number;
  customerName: string;
  setOpenSuccessDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setIsApiSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  openSuccessDialog: boolean;
}
export enum C4CStatus {
  Sent = "Sent",
  Failed = "Failed",
  New = "New",
  Linked = "Linked",
}
const RecommendationListTable = ({
  columns,
  data,
  CloseDailog,
  auditId,
  customerName,
  setOpenSuccessDialog,
  openSuccessDialog,
  setIsApiSuccess,
}: TableData) => {
  const [listData, setData] = useState(
    data.map((element: any) => ({ ...element, isChecked: false }))
  );
  const [comments, setComments] = useState("");

  const [openRecommendation, setOpenRecommendation] = useState(false);
  const [isEditRecommendation, setIsEditRecommendation] = useState(false);
  const [selectedRecommendation, setSelectedRecommendation] =
    useState<AuditRecommendations>();

  const msal = useMsal();
  const userName = msal.accounts[0]?.name;

  useEffect(() => {
    setData(data.map((element: any) => ({ ...element, isChecked: false })));
  }, [data]);

  const navigation = useNavigate();

  const dispatch = useAppDispatch();
  const request = useRequest();

  interface SimpleDialogProps {
    open: boolean;
    onClose: () => any;
  }
  function navigateToC4CReport() {
    navigation("/generated-leads");
  }

  const Close = () => {
    setOpen(false);
  };

  const [open, setOpen] = useState(false);

  function SendC4CDailog(props: SimpleDialogProps) {
    const { onClose, open } = props;
    const handleClose = () => {
      setOpen(false);
    };

    const Close = () => {
      onClose();
    };
    const getC4cStatus = () => {
      if (failureCount === 0) {
        return (
          <>
            <div
              className="bold-message"
              style={{ color: "#43B02A", marginBottom: "3px" }}
            >
              {successCount} Sent.
            </div>
            <div className={classes["sent-to-text"]}>
              <span className={classes["success-text-para"]}>
                Recommendation(s)
              </span>
              <span> sent to</span>
              <span className={classes["c4C-text"]}>C4C</span>
              <span> successfully!</span>
            </div>
          </>
        );
      }
      if (successCount === 0) {
        return (
          <>
            <div
              className="bold-message"
              style={{ color: "#C8102E", marginBottom: "3px" }}
            >
              {failureCount} Failed!
            </div>
            <div className={classes["sent-to-text"]}>
              <span>All</span>
              <span className={classes["success-text-para"]}>
                Recommendation(s) Not
              </span>
              <span> sent to</span>
              <span
                className={classes["c4C-text"]}
                style={{ marginLeft: "0px !important" }}
              >
                C4C
              </span>{" "}
              !
              <br />
              <span>Please try after some time.</span>
            </div>
          </>
        );
      }
      return (
        <>
          <div
            className="bold-message"
            style={{ color: "#C8102E", marginBottom: "3px" }}
          >
            {failureCount} Failed!
          </div>
          <div className={classes["sent-to-text"]}>
            <span className={classes["success-text-para"]}>
              {successCount} Recommendation(s)
            </span>
            <span> sent to</span>
            <span className={classes["c4C-text"]}>C4C</span> !
            <br />
            <span>For remaining, please try after some time.</span>
          </div>
        </>
      );
    };
    return (
      <>
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "20px",
            },
          }}
          onClose={handleClose}
          open={open}
        >
          <DialogTitle>
            <div className={classes["iconimg"]}>
              <img
                onClick={Close}
                src={FileSentC4CIcon}
                height="35"
                alt="Back"
                loading="lazy"
                decoding="async"
              />
            </div>
            <div className={classes["close-icon"]}>
              <img
                onClick={successCount == 0 ? Close : navigateToC4CReport}
                src={CloseIcon}
                height="35"
                alt="Back"
                loading="lazy"
                decoding="async"
              />
            </div>

            <div style={{ textAlign: "center" }}>
              <ConfirmDelete
                textCss="text-message"
                CloseDeleteDailog={Close}
                CloseRecommendationDailog={
                  successCount == 0 ? Close : navigateToC4CReport
                }
                btnText="Okay"
                btnCss="custom-buttons cancel-button"
                hideCancel={false}
                hideRemark={false}
                variantCSS="outlined"
                c4C={true}
                message={getC4cStatus()}
              />
            </div>
          </DialogTitle>
        </Dialog>
      </>
    );
  }

  const [openC4C, setC4COpen] = useState(false);
  const [openLinkDialog, setOpenLinkDialog] = useState(false);
  const [selectedRecommendationId, setSelectedRecommendationId] = useState<
    number | null
  >(null);
  const [successCount, setSuccessCount] = useState(0);
  const [failureCount, setFailureCount] = useState(0);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState<"success" | "error">(
    "success"
  );
  const [alertOpen, setAlertOpen] = useState(false);
  function setAlert(severity: any, message: string, OCflag: boolean) {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(OCflag);
    // handleCloseDialog();
  }

  const handleC4CClickOpen = (event: any) => {
    event.stopPropagation();

    var selectedRecommendationsIds = listData
      .filter(function (e: any) {
        return e.isChecked;
      })
      .map(function (e: any) {
        return e.id;
      });

    var body = {
      recommendationId: selectedRecommendationsIds,
      // initiator: userName,
      auditId: auditId,
      // companyName: customerName,
      // notes: comments,
    };

    request({
      url: RequestApi.SEND_RECOMMENDATIONS_TO_C4C,
      method: "post",
      data: body,
    })
      .then((resp) => {
        setFailureCount(resp.data.failureCount);
        setSuccessCount(resp.data.successCount);
        if (resp.data.isSuccess === true) {
          setC4COpen(true);
        } else {
          setAlert("error", resp.data.message, true);
        }
      })
      .catch((err) => {
        setAlert("error", err.response.data.message, true);
      });
  };

  const handleC4CClose = () => {
    setC4COpen(false);
    setIsApiSuccess(true);
  };
  //     const { onClose, open } = props;
  //     const handleClose = () => {
  //         //onClose(selectedValue);
  //         setOpen(false);
  //     };
  //     const Close = () => {
  //         onClose();
  //     };
  //     return (
  //         <>
  //             <Dialog
  //                 sx={{
  //                     "& .MuiDialog-paper": {
  //                         width: "400px",
  //                         borderRadius: "20px"
  //                     },
  //                 }}
  //                 onClose={handleClose}
  //                 open={open}
  //             >
  //                 <div className="modalclose">
  //                     <img
  //                         onClick={Close}
  //                         src={CloseIcon}
  //                         height="35"
  //                         alt="Back"
  //                     ></img></div>
  //                 <div className="iconimg">
  //                     <img
  //                         onClick={Close}
  //                         src={Exclamation}
  //                         height="35"
  //                         alt="Back"
  //                     ></img>
  //                 </div>
  //                 <div className={classes["upload-report-popup-title"]}>Are You Sure!</div>

  //                 <div style={{ padding: "10px 20px 20px 20px", textAlign: "center" }}>
  //                     <ConfirmDelete message="If you proceed, you will lose all your
  //                 entered data, unsaved changes
  //                 may be lost."
  //                         CloseDeleteDailog={Close}
  //                         textCss="text-message"
  //                         CloseRecommendationDailog={navigateToFinishReport}
  //                         btnText="Confirm"
  //                         btnCss="custom-buttons"
  //                         hideCancel={true}
  //                         variantCSS="contained"
  //                     ></ConfirmDelete>
  //                 </div>
  //             </Dialog>
  //         </>
  //     );
  // }

  // const [openCancel, setCancelOpen] = useState(false);

  // const handleCancelClickOpen = (event: any) => {
  //     event.stopPropagation();
  //     setCancelOpen(true);
  // };

  // const handleCancelClose = () => {
  //     setCancelOpen(false);
  // };

  function onCloseCancelSave() {
    dispatch(hideLoader());
  }

  function onConfirmCancelSave() {
    dispatch(hideLoader());
    CloseDailog();
  }

  const handleTextAreaChange = (event: any) => {
    setComments(event.target.value);
  };

  const handleCancelClickOpen = (event: any) => {
    let customConfirmationDialogProps: CustomConfirmationDialogProps = {
      title: "Are You Sure!",
      content:
        "If you proceed, you will lose all your entered data, unsaved changes may be lost.",
      icon: Exclamation,
      onClose: onCloseCancelSave,
      onConfirm: onConfirmCancelSave,
    };
    dispatch(showConfirmation(customConfirmationDialogProps));
    dispatch(showLoader());
  };

  const handleChange = (id: any, e: any) => {
    const { name, checked } = e.target;
    if (id === 0) {
      let tempData = listData.map((item: any) => {
        return !item.sentToC4C && (item?.leadId === null || item?.leadId === 0)
          ? { ...item, isChecked: checked }
          : item;
      });
      setData(tempData);
    } else {
      let tempData = listData.map((item: any) =>
        item.id === id ? { ...item, isChecked: checked } : item
      );
      setData(tempData);
    }
  };
  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleClickOpenRecommendation = (
    event: any,
    recommendation?: AuditRecommendations & { approxInvestment: number }
  ) => {
    event.stopPropagation();
    setOpenRecommendation(true);

    if (recommendation) {
      setSelectedRecommendation({
        ...recommendation,
        aproxInvestment: recommendation.approxInvestment,
      });
      setIsEditRecommendation(true);
      return;
    }

    setIsEditRecommendation(false);
  };

  const getC4cStatus = (
    sentToC4C: Boolean,
    attemptToSendToC4C: number,
    leadId: number,
    opportunityId: number,
    recommendationSource: string
  ) => {
    if (
      (leadId > 0 || opportunityId > 0) &&
      (recommendationSource === "Linked Opportunity" ||
        recommendationSource === "Linked Lead")
    ) {
      const Icon = LinkedRecommendation;
      const text = C4CStatus.Linked;
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <img src={Icon} /> {text}
        </div>
      );
    } else if (sentToC4C == true || attemptToSendToC4C > 0) {
      const Icon = sentToC4C == true ? CheckCircle : ClearCircle;
      const text = sentToC4C == true ? C4CStatus.Sent : C4CStatus.Failed;
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <img src={Icon} /> {text}
        </div>
      );
    } else if (leadId <= 0 && opportunityId <= 0) {
      const Icon = ActiveRecommendation;
      const text = C4CStatus.New;
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <img src={Icon} /> {text}
        </div>
      );
    }
  };

  return (
    <>
      <CustomAlert
        severity={alertSeverity}
        open={alertOpen}
        message={alertMessage}
        onClose={handleAlertClose}
      />

      <div className={classes["wrapper-table-scroll"]}>
        <Box
          border={"none"}
          boxShadow={"none"}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                {columns.map((column, i) => (
                  <TableCell
                    className={`${classes["table-header"]} ${
                      classes["tabel-cell-border"]
                    } ${
                      column.label === "Name"
                        ? classes["stickyHeader-left"]
                        : ""
                    } ${
                      column.label === "Density"
                        ? classes["stickyHeader-right"]
                        : ""
                    }`}
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <p className="tablethsec">
                      {i === 0 && (
                        <input
                          className="customcheckbox"
                          type="checkbox"
                          name={column.label}
                          checked={
                            listData.filter(
                              (item: any) =>
                                item?.isChecked === true &&
                                item.sentToC4C === false &&
                                (item?.leadId === null || item?.leadId === 0)
                            ).length > 0 &&
                            listData.filter(
                              (item: any) =>
                                item?.isChecked == false &&
                                item.sentToC4C === false &&
                                (item?.leadId === null || item?.leadId === 0)
                            ).length < 1
                          }
                          onChange={(event) => handleChange(0, event)}
                        />
                      )}
                      <span className={classes["table-title"]}>
                        {column.label}
                      </span>
                    </p>
                  </TableCell>
                ))}
                <TableCell
                  className={`${classes["table-header"]} ${classes["stickyHeader-right"]}  ${classes["tabel-cell-border"]}`}
                >
                  <span className={classes["table-title"]}>Action</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listData.length === 0 && (
                <>
                  <TableRow>
                    <TableCell
                      className={classes["tabel-cell-border"]}
                      style={{
                        width: "100%",
                        textAlign: "center",
                        borderRight: "none",
                      }}
                      colSpan={7}
                    >
                      <img
                        src={noDataFound}
                        alt="no-data"
                        width="130px"
                        height="130px"
                        loading="lazy"
                        decoding="async"
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        position: "sticky",
                        right: 0,
                        background: "white",
                        borderLeft: "none",
                        borderRight: "1px solid lightgray",
                      }}
                      className={classes["sticky-right"]}
                    ></TableCell>
                  </TableRow>
                </>
              )}
              {listData.length > 0 &&
                listData.map((row: any) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                    >
                      {columns.map((column, i) => {
                        const columnName = column.id;
                        const value = row[columnName];
                        return (
                          <TableCell className={classes["tabel-cell-border"]}>
                            <Tooltip
                              title={value}
                              placement="right"
                              slotProps={{
                                popper: {
                                  modifiers: [
                                    {
                                      name: "offset",
                                      options: {
                                        offset: [0, -25],
                                      },
                                    },
                                  ],
                                },
                              }}
                            >
                              <span
                                style={{ width: column.minWidth }}
                                className={`${classes["cell-container"]}`}
                              >
                                {i === 0 && (
                                  <input
                                    className="customcheckbox"
                                    type="checkbox"
                                    checked={row?.isChecked || false}
                                    disabled={
                                      row["sentToC4C"] ||
                                      row["leadId"] > 0 ||
                                      row["opportunityId"]
                                    }
                                    name={
                                      column.format && typeof value === "number"
                                        ? column.format(value)
                                        : value
                                    }
                                    onChange={(event) =>
                                      handleChange(row.id, event)
                                    }
                                  />
                                )}
                                {columnName == "C4CStatus"
                                  ? getC4cStatus(
                                      row["sentToC4C"],
                                      row["attemptToSendToC4C"],
                                      row["leadId"],
                                      row["opportunityId"],
                                      row["recommendationSource"]
                                    )
                                  : column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value}
                              </span>
                            </Tooltip>
                          </TableCell>
                        );
                      })}
                      <TableCell
                        key={row.id}
                        className={classes["sticky-right"]}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: "1rem",
                        }}
                      >
                        <Tooltip
                          title={"Link Recommendation"}
                          placement="top"
                        >
                          <img
                            src={LinkIcon}
                            height="30"
                            alt="link"
                            loading="lazy"
                            className={
                              row["sentToC4C"] ||
                              row["leadId"] > 0 ||
                              row["opportunityId"] > 0
                                ? "disabled-icons"
                                : "action-icons"
                            }
                            decoding="async"
                            onClick={() => {
                              setSelectedRecommendationId(row["id"]);
                              setOpenLinkDialog(true);
                            }}
                          />
                        </Tooltip>
                        <Tooltip
                          title={"Edit Recommendation"}
                          placement="top"
                        >
                          <img
                            style={{ marginRight: "10px" }}
                            src={EditIcon}
                            height="24"
                            className={
                              row["sentToC4C"] ||
                              row["leadId"] > 0 ||
                              row["opportunityId"] > 0
                                ? "disabled-icons"
                                : "action-icons"
                            }
                            alt="edit product"
                            loading="lazy"
                            decoding="async"
                            onClick={(event) => {
                              if (!row["sentToC4C"]) {
                                handleClickOpenRecommendation(event, row);
                              }
                            }}
                          />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Box>
      </div>

      {/* <div className={classes["wrapper-txtarea"]}>
        <p className="labels">Comments</p>
        <textarea
          className={classes["custtextcontrol"]}
          value={comments}
          onChange={handleTextAreaChange}
        ></textarea>
        <p className={classes["maxlettertext"]}>
          Explain in 500 character max.
        </p>
      </div> */}
      <Grid
        style={{ marginBlock: "20px" }}
        item
        container
        xs={12}
        sm={12}
        md={12}
        xl={12}
        lg={12}
        direction="row"
        justifyContent="flex-end"
      >
        <Button
          sx={{ textTransform: "none" }}
          className="cancel-button custom-buttons"
          disableRipple
          style={{
            fontSize: "18px",
            fontWeight: "400",
            marginRight: "15px",
          }}
          variant="outlined"
          // onClick={checkErrors}
          onClick={handleCancelClickOpen}
        >
          Cancel
        </Button>
        <Button
          style={{ fontSize: "18px", fontWeight: "400" }}
          type="submit"
          className="custom-buttons"
          // startIcon={uploadReportIcon}
          sx={{
            textTransform: "none",
            "&.Mui-disabled": {
              background: "rgba(0, 0, 0, 0.12) !important",
            },
          }}
          variant="contained"
          disableRipple
          disabled={
            listData &&
            listData.filter((item: any) => item.isChecked === true).length === 0
          }
          size="medium"
          onClick={handleC4CClickOpen}
        >
          <img
            src={SendIcon}
            height="24px"
            width="24px"
            alt="Notifications"
            loading="lazy"
            decoding="async"
          ></img>
          Send to C4C Team
        </Button>
      </Grid>

      {/* <RecommendationsListdailog open={open} onClose={handleClose} /> */}
      <SendC4CDailog
        open={openC4C}
        onClose={handleC4CClose}
      />
      {/* <CancelReportDailog open={openCancel} onClose={handleCancelClose} /> */}
      <AddRecommendationsdailog
        open={openRecommendation}
        onClose={() => {
          setOpenRecommendation(false);
          setSelectedRecommendation(undefined);
        }}
        isEdit={isEditRecommendation}
        selectedRecommendation={selectedRecommendation}
        fromOpportunity={false}
        auditId={auditId}
        auditRecommendationId={0}
        isEditApiCall={true}
        setOpenSuccessDialog={setOpenSuccessDialog}
        setIsApiSuccess={setIsApiSuccess}
      />
      <SavedSuccessDailog
        open={openSuccessDialog}
        isDelete={false}
        edit={isEditRecommendation}
        onClose={() => {
          setOpenSuccessDialog(false);
        }}
      />
      <LinkLeadOrOpportunityDialog
        open={openLinkDialog}
        onClose={(isSuccess) => {
          setOpenLinkDialog(false);
          setSelectedRecommendationId(null);
          if (isSuccess) {
            setIsApiSuccess(true);
            setAlert("success", "Recommendation Linked Successfully!", true);
          }
        }}
        selectedRecommendation={
          selectedRecommendationId
            ? listData.filter((i: any) => i.id === selectedRecommendationId)[0]
            : null
        }
      />
    </>
  );
};

export default RecommendationListTable;
