import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { AuditRecommendations } from "../../store/addRecommendationsDataSlice";
import AddRecommendations from "../../../features/AddRecommendations";
import AddLinkIcon from "@mui/icons-material/AddLink";
import CloseIcon from "../../../assets/icons/close.svg";
import BackArrowIcon from "../../../assets/icons/back_arrow-icon.svg";
import search_icon from "../../../assets/icons/search_icon.svg";
import classes from "./AddRecommendationsdailog.module.css";
import DropdownModel from "../../interface/DropdownModel";
import { FormProvider, Controller, useForm } from "react-hook-form";
import CustomInput from "./CustomInput";
import CustomRibbon from "./CustomRibbon";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Note from "../../../assets/icons/note.svg";
import RedNote from "../../../assets/icons/Note_Red.svg";
import {
  SearchById,
  LeadStatus,
  OpportunityStatus,
  Id,
  LinkProjectData,
} from "../../../features/LinkProject";
import { useState, KeyboardEvent, ClipboardEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useRequest } from "../../../services/agent";
import { RequestApi } from "../../Constant";
import CustomAlertTest from "./CustomAlert";
import { TableColumn } from "../../interface/TableColumn";
import { useCountries } from "../../../services/countries";

interface Props {
  open: boolean;
  onClose: (data: any) => any;
  selectedRecommendation: any;
}

const defaultRibbonStyle = {
  ribbonStyle: {
    bgColor: "#F2F7FB",
    borderColor: "#0078A1",
    paraColor: "#0078A1",
    icon: Note,
  },
  content: [],
};

function LinkLeadOrOpportunityDialog(props: Props) {
  const { onClose, open, selectedRecommendation } = props;
  const [label, setLabel] = useState<"Lead ID" | "Opportunity ID">(
    "Opportunity ID"
  );
  const [linkProjectData, setLinkProjectData] = useState<
    Array<LinkProjectData>
  >([]);
  const [ribbonContent, setRibbonContent] = useState<any>(defaultRibbonStyle);
  const [searchId, setSearchId] = useState<Id>();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState<"success" | "error">(
    "success"
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };
  const request = useRequest();
  const countries = useCountries();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        // marginRight: 10,
      },
    },
  };

  const options = [
    { valueInt: 1, text: "Lead ID" },
    { valueInt: 2, text: "Opportunity ID" },
  ] as DropdownModel[];

  function close(isSuccess: boolean = false) {
    setLinkProjectData([]);
    setRibbonContent(defaultRibbonStyle);
    setValue("SearchBy", "2");
    setLabel("Opportunity ID");
    methods.reset();
    onClose(isSuccess);
  }

  const validationSchema = Yup.object().shape({
    ValueOfId: Yup.string().required("This field is required"),
    SearchBy: Yup.string().required("This field is required"),
  });

  const methods = useForm<any>({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      SearchBy: "2",
    },
  });

  const { control, setValue, getValues } = methods;

  function setAlert(severity: any, message: string, OCflag: boolean) {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(OCflag);
    handleCloseDialog();
  }

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  function renderParaMessges(data: any) {
    let convertedRibbonStyle = {
      bgColor: "#F2F7FB",
      borderColor: "#0078A1",
      paraColor: "#0078A1",
      icon: Note,
    };

    let warningRibbonStyle = {
      bgColor: "#ffe6e6",
      borderColor: "#ff9999",
      paraColor: "#b30000",
      icon: RedNote,
    };

    const {
      leadStatus,
      leadId,
      opportunityId,
      opportunityStatus,
      leadModifiedOn,
      leadCreatedOn,
    } = data;

    const SearchBy = methods.getValues("SearchBy");
    if (SearchBy == SearchById.LeadId) {
      const twoYearAgo = new Date().setFullYear(new Date().getFullYear() - 2);

      const isActive =
        (leadModifiedOn && new Date(leadModifiedOn).getTime() > twoYearAgo) ||
        (leadCreatedOn && new Date(leadCreatedOn).getTime() > twoYearAgo);

      if (leadStatus == LeadStatus.Converted) {
        return {
          ribbonStyle: convertedRibbonStyle,
          content: [
            <p>
              {" "}
              <b>UPDATE</b> Lead Converted
            </p>,
            <p>
              The lead is already converted to Opportunity (
              <b>Opportunity ID - {opportunityId}</b>). You can go ahead and
              link project to opportunity.
            </p>,
          ],
        };
      } else if (
        leadStatus == LeadStatus.Deleted ||
        leadStatus == LeadStatus.Declined
      ) {
        return {
          ribbonStyle: warningRibbonStyle,
          content: [
            <p>
              Lead ID
              <b style={{ margin: "0 0.25em" }}>{leadId}</b>is in
              <b style={{ margin: "0 0.25em" }}>{leadStatus}</b>status{" "}
              {!isActive && " and has been inactive for more than 2 years."}
            </p>,
            <p>If you would like to continue, please proceed.</p>,
          ],
        };
      } else {
        if (!isActive) {
          return {
            ribbonStyle: warningRibbonStyle,
            content: [
              <p>
                Lead ID
                <b style={{ margin: "0 0.25em" }}>{leadId}</b>has been inactive
                for more than 2 years.
              </p>,
              <p>If you would like to continue, please proceed.</p>,
            ],
          };
        }
      }
    } else {
      if (
        opportunityStatus == OpportunityStatus.Stopped ||
        opportunityStatus == OpportunityStatus.Lost
      ) {
        return {
          ribbonStyle: warningRibbonStyle,
          content: [
            <p>
              Opportunity ID
              <b style={{ margin: "0 0.25em" }}>{opportunityId}</b>is in
              <b style={{ margin: "0 0.25em" }}>{opportunityStatus}</b>status
            </p>,
            <p>If you would like to continue, please proceed.</p>,
          ],
        };
      }
    }
    return defaultRibbonStyle;
  }

  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value;
    setLabel(value === "1" ? "Lead ID" : "Opportunity ID");
    setValue("SearchBy", value);
    setLinkProjectData([]);
    setRibbonContent(defaultRibbonStyle);
    // methods.reset();
    methods.setValue("ValueOfId", "");
  };

  function submitData(data: any) {
    let typeOfId = data.SearchBy == 1 ? Id.LeadId : Id.opportunityId;
    setSearchId(typeOfId);
    setRibbonContent({ ...defaultRibbonStyle });
    request({
      url: RequestApi.GET_LINK_PROJECT_LIST,
      method: "post",
      data: {
        country: selectedRecommendation?.country,
        [typeOfId]: data.ValueOfId.trim(),
        recommendationId: selectedRecommendation?.id,
      },
    })
      .then((resp) => {
        let { lead, opportunity, customer } = resp.data;
        customer = {
          ...customer,
          country: countries.getCountryName(customer.country),
        };
        setLinkProjectData([{ ...lead, ...opportunity, ...customer }]);
        setRibbonContent(renderParaMessges({ ...lead, ...opportunity }));
      })
      .catch((err) => {
        setLinkProjectData([]);
        if (err.response?.status === 400) {
          let responseArr = err.response.data;

          if (Array.isArray(responseArr.error)) {
            responseArr?.error?.forEach((element: any) => {
              setAlert("error", element, true);
            });
          } else {
            setAlert("error", "Request Failed", true);
          }

          for (let key in responseArr.result) {
            if (Array.isArray(responseArr.result[key])) {
              responseArr.result[key].forEach((value: any) => {
                setAlert("error", value, true);
              });
            }
          }
        }
      });
  }

  const linkProject = () => {
    let obj = {
      recommendationId: selectedRecommendation?.id,
      leadId: linkProjectData[0]?.leadId ?? null,
      opportunityId: linkProjectData[0]?.opportunityId ?? null,
    };
    request({
      url: RequestApi.POST_LINK_RECOMMENDATION,
      method: "post",
      data: obj,
    })
      .then((resp) => {
        //setAlert("success", "Recommendation Linked Successfully!", true);
        close(true);
      })
      .catch((err) => {
        const message =
          label === "Lead ID"
            ? err.response.data.result.LeadId[0]
            : err.response.data.result.OpportunityId[0];
        setAlert("error", message, true);
      });
  };
  return (
    <Dialog
      sx={{
        "& .MuiDialog-container.MuiDialog-scrollPaper": {
          position: "absolute !important",
          right: "0 !important",
          top: "0 !important",
        },
        "& .MuiDialog-paper": {
          height: "100vh",
          borderRadius: "20px 0 0 20px",
          marginTop: "0",
          marginInline: "0",
          maxHeight: "calc(100% - 32px)",
          minWidth: "700px",
        },
        "& .MuiDialogTitle-root": {
          padding: "16px",
        },
      }}
      onClose={() => {
        close(false);
      }}
      open={open}
    >
      <CustomAlertTest
        severity={alertSeverity}
        open={alertOpen}
        message={alertMessage}
        onClose={handleAlertClose}
        hideTime={5000}
      />

      <DialogTitle>
        <div className={classes["dialog-title-container"]}>
          <div className={classes["page-title"]}>
            <img
              style={{ cursor: "pointer" }}
              onClick={() => {
                close(false);
              }}
              src={BackArrowIcon}
              height="20"
              alt="Back"
              loading="lazy"
              decoding="async"
            />
            <div className={classes["add-recommendation-title"]}>
              Link Lead / Opportunity
            </div>
          </div>
          <div>
            <img
              style={{ cursor: "pointer", float: "right" }}
              onClick={() => {
                close(false);
              }}
              src={CloseIcon}
              height="35"
              alt="Back"
              loading="lazy"
              decoding="async"
            />
          </div>
        </div>
      </DialogTitle>

      <Box
        sx={{
          boxShadow: 0,
          borderBottom: 0,
          backgroundColor: "#fff",
          borderRadius: "8px",
          border: "none",
          paddingInline: "1.5rem",
        }}
        minWidth={"600px"}
        px={1}
        className={classes["form-container"]}
      >
        <FormProvider {...methods}>
          <form
            style={{ height: "auto" }}
            onSubmit={methods.handleSubmit(submitData)}
          >
            <Grid
              container
              py={0.85}
              columnSpacing={2}
              sx={{ paddingBottom: "14px !important" }}
            >
              <Grid
                item
                xs={12}
                md={5}
                xl={5}
                direction="row"
              >
                <FormControl
                  variant="outlined"
                  fullWidth
                >
                  <FormLabel className="labels">
                    {"Search By"}
                    {<span style={{ color: "red", marginLeft: "3px" }}>*</span>}
                  </FormLabel>
                  <Controller
                    name={"SearchBy"}
                    control={control}
                    render={({ field }) => (
                      <Select
                        fullWidth
                        value={field.value ? String(field.value) : ""}
                        MenuProps={MenuProps}
                        name={"SearchBy"}
                        displayEmpty
                        onBlur={field.onBlur}
                        onChange={(e) => {
                          field.onChange(e);
                          handleChange(e);
                        }}
                        id={"SearchBy"}
                        renderValue={(selected) => {
                          return <p>{label}</p>;
                        }}
                      >
                        {options?.map((option) => (
                          <MenuItem
                            key={option.valueInt}
                            value={option?.valueInt?.toString()}
                            disableRipple
                          >
                            {option.text}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <CustomInput
                md={4}
                isRequired={true}
                label={label}
                name="ValueOfId"
                variant="outlined"
                numberOnly={true}
                fullWidth
                onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
                  const isNumeric = /^[0-9]*$/.test(event.key);
                  if (
                    (!isNumeric &&
                      event.key !== "Tab" &&
                      event.key !== "." &&
                      event.key !== "Backspace") ||
                    (event.key === "." &&
                      (event.target as HTMLInputElement).value.includes("."))
                  ) {
                    event.preventDefault();
                  }
                }}
              />
              <Grid
                item
                xs={12}
                md={3}
                xl={3}
                direction="row"
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  startIcon={
                    <img
                      src={search_icon}
                      alt="search-icon"
                      style={{
                        width: "16px",
                        height: "16px",
                      }}
                      loading="lazy"
                      decoding="async"
                    />
                  }
                  sx={{
                    padding: "10px 12px !important",
                    color: "#FFF",
                    fontSize: 18,
                    fontWeight: 400,
                    height: "35px !important",
                    lineHeight: "20px",
                    textTransform: "none",
                    boxShadow: "none !important",
                    gap: "6px !important",
                    marginTop: "20px",
                  }}
                  disableRipple
                  fullWidth
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
        {ribbonContent?.content?.length > 0 && (
          <CustomRibbon
            messagesInHtmlArray={ribbonContent.content}
            md={12}
            style={{
              height: "auto",
              border: `1px solid ${ribbonContent.ribbonStyle.borderColor}`,
              backgroundColor: ribbonContent.ribbonStyle.bgColor,
              borderRadius: "5px",
              marginBottom: "8px",
            }}
            icon={ribbonContent.ribbonStyle.icon}
            iconHeight={18}
            paraColor={ribbonContent.ribbonStyle.paraColor}
          />
        )}
        {linkProjectData.length > 0 && (
          <>
            <Box
              sx={{ border: "unset", boxShadow: "none", marginBlock: "2rem" }}
              display={"grid"}
              gridTemplateColumns={
                label === "Lead ID" &&
                linkProjectData[0]?.leadStatus != "Converted"
                  ? "25% 25% 25% 25%"
                  : "20% 20% 20% 20% 20%"
              }
            >
              <Box
                sx={{
                  border: "unset",
                  boxShadow: "none",
                  borderRight: "1px solid #9F9F9F",
                }}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                gap={"1rem"}
                paddingRight={".5rem"}
                width={"100%"}
                paddingLeft={"unset"}
              >
                <Typography fontWeight={600}>
                  {label === "Lead ID" &&
                  linkProjectData[0]?.leadStatus != "Converted"
                    ? "Lead ID"
                    : "Opportunity ID"}
                </Typography>
                <Typography>
                  {label === "Lead ID" &&
                  linkProjectData[0]?.leadStatus != "Converted"
                    ? linkProjectData[0]?.leadId
                    : linkProjectData[0]?.opportunityId}
                </Typography>
              </Box>
              <Box
                sx={{
                  border: "unset",
                  boxShadow: "none",
                  borderRight: "1px solid #9F9F9F",
                }}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                gap={"1rem"}
                paddingRight={".5rem"}
                width={"100%"}
                paddingLeft={".5rem"}
              >
                <Typography fontWeight={600}>Customer name </Typography>
                <Typography>{linkProjectData[0]?.customerName}</Typography>
              </Box>
              <Box
                sx={{
                  border: "unset",
                  boxShadow: "none",
                  borderRight: "1px solid #9F9F9F",
                }}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                gap={"1rem"}
                paddingRight={".5rem"}
                width={"100%"}
                paddingLeft={".5rem"}
              >
                <Typography fontWeight={600}>Country</Typography>
                <Typography>{linkProjectData[0]?.country}</Typography>
              </Box>
              <Box
                sx={{
                  border: "unset",
                  boxShadow: "none",
                  borderRight:
                    label === "Lead ID" &&
                    linkProjectData[0]?.leadStatus != "Converted"
                      ? "unset"
                      : "1px solid #9F9F9F",
                }}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                gap={"1rem"}
                paddingRight={".5rem"}
                width={"100%"}
                paddingLeft={".5rem"}
              >
                <Typography fontWeight={600}>Current Status</Typography>
                <Typography>
                  {label === "Lead ID" &&
                  linkProjectData[0]?.leadStatus != "Converted"
                    ? linkProjectData[0]?.leadStatus
                    : linkProjectData[0]?.opportunityStatus}
                </Typography>
              </Box>
              {(label !== "Lead ID" ||
                linkProjectData[0]?.leadStatus == "Converted") && (
                <Box
                  sx={{
                    border: "unset",
                    boxShadow: "none",
                  }}
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  gap={"1rem"}
                  paddingRight={".5rem"}
                  width={"100%"}
                  paddingLeft={"1.5rem"}
                >
                  <Typography fontWeight={600}>
                    Amount({linkProjectData[0]?.totalAmountCurrencyCode})
                  </Typography>
                  <Typography>
                    {linkProjectData[0]?.totalAmount > 0
                      ? linkProjectData[0]?.totalAmount
                      : linkProjectData[0]?.totalExpectedValue}
                  </Typography>
                </Box>
              )}
            </Box>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={12}
              xl={12}
              lg={12}
              direction="row"
              justifyContent="flex-end"
            >
              <Button
                sx={{ textTransform: "none" }}
                className="cancel-button custom-buttons"
                disableRipple
                style={{
                  fontSize: "18px",
                  fontWeight: "400",
                  marginRight: "15px",
                }}
                variant="outlined"
                onClick={() => {
                  close(false);
                }}
              >
                Cancel
              </Button>
              <Button
                style={{ fontSize: "18px", fontWeight: "400" }}
                className="custom-buttons"
                sx={{ textTransform: "none" }}
                startIcon={<AddLinkIcon />}
                variant="contained"
                disableRipple
                size="medium"
                onClick={linkProject}
              >
                Link Lead / Opportunity
              </Button>
            </Grid>
          </>
        )}
      </Box>
    </Dialog>
  );
}

export default LinkLeadOrOpportunityDialog;
