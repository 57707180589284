import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Tabs,
  Tab,
  Typography,
  Grid,
  Button,
  Tooltip,
} from "@mui/material";
import CustomTable from "../../common/components/CustomComponents/CustomTable";
import {
  DatePickerMode,
  TableActionButtons,
  TableColumn,
} from "../../common/interface/TableColumn";
import addIcon from "../../assets/icons/recommendation-icon.svg";
import DropdownModel from "../../common/interface/DropdownModel";
import {
  RecommendationDropdownModel,
  RecommendationsSelectList,
} from "../AddRecommendations";
import { RequestApi } from "../../common/Constant";
import { useRequest } from "../../services/agent";
import { useAppDispatch } from "../../common/hooks/hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../common/store";
import {
  AuditRecommendations,
  deleteAllRecommendations,
} from "../../common/store/addRecommendationsDataSlice";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CustomAlertTest from "../../common/components/CustomComponents/CustomAlert";
import EditSavingsInformation from "../EditSavingsInformation";
import AddRecommendationsdailog from "../../common/components/CustomComponents/AddRecommendationsdailog";
import { PageConstants } from "../../common/PageConstants";
import { ShowAuditTypesOnTabConstants } from "../../common/AuditTypeConstants";
import { tab_Style } from "../../common/TabStyleConstants";
import AirNetOpportunities from "./AirNetOpportunities";
import EnergyRecoveryOpportunities from "./EnergyRecoveryOpportunities";
import dayjs from "dayjs";
import FollowUpIcon from "../../assets/icons/FollowUp.svg";
import FollowUpSetIcon from "../../assets/icons/FollowUpSet.svg";
import acknowledgeIcon from "../../assets/icons/ConfirmFollowUp.svg";
import { OperationPermissions } from "../../common/models/operationPermission";
import PermissionService from "../../services/PermissionService";
import CustomDatePicker from "../../common/components/CustomComponents/CustumDatePicker";
import { RoutingConstants } from "../../common/RoutingConstants";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export interface ExportToExcel {
  url: string;
  filename: string;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: 0, background: "#fff", borderRadius: "8px" }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export enum ActualSavings {
  All = "Total Quoted/Expected Value",
  QuotedValue = "Total Quoted Value",
  ExpectedValue = "Total Expected Value",
}
export type tableColumns =
  | "id"
  | "status"
  | "opportunityId"
  | "typeOfInvestment"
  | "typeOfRecommendation"
  | "salesPhase"
  | "approximateInvestment"
  | "minimumEstimatedSavings"
  | "cO2Emission"
  | "auditId"
  | "leadId"
  | "actualSavings";

export interface IOpportunityAirAuditData {
  id: number;
  auditId: number;
  status: string;
  leadId: number;
  OpportunityId: number;
  typeOfInvestment: string;
  typeOfRecommendation: string;
  approximateInvestment: string;
  minimumEstimatedSavings: string;
  cO2Emission: string;
  actualSavings: string;
  investmentTypeId: number;
  recommendationTypeId: number;
  customerName: string;
  salesPhase: string;
  opportunityCreatedDate: string;
  opportunityResponsiblePerson: string;
}

interface IOpportunityVibrationAuditData {
  id: number;
  auditId: number;
  status: string;
  leadId: number;
  OpportunityId: number;
  typeOfInvestment: string;
  typeOfRecommendation: string;
  approximateInvestment: string;
  minimumEstimatedSavings: string;
  actualSavings: string;
  customerName: string;
  salesPhase: string;
  opportunityCreatedDate: string;
  opportunityResponsiblePerson: string;
}

export const statusDropDownData: DropdownModel[] = [
  {
    text: "Won",
    value: "Won",
    valueInt: 1,
  },
  {
    text: "Lost",
    value: "Lost",
    valueInt: 2,
  },
  {
    text: "Stopped",
    value: "Stopped",
    valueInt: 3,
  },
  {
    text: "In Process",
    value: "In Process",
    valueInt: 4,
  },
  {
    text: "Open",
    value: "Open",
    valueInt: 5,
  },
];

export const salesPhaseDropDownData: DropdownModel[] = [
  {
    text: "Qualification",
    value: "Qualification",
    valueInt: 1,
  },
  {
    text: "Proposal",
    value: "Proposal",
    valueInt: 2,
  },
  {
    text: "Negotiation",
    value: "Negotiation",
    valueInt: 3,
  },
  {
    text: "Quotation",
    value: "Quotation",
    valueInt: 4,
  },
  {
    text: "Decision",
    value: "Decision",
    valueInt: 5,
  },
  {
    text: "Closing",
    value: "Closing",
    valueInt: 6,
  },
  {
    text: "Satisfy",
    value: "Satisfy",
    valueInt: 7,
  },
];

export const actualSavingsDropDownData: DropdownModel[] = [
  {
    text: "All",
    value: "ALL",
    valueInt: 1,
  },
  {
    text: "Total Quoted Value",
    value: "Total Quoted Value",
    valueInt: 2,
  },
  {
    text: "Total Expected Value",
    value: "Total Expected Value",
    valueInt: 3,
  },
];
export const getIsTotalQuotedValue = (valueInt: number) => {
  let values = [null, true, false];

  return values[valueInt - 1];
};

const GeneratedOpportunities = () => {
  const [value, setValue] = useState(0);
  const location = useLocation();
  const filterState = location.state;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const opportunityId = searchParams.get("opid");
  const group = searchParams.get("group");
  const [openEditSavings, setOpenEditSavings] = useState(false);
  const [selectList, setSelectList] = useState<RecommendationsSelectList>();
  const [investmentId, setInvestmentId] = useState<number>();
  const [auditNumber, setAuditNumber] = useState<string>("");
  const [auditId, setAuditId] = useState<number>(0);
  const [recommendationId, setRecommendationId] = useState<number>();
  const [auditRecommendationId, setAuditRecommendationId] = useState<
    number | null
  >(null);
  const [totalQuotedValue, setTotalQuotedValue] = useState<number>(0);
  const [pageSpecificData, setPageSpecificData] = useState<{
    opportunityStausUpdatedOn: "string";
  }>();

  const [FollowUp, setFollowUp] = useState({
    isOpen: false,
    isEdit: false,
    lastFollowUp: "",
    recommendationId: 0,
  });
  const generatedLeadPermission: OperationPermissions = PermissionService(
    PageConstants.GeneratedLeads
  );
  //air audit states
  const [airAuditPage, setAirAuditPage] = useState(0);
  const [airAuditTotalRecords, setAirAuditTotalRecords] = useState<number>(0);
  const [airAuditData, setAirAuditData] = useState<
    Array<IOpportunityAirAuditData>
  >([]);
  const [airAuditFilterStatus, setAirAuditFilterStatus] = useState<number>(0);
  const [airAuditFilterLeadId, setAirAuditFilterLeadId] = useState<
    number | null
  >(null);
  const [airAuditFilterOppurtunityId, setAirAuditFilterOppurtunityId] =
    useState<number | null>(null);
  const [airAuditFilterAuditId, setAirAuditAuditId] = useState<string>("");
  const [airAuditFilterTypeOfInvestment, setAirAuditFilterTypeOfInvestment] =
    useState<string>("");
  const [
    airAuditFilterTypeOfRecommendation,
    setAirAuditFilterTypeOfRecommendation,
  ] = useState<string>("");
  const [airAuditFilterApproxInvestment, setAirAuditFilterApproxInvestment] =
    useState<string>("");
  const [
    airAuditFilterMinEstimatedSavings,
    setAirAuditFilterMinEstimatedSavings,
  ] = useState<string>("");
  const [
    airAuditFilterApproximateInvestment,
    setAirAuditFilterApproximateInvestment,
  ] = useState<number | undefined>();

  const [
    airAuditFilterMinimumEstimatedSavings,
    setAirAuditFilterMinimumEstimatedSavings,
  ] = useState<number | undefined>();

  const [airAuditFilterCO2Emission, setAirAuditFilterCO2Emission] =
    useState<string>("");

  const [airAuditFilterCustomerName, setAirAuditFilterCustomerName] =
    useState<string>("");

  const [airAuditFilterSalesPhase, setAirAuditFilterSalesPhase] =
    useState<number>(0);

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState<"success" | "error">(
    "success"
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [airAuditSortingColumn, setAirAuditSortingColumn] =
    useState<string>("");
  const [airAuditSortingOrder, setAirAuditSortingOrder] = useState<string>("");
  const [getAirData, setGetAirData] = useState(false);
  const [airAuditActualSavingValueInt, setAirAuditActualSavingValueInt] =
    useState(1);

  const [salesAirColumns, setSalesAirColumns] = useState<TableColumn[]>([]);
  const [airAuditFilterProjectSource, setAirAuditFilterProjectSource] =
    useState<string>("");
  const [
    airAuditFilterOpportunityCreateDate,
    setAirAuditFilterOpportunityCreateDate,
  ] = useState("");
  const [
    airAuditFilterOpportunityResponsible,
    setAirAuditFilterOpportunityResponsible,
  ] = useState("");
  const [
    airAuditFilterLocalOpportunityCreateDate,
    setAirAuditFilterLocalOpportunityCreateDate,
  ] = useState<string>("");

  //airnet state start
  const [airNetAuditFilterStatus, setAirNetAuditFilterStatus] =
    useState<number>(0);
  const [airNetAuditFilterLeadId, setAirNetAuditFilterLeadId] = useState<
    number | null
  >(null);
  const [airNetAuditFilterOppurtunityId, setAirNetAuditFilterOppurtunityId] =
    useState<number | null>(null);
  const [airNetAuditFilterAuditId, setAirNetAuditAuditId] =
    useState<string>("");

  const [airNetAuditFilterCustomerName, setAirNetAuditFilterCustomerName] =
    useState<string>("");

  const [airNetAuditFilterSalesPhase, setAirNetAuditFilterSalesPhase] =
    useState<number>(0);
  const [airNetAuditActualSavingValueInt, setAirNetAuditActualSavingValueInt] =
    useState(1);
  const [airNetAuditSortingColumn, setAirNetAuditSortingColumn] =
    useState<string>("");
  const [airNetAuditSortingOrder, setAirNetAuditSortingOrder] =
    useState<string>("");
  const [airNetAuditPage, setAirNetAuditPage] = useState(0);
  const [
    airNetAuditFilterOpportunityCreateDate,
    setAirNetAuditFilterOpportunityCreateDate,
  ] = useState("");
  const [
    airNetAuditFilterOpportunityResponsible,
    setAirNetAuditFilterOpportunityResponsible,
  ] = useState("");
  const [
    airNetAuditFilterLocalOpportunityCreateDate,
    setAirNetAuditFilterLocalOpportunityCreateDate,
  ] = useState<string>("");
  const [getAirNetData, setGetAirNetData] = useState(false);

  const airNetAuditFilters = {
    airNetAuditFilterStatus,
    setAirNetAuditFilterStatus,
    airNetAuditFilterLeadId,
    setAirNetAuditFilterLeadId,
    airNetAuditFilterOppurtunityId,
    setAirNetAuditFilterOppurtunityId,
    airNetAuditFilterAuditId,
    setAirNetAuditAuditId,
    airNetAuditFilterCustomerName,
    setAirNetAuditFilterCustomerName,
    airNetAuditFilterSalesPhase,
    setAirNetAuditFilterSalesPhase,
    airNetAuditActualSavingValueInt,
    setAirNetAuditActualSavingValueInt,
    airNetAuditPage,
    setAirNetAuditPage,
    airNetAuditFilterOpportunityCreateDate,
    setAirNetAuditFilterOpportunityCreateDate,
    airNetAuditFilterOpportunityResponsible,
    setAirNetAuditFilterOpportunityResponsible,
    airNetAuditFilterLocalOpportunityCreateDate,
    setAirNetAuditFilterLocalOpportunityCreateDate,
    getAirNetData,
    setGetAirNetData,
  };

  const airNetSorting = {
    airNetAuditSortingColumn,
    setAirNetAuditSortingColumn,
    airNetAuditSortingOrder,
    setAirNetAuditSortingOrder,
  };

  //airnet state end

  //Energy Recovery state start
  const [energyRecoveryAuditFilterStatus, setEnergyRecoveryAuditFilterStatus] =
    useState<number>(0);
  const [energyRecoveryAuditFilterLeadId, setEnergyRecoveryAuditFilterLeadId] =
    useState<number | null>(null);
  const [
    energyRecoveryAuditFilterOppurtunityId,
    setEnergyRecoveryAuditFilterOppurtunityId,
  ] = useState<number | null>(null);
  const [energyRecoveryAuditFilterAuditId, setEnergyRecoveryAuditAuditId] =
    useState<string>("");

  const [
    energyRecoveryAuditFilterCustomerName,
    setEnergyRecoveryAuditFilterCustomerName,
  ] = useState<string>("");

  const [
    energyRecoveryAuditFilterSalesPhase,
    setEnergyRecoveryAuditFilterSalesPhase,
  ] = useState<number>(0);
  const [
    energyRecoveryAuditActualSavingValueInt,
    setEnergyRecoveryAuditActualSavingValueInt,
  ] = useState(1);
  const [
    energyRecoveryAuditSortingColumn,
    setEnergyRecoveryAuditSortingColumn,
  ] = useState<string>("");
  const [energyRecoveryAuditSortingOrder, setEnergyRecoveryAuditSortingOrder] =
    useState<string>("");
  const [energyRecoveryAuditPage, setEnergyRecoveryAuditPage] = useState(0);

  const [
    energyRecoveryAuditFilterOpportunityCreateDate,
    setEnergyRecoveryAuditFilterOpportunityCreateDate,
  ] = useState("");
  const [
    energyRecoveryAuditFilterOpportunityResponsible,
    setEnergyRecoveryAuditFilterOpportunityResponsible,
  ] = useState("");
  const [
    energyRecoveryAuditFilterLocalOpportunityCreateDate,
    setEnergyRecoveryAuditFilterLocalOpportunityCreateDate,
  ] = useState<string>("");
  const [getEnergyRecoveryData, setGetEnergyRecoveryData] = useState(false);

  const energyRecoveryAuditFilters = {
    energyRecoveryAuditFilterStatus,
    setEnergyRecoveryAuditFilterStatus,
    energyRecoveryAuditFilterLeadId,
    setEnergyRecoveryAuditFilterLeadId,
    energyRecoveryAuditFilterOppurtunityId,
    setEnergyRecoveryAuditFilterOppurtunityId,
    energyRecoveryAuditFilterAuditId,
    setEnergyRecoveryAuditAuditId,
    energyRecoveryAuditFilterCustomerName,
    setEnergyRecoveryAuditFilterCustomerName,
    energyRecoveryAuditFilterSalesPhase,
    setEnergyRecoveryAuditFilterSalesPhase,
    energyRecoveryAuditActualSavingValueInt,
    setEnergyRecoveryAuditActualSavingValueInt,
    energyRecoveryAuditPage,
    setEnergyRecoveryAuditPage,
    energyRecoveryAuditFilterOpportunityCreateDate,
    setEnergyRecoveryAuditFilterOpportunityCreateDate,
    energyRecoveryAuditFilterOpportunityResponsible,
    setEnergyRecoveryAuditFilterOpportunityResponsible,
    energyRecoveryAuditFilterLocalOpportunityCreateDate,
    setEnergyRecoveryAuditFilterLocalOpportunityCreateDate,
    getEnergyRecoveryData,
    setGetEnergyRecoveryData,
  };

  const energyRecoverySorting = {
    energyRecoveryAuditSortingColumn,
    setEnergyRecoveryAuditSortingColumn,
    energyRecoveryAuditSortingOrder,
    setEnergyRecoveryAuditSortingOrder,
  };

  //Energy Recovery state end

  const airAuditfilterObject: any = useMemo(
    () => ({
      auditNumber: airAuditFilterAuditId,
      leadId: airAuditFilterLeadId ? airAuditFilterLeadId : "",
      opportunityId: airAuditFilterOppurtunityId
        ? airAuditFilterOppurtunityId
        : "",
      status: airAuditFilterStatus,
      typeOfInvestment: airAuditFilterTypeOfInvestment,
      typeOfRecommendation: airAuditFilterTypeOfRecommendation,
      approximateInvestment: airAuditFilterApproxInvestment,
      minimumEstimatedSavings: airAuditFilterMinEstimatedSavings,
      cO2Emission: airAuditFilterCO2Emission,
      actualSavings: airAuditActualSavingValueInt,
      customerName: airAuditFilterCustomerName,
      salesPhase: airAuditFilterSalesPhase,
      projectSource: airAuditFilterProjectSource,
      opportunityCreatedDate: dayjs(
        airAuditFilterLocalOpportunityCreateDate
      ).isValid()
        ? dayjs(airAuditFilterLocalOpportunityCreateDate)
        : "",
      opportunityResponsiblePerson: airAuditFilterOpportunityResponsible,
    }),
    [
      airAuditFilterAuditId,
      airAuditFilterLeadId,
      airAuditFilterOppurtunityId,
      airAuditFilterStatus,
      airAuditFilterTypeOfInvestment,
      airAuditFilterTypeOfRecommendation,
      airAuditFilterApproxInvestment,
      airAuditFilterMinEstimatedSavings,
      airAuditFilterCO2Emission,
      airAuditFilterCustomerName,
      airAuditFilterSalesPhase,
      airAuditActualSavingValueInt,
      airAuditFilterProjectSource,
      airAuditFilterOpportunityResponsible,
      airAuditFilterLocalOpportunityCreateDate,
    ]
  );
  function setAlert(severity: any, message: string, OCflag: boolean) {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(OCflag);
    handleCloseDialog();
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };
  //vibration audit starts
  const [vibrationAuditPage, setVibrationAuditPage] = useState(0);
  const [vibrationAuditTotalRecords, setVibrationAuditTotalRecords] =
    useState<number>(0);
  const [vibrationAuditData, setVibrationAuditData] = useState<
    Array<IOpportunityVibrationAuditData>
  >([]);
  const [vibrationAuditFilterLeadId, setVibrationAuditFilterLeadId] = useState<
    number | null
  >(null);
  const [
    vibrationAuditFilterOppurtunityId,
    setVibrationAuditFilterOppurtunityId,
  ] = useState<number | null>(null);
  const [vibrationAuditFilterAuditId, setVibrationAuditAuditId] =
    useState<string>("");
  const [vibrationAuditFilterStatus, setVibrationAuditFilterStatus] =
    useState<number>(0);
  const [
    vibrationAuditFilterTypeOfInvestment,
    setVibrationAuditFilterTypeOfInvestment,
  ] = useState<string>("");
  const [
    vibrationAuditFilterTypeOfRecommendation,
    setVibrationAuditFilterTypeOfRecommendation,
  ] = useState<string>("");
  const [
    vibrationAuditFilterApproxInvestment,
    setVibrationAuditFilterApproxInvestment,
  ] = useState<string>("");
  const [
    vibrationAuditFilterMinEstimatedSavings,
    setVibrationAuditFilterMinEstimatedSavings,
  ] = useState<string>("");
  const [
    vibrationAuditFilterApproximateInvestment,
    setVibrationAuditFilterApproximateInvestment,
  ] = useState<number | undefined>();

  const [
    vibrationAuditFilterMinimumEstimatedSavings,
    setVibrationAuditFilterMinimumEstimatedSavings,
  ] = useState<number | undefined>();

  const [
    vibrationAuditFilterCustomerName,
    setVibrationAuditFilterCustomerName,
  ] = useState<string>("");

  const [vibrationAuditSortingColumn, setVibrationAuditSortingColumn] =
    useState<string>("");
  const [vibrationAuditSortingOrder, setVibrationAuditSortingOrder] =
    useState<string>("");
  const [getVibrationData, setGetVibrationData] = useState(true);
  const [vibrationAuditFilterSalesPhase, setVibrationAuditFilterSalesPhase] =
    useState<number>(0);
  const [
    vibrationAuditActualSavingValueInt,
    setVibrationAuditActualSavingValueInt,
  ] = useState(1);
  const [
    vibrationAuditFilterOpportunityCreateDate,
    setVibrationAuditFilterOpportunityCreateDate,
  ] = useState("");
  const [
    vibrationAuditFilterOpportunityResponsible,
    setVibrationAuditFilterOpportunityResponsible,
  ] = useState("");
  const [
    vibrationAuditFilterLocalOpportunityCreateDate,
    setVibrationAuditFilterLocalOpportunityCreateDate,
  ] = useState<string>("");

  const vibrationAuditfilterObject: any = useMemo(
    () => ({
      auditNumber: vibrationAuditFilterAuditId,
      leadId: vibrationAuditFilterLeadId ? vibrationAuditFilterLeadId : "",
      opportunityId: vibrationAuditFilterOppurtunityId
        ? vibrationAuditFilterOppurtunityId
        : "",
      status: vibrationAuditFilterStatus,
      typeOfInvestment: vibrationAuditFilterTypeOfInvestment,
      typeOfRecommendation: vibrationAuditFilterTypeOfRecommendation,
      approximateInvestment: vibrationAuditFilterApproxInvestment,
      minimumEstimatedSavings: vibrationAuditFilterMinEstimatedSavings,
      actualSavings: vibrationAuditActualSavingValueInt,
      customerName: vibrationAuditFilterCustomerName,
      salesPhase: vibrationAuditFilterSalesPhase,
      opportunityCreatedDate: dayjs(
        vibrationAuditFilterLocalOpportunityCreateDate
      ).isValid()
        ? dayjs(vibrationAuditFilterLocalOpportunityCreateDate)
        : "",
      opportunityResponsiblePerson: vibrationAuditFilterOpportunityResponsible,
    }),

    [
      vibrationAuditFilterAuditId,
      vibrationAuditFilterLeadId,
      vibrationAuditFilterOppurtunityId,
      vibrationAuditFilterStatus,
      vibrationAuditFilterTypeOfInvestment,
      vibrationAuditFilterTypeOfRecommendation,
      vibrationAuditFilterApproxInvestment,
      vibrationAuditFilterMinEstimatedSavings,
      vibrationAuditActualSavingValueInt,
      vibrationAuditFilterCustomerName,
      vibrationAuditFilterSalesPhase,
      vibrationAuditFilterOpportunityResponsible,
      vibrationAuditFilterLocalOpportunityCreateDate,
    ]
  );

  function getSelectedRecommendations() {
    const selectedRecommendations = selectList?.typeOfRecommendation.filter(
      (recommendation) => recommendation.investmentId === investmentId
    );

    return selectedRecommendations;
  }

  useEffect(() => {
    let actualSavings: ActualSavings;
    if (value == 0) {
      actualSavings =
        airAuditActualSavingValueInt == 1
          ? ActualSavings.All
          : airAuditActualSavingValueInt == 2
          ? ActualSavings.QuotedValue
          : ActualSavings.ExpectedValue;
    } else {
      actualSavings =
        vibrationAuditActualSavingValueInt == 1
          ? ActualSavings.All
          : vibrationAuditActualSavingValueInt == 2
          ? ActualSavings.QuotedValue
          : ActualSavings.ExpectedValue;
    }

    const airColumns: TableColumn[] = [
      {
        id: "opportunityId" as tableColumns,
        label: " Opportunity ID",
        align: "left",
        minWidth: 140,
        filterType: "text",
        filter: true,
        sorting: true,
      },
      {
        id: "status" as tableColumns,
        label: "Status",
        align: "left",
        minWidth: 140,
        filterType: "dropdown",
        filter: true,
        dropdownData: statusDropDownData,
      },
      {
        id: "salesPhase" as tableColumns,
        label: "Sales Phase",
        align: "left",
        minWidth: 140,
        filterType: "dropdown",
        filter: true,
        dropdownData: salesPhaseDropDownData,
      },
      {
        id: "customerName" as tableColumns,
        label: "Customer Name",
        align: "left",
        minWidth: 250,
        filter: true,
        filterType: "text",
        sorting: true,
      },

      {
        id: "typeOfInvestment" as tableColumns,
        label: "Category of Product",
        minWidth: 180,
        align: "left",
        filterType: "dropdown",
        filter: true,
        dropdownData: selectList
          ? (selectList.typeOfInvestment as DropdownModel[])
          : ([] as DropdownModel[]),
      },
      {
        id: "typeOfRecommendation" as tableColumns,
        label: "Type of Product",
        minWidth: 195,
        align: "left",
        filterType: "dropdown",
        filter: true,
        dropdownData: selectList
          ? (getSelectedRecommendations() as RecommendationDropdownModel[])
          : ([] as RecommendationDropdownModel[]),
      },

      {
        id: "actualSavings",
        label: actualSavings,
        minWidth: 170,
        align: "left",
        filterType: "dropdown",
        filter: true,
        dropdownData: actualSavingsDropDownData,
      },
      // {
      //   id: 'approximateInvestment' as tableColumns,
      //   label: 'Approx. Investment',
      //   minWidth: 150,
      //   align: 'left',
      //   filterType: 'text',
      //   filter: true,
      //   sorting: true,
      // },
      {
        id: "minimumEstimatedSavings" as tableColumns,
        label: "Minimum Estimated Savings (kWh/annum)",
        minWidth: 170,
        align: "left",
        filterType: "text",
        filter: true,
        sorting: true,
      },
      {
        id: "cO2Emission" as tableColumns,
        label: "Electricity CO2 Emission",
        minWidth: 170,
        align: "left",
        filterType: "text",
        filter: true,
        sorting: true,
        format: (value: number) => value.toFixed(2),
      },
      {
        id: "auditNumber" as tableColumns,
        label: "Project ID",
        align: "left",
        minWidth: 120,
        filter: true,
        filterType: "text",
        sorting: true,
      },
      {
        id: "leadId" as tableColumns,
        label: "Lead ID",
        minWidth: 120,
        align: "left",
        filter: true,
        filterType: "text",
        sorting: true,
      },
      {
        id: "opportunityCreatedDate",
        label: "Opportunity Created Date",
        minWidth: 120,
        align: "left",
        format: (value: number) => value.toLocaleString("en-US"),
        filter: true,
        filterType: "datepicker",
        sorting: true,
        datePickerMode: DatePickerMode.MonthYear,
        datePickerLimit: { minDate: dayjs("2014-01-01") },
      },
      {
        id: "opportunityResponsiblePerson",
        label: "C4C Owner",
        minWidth: 140,
        align: "left",
        format: (value: number) => value.toFixed(2),
        filter: true,
        sorting: true,
      },
    ];

    setSalesAirColumns(airColumns);
  }, [
    airAuditActualSavingValueInt,
    vibrationAuditActualSavingValueInt,
    value,
    selectList,
    investmentId,
  ]);

  const SalesVibrationColumns: TableColumn[] = [
    ...salesAirColumns.filter(
      (column) => column.id !== "cO2Emission" && column.id !== "projectSource"
    ),
  ];

  useEffect(() => {
    request(RequestApi.GET_RECOMMENDATIONS_SELECT_LIST)
      .then((resp) => setSelectList(resp.data))
      .catch((error) => console.error(error));
    setGetAirData(true);
    dispatch(deleteAllRecommendations());
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (newValue === 0) {
      setGetAirData(true);
    } else if (newValue === 1) {
      setGetVibrationData(true);
    }
  };

  const handleEditSavingsClose = () => {
    setOpenEditSavings(false);
    if (value === 0) {
      setGetAirData(true);
    } else if (value === 1) {
      setGetVibrationData(true);
    }
  };

  const handleAirFilterChange = (id: any, event: any) => {
    switch (id) {
      case "typeOfInvestment":
        setAirAuditFilterTypeOfInvestment(event.target.value);
        setInvestmentId(event.target.value);
        break;
      case "typeOfRecommendation":
        setAirAuditFilterTypeOfRecommendation(event.target.value);
        setRecommendationId(event.target.value);
        break;
      case "auditNumber":
        setAirAuditAuditId(event.target.value);
        break;
      case "approximateInvestment":
        var isNumber = parseInt(event.target.value);
        if (Number.isNaN(isNumber)) {
          setAirAuditFilterApproximateInvestment(undefined);
          setAirAuditFilterApproxInvestment("");
        } else {
          setAirAuditFilterApproximateInvestment(Number(event.target.value));
          setAirAuditFilterApproxInvestment(event.target.value);
        }

        break;
      case "minimumEstimatedSavings":
        var isNumber = parseInt(event.target.value);
        if (Number.isNaN(isNumber)) {
          setAirAuditFilterMinimumEstimatedSavings(undefined);
          setAirAuditFilterMinEstimatedSavings("");
        } else {
          setAirAuditFilterMinimumEstimatedSavings(Number(event.target.value));
          setAirAuditFilterMinEstimatedSavings(event.target.value);
        }
        break;
      case "status":
        setAirAuditFilterStatus(event.target.value);
        break;
      case "actualSavings":
        setAirAuditActualSavingValueInt(event.target.value);
        break;
      case "cO2Emission":
        var isNumber = parseInt(event.target.value);
        if (Number.isNaN(isNumber)) {
          setAirAuditFilterCO2Emission("");
        } else {
          setAirAuditFilterCO2Emission(event.target.value);
        }
        break;
      case "leadId":
        var isNumber = parseInt(event.target.value);
        if (Number.isNaN(isNumber)) {
          setAirAuditFilterLeadId(null);
        } else {
          setAirAuditFilterLeadId(event.target.value);
        }
        break;
      case "opportunityId":
        var isNumber = parseInt(event.target.value);
        if (Number.isNaN(isNumber)) {
          setAirAuditFilterOppurtunityId(null);
        } else {
          setAirAuditFilterOppurtunityId(event.target.value);
        }
        break;
      case "customerName":
        setAirAuditFilterCustomerName(event.target.value);
        break;
      case "salesPhase":
        setAirAuditFilterSalesPhase(event.target.value);
        break;
      case "projectSource":
        setAirAuditFilterProjectSource(event.target.value);
        break;
      case "opportunityCreatedDate":
        if (event && event.$y > 1800 && event.$d !== "Invalid Date") {
          setAirAuditFilterOpportunityCreateDate(
            dayjs(event.$d).format("DD/MM/YYYY")
          );
          setAirAuditFilterLocalOpportunityCreateDate(event.$d);
        } else {
          setAirAuditFilterOpportunityCreateDate("");
          setAirAuditFilterLocalOpportunityCreateDate("");
        }
        break;
      case "opportunityResponsiblePerson":
        setAirAuditFilterOpportunityResponsible(event.target.value);
        break;
    }
  };

  const clearAllAirAuditSorting = useCallback(() => {
    if (opportunityId) navigate("/" + RoutingConstants.GeneratedOpportunities);

    setAirAuditPage(0);
    setAirAuditFilterStatus(0);
    setAirAuditSortingColumn("");
    setAirAuditSortingOrder("");
    setAirAuditAuditId("");
    setAirAuditFilterLeadId(null);
    setAirAuditFilterOppurtunityId(null);
    setAirAuditFilterTypeOfInvestment("");
    setAirAuditFilterTypeOfRecommendation("");
    setInvestmentId(0);
    setRecommendationId(0);
    setAirAuditFilterOpportunityCreateDate("");
    setAirAuditFilterLocalOpportunityCreateDate("");
    setAirAuditFilterOpportunityResponsible("");
    setAirAuditFilterCO2Emission("");
    setAirAuditFilterApproxInvestment("");
    setAirAuditFilterMinEstimatedSavings("");
    setAirAuditFilterApproximateInvestment(undefined);
    setAirAuditFilterMinimumEstimatedSavings(undefined);
    setAirAuditFilterCustomerName("");
    setGetAirData(true);
    setAirAuditFilterSalesPhase(0);
    setAirAuditActualSavingValueInt(1);
    setAirAuditFilterProjectSource("");
  }, []);

  const handleVibrationFilterChange = (id: any, event: any) => {
    switch (id) {
      case "typeOfInvestment":
        setVibrationAuditFilterTypeOfInvestment(event.target.value);
        setInvestmentId(event.target.value);
        break;
      case "typeOfRecommendation":
        setVibrationAuditFilterTypeOfRecommendation(event.target.value);
        setRecommendationId(event.target.value);
        break;
      case "auditNumber":
        setVibrationAuditAuditId(event.target.value);
        break;
      case "approximateInvestment":
        var isNumber = parseInt(event.target.value);
        if (Number.isNaN(isNumber)) {
          setVibrationAuditFilterApproximateInvestment(undefined);
          setVibrationAuditFilterApproxInvestment("");
        } else {
          setVibrationAuditFilterApproximateInvestment(
            Number(event.target.value)
          );
          setVibrationAuditFilterApproxInvestment(event.target.value);
        }
        break;
      case "minimumEstimatedSavings":
        var isNumber = parseInt(event.target.value);
        if (Number.isNaN(isNumber)) {
          setVibrationAuditFilterMinimumEstimatedSavings(undefined);
          setVibrationAuditFilterMinEstimatedSavings("");
        } else {
          setVibrationAuditFilterMinimumEstimatedSavings(
            Number(event.target.value)
          );
          setVibrationAuditFilterMinEstimatedSavings(event.target.value);
        }
        break;
      case "status":
        setVibrationAuditFilterStatus(event.target.value);
        break;
      case "actualSavings":
        setVibrationAuditActualSavingValueInt(event.target.value);
        break;
      case "leadId":
        var isNumber = parseInt(event.target.value);
        if (Number.isNaN(isNumber)) {
          setVibrationAuditFilterLeadId(null);
        } else {
          setVibrationAuditFilterLeadId(event.target.value);
        }
        break;
      case "opportunityId":
        var isNumber = parseInt(event.target.value);
        if (Number.isNaN(isNumber)) {
          setVibrationAuditFilterOppurtunityId(null);
        } else {
          setVibrationAuditFilterOppurtunityId(event.target.value);
        }
        break;
      case "customerName":
        setVibrationAuditFilterCustomerName(event.target.value);
        break;
      case "salesPhase":
        setVibrationAuditFilterSalesPhase(event.target.value);
        break;
      case "opportunityCreatedDate":
        if (event && event.$y > 1800 && event.$d !== "Invalid Date") {
          setVibrationAuditFilterOpportunityCreateDate(
            dayjs(event.$d).format("DD/MM/YYYY")
          );
          setVibrationAuditFilterLocalOpportunityCreateDate(event.$d);
        } else {
          setVibrationAuditFilterOpportunityCreateDate("");
          setVibrationAuditFilterLocalOpportunityCreateDate("");
        }
        break;
      case "opportunityResponsiblePerson":
        setVibrationAuditFilterOpportunityResponsible(event.target.value);
        break;
    }
  };

  const clearAllVibrationSorting = useCallback(() => {
    if (opportunityId) navigate("/" + RoutingConstants.GeneratedOpportunities);

    setVibrationAuditPage(0);
    setVibrationAuditFilterStatus(0);
    setVibrationAuditSortingColumn("");
    setVibrationAuditSortingOrder("");
    setVibrationAuditAuditId("");
    setVibrationAuditFilterLeadId(null);
    setVibrationAuditFilterOppurtunityId(null);
    setVibrationAuditFilterTypeOfInvestment("");
    setVibrationAuditFilterTypeOfRecommendation("");
    setInvestmentId(0);
    setRecommendationId(0);
    setVibrationAuditFilterOpportunityCreateDate("");
    setVibrationAuditFilterLocalOpportunityCreateDate("");
    setVibrationAuditFilterOpportunityResponsible("");
    setVibrationAuditFilterApproxInvestment("");
    setVibrationAuditFilterMinEstimatedSavings("");
    setVibrationAuditFilterApproximateInvestment(undefined);
    setVibrationAuditFilterMinimumEstimatedSavings(undefined);
    setVibrationAuditFilterCustomerName("");
    setAirAuditActualSavingValueInt(1);
    setGetVibrationData(true);
    setVibrationAuditFilterSalesPhase(0);
    setVibrationAuditActualSavingValueInt(1);
  }, []);

  const actionButtons: TableActionButtons[] = [
    {
      icon: addIcon,
      handleClick: (
        auditRecommendationId: number,
        actualSavings: string,
        auditId: number,
        auditNumber: string
      ) => {
        setAuditRecommendationId(auditRecommendationId);

        setTotalQuotedValue(Number(actualSavings.split(" ")[0]));
        setAuditId(auditId);
        setAuditNumber(auditNumber);
        setOpenEditSavings(true);
      },
      name: "Edit Products",
      text: "editProducts",
    },
    {
      text: "SetFollowUp",
      name: "Set Follow Up",
      icon: FollowUpIcon,
      handleClick: (...args: any[]) => {
        const row = args[3];
        setFollowUp({
          lastFollowUp: "",
          isEdit: false,
          isOpen: true,
          recommendationId: row.auditRecommendationId,
        });
      },
      permission: generatedLeadPermission.create,
    },
    {
      text: "UpdateFollowUp",
      name: "Update Follow Up",
      icon: FollowUpSetIcon,
      handleClick: (...args: any[]) => {
        const row = args[3];

        setFollowUp({
          isEdit: true,
          isOpen: true,
          lastFollowUp: row?.opportunityFollowUpDate,
          recommendationId: row.auditRecommendationId,
        });
      },
      permission: generatedLeadPermission.update,
    },
    {
      text: "AcknowledgeFollowUp",
      name: "Acknowledge Follow Up",
      icon: acknowledgeIcon,
      handleClick: (...args: any[]) => {
        const row = args[3];
        SetFollowUpDate(row.auditRecommendationId, undefined);
      },
      permission: generatedLeadPermission.update,
    },
    // {
    //   text: "SetFollowUp",
    //   name: "Set Follow Up",
    //   icon: FollowUpIcon,
    //   handleClick: (...args: any[]) => {
    //     console.log(args[3]);
    //   },
    //   permission: generatedLeadPermission.create,
    // },
    // {
    //   text: "UpdateFollowUp",
    //   name: "Update Follow Up",
    //   icon: FollowUpSetIcon,
    //   handleClick: (...args: any[]) => {
    //     console.log(args[3]);
    //   },
    //   permission: generatedLeadPermission.update,
    // },
    // {
    //   text: "AcknowledgeFollowUp",
    //   name: "Acknowledge Follow Up",
    //   icon: acknowledgeIcon,
    //   handleClick: (...args: any[]) => {
    //     console.log(args[3]);
    //   },
    //   permission: generatedLeadPermission.update,
    // },
    // {
    //   icon: addIcon,
    //   handleClick: (auditId: number) => {
    //     setAuditId(auditId);
    //     setRecommendationDialogOpen(true);
    //   },
    //   text: 'AddRecommendation',
    //   name: 'Add Recommendation',
    // },
  ];

  const dispatch = useAppDispatch();
  const request = useRequest();
  let recommendationData = useSelector(
    (state: RootState) => state.auditRecommendationsReducer.recommendations
  );

  // useEffect(() => {
  //   if (recommendationData.length > 0 && auditId) {
  //     const recommendation: any = recommendationData[0];
  //     const data = {
  //       auditId,
  //       ...recommendation,
  //       aproxInvestment:
  //         recommendation.aproxInvestment !== ""
  //           ? recommendation?.aproxInvestment
  //           : null,
  //       paybackMonths:
  //         recommendation.paybackMonths !== ""
  //           ? recommendation?.paybackMonths
  //           : null,
  //       followUpDate:
  //         recommendation.followUpDate !== ""
  //           ? recommendation?.followUpDate
  //           : null,
  //       isGeneratedOpportunities: true,
  //     };
  //     request({
  //       url: RequestApi.POST_INSERT_RECOMMENDATION_BY_AUDITID,
  //       method: "post",
  //       data,
  //     })
  //       .then((resp: any) => {
  //         setAlert(
  //           "success",
  //           "Recommendation Details Saved Successfully",
  //           true
  //         );
  //       })
  //       .catch((err) => {
  //         if (err.code === "ERR_BAD_RESPONSE") {
  //           setAlert("error", "Request Failed", true);
  //         } else {
  //           console.log(err);
  //         }
  //       });

  //     dispatch(deleteAllRecommendations());
  //   }
  // }, [recommendationData, auditId]);

  const getRequestBody = () => {
    if (value == 0) {
      return {
        page: airAuditPage,
        pageSize: 20,
        sort: airAuditSortingColumn,
        order: airAuditSortingOrder,
        auditId: airAuditFilterAuditId ? airAuditFilterAuditId : "",
        status: airAuditFilterStatus
          ? statusDropDownData.filter(
              (data) => data.valueInt === airAuditFilterStatus
            )[0].value
          : "",
        LeadId: airAuditFilterLeadId ? airAuditFilterLeadId : 0,
        OpportunityId: airAuditFilterOppurtunityId
          ? airAuditFilterOppurtunityId
          : 0,
        investmentId: Number(airAuditFilterTypeOfInvestment)
          ? Number(airAuditFilterTypeOfInvestment)
          : 0,
        recommendationId: Number(airAuditFilterTypeOfRecommendation)
          ? Number(airAuditFilterTypeOfRecommendation)
          : 0,
        isTotalQuotedValue: getIsTotalQuotedValue(airAuditActualSavingValueInt),
        cO2Emission: airAuditFilterCO2Emission
          ? airAuditFilterCO2Emission
          : null,
        aproxInvestment: airAuditFilterApproximateInvestment
          ? airAuditFilterApproximateInvestment
          : null,
        minimumEstimatedSaving:
          airAuditFilterMinimumEstimatedSavings == 0
            ? 0
            : airAuditFilterMinimumEstimatedSavings
            ? airAuditFilterMinimumEstimatedSavings
            : null,
        customerName: airAuditFilterCustomerName
          ? airAuditFilterCustomerName
          : "",
        salesPhase: airAuditFilterSalesPhase
          ? salesPhaseDropDownData.filter(
              (data) => data.valueInt === airAuditFilterSalesPhase
            )[0].value
          : "",
        projectSource: airAuditFilterProjectSource
          ? airAuditFilterProjectSource
          : "",
        opportunityCreatedDate: airAuditFilterOpportunityCreateDate
          ? airAuditFilterOpportunityCreateDate === "clear"
            ? ""
            : airAuditFilterOpportunityCreateDate
          : "",
        opportunityResponsiblePerson: airAuditFilterOpportunityResponsible
          ? airAuditFilterOpportunityResponsible
          : "",
      };
    } else if (value == 1) {
      return {
        page: vibrationAuditPage,
        pageSize: 20,
        sort: vibrationAuditSortingColumn,
        order: vibrationAuditSortingOrder,
        auditId: vibrationAuditFilterAuditId ? vibrationAuditFilterAuditId : "",
        status: vibrationAuditFilterStatus
          ? statusDropDownData.filter(
              (data) => data.valueInt === vibrationAuditFilterStatus
            )[0].value
          : "",
        leadId: vibrationAuditFilterLeadId ? vibrationAuditFilterLeadId : 0,
        opportunityId: vibrationAuditFilterOppurtunityId
          ? vibrationAuditFilterOppurtunityId
          : 0,
        investmentId: Number(vibrationAuditFilterTypeOfInvestment)
          ? Number(vibrationAuditFilterTypeOfInvestment)
          : 0,
        recommendationId: Number(vibrationAuditFilterTypeOfRecommendation)
          ? Number(vibrationAuditFilterTypeOfRecommendation)
          : 0,
        isTotalQuotedValue: getIsTotalQuotedValue(
          vibrationAuditActualSavingValueInt
        ),
        aproxInvestment: vibrationAuditFilterApproximateInvestment
          ? vibrationAuditFilterApproximateInvestment
          : null,
        minimumEstimatedSaving:
          vibrationAuditFilterMinimumEstimatedSavings == 0
            ? 0
            : vibrationAuditFilterMinimumEstimatedSavings
            ? vibrationAuditFilterMinimumEstimatedSavings
            : null,
        customerName: vibrationAuditFilterCustomerName,
        salesPhase: vibrationAuditFilterSalesPhase
          ? salesPhaseDropDownData.filter(
              (data) => data.valueInt === vibrationAuditFilterSalesPhase
            )[0].value
          : "",
        opportunityCreatedDate: vibrationAuditFilterOpportunityCreateDate
          ? vibrationAuditFilterOpportunityCreateDate === "clear"
            ? ""
            : vibrationAuditFilterOpportunityCreateDate
          : "",
        opportunityResponsiblePerson: vibrationAuditFilterOpportunityResponsible
          ? vibrationAuditFilterOpportunityResponsible
          : "",
      };
    } else if (value == 2) {
      return {
        page: airNetAuditPage,
        pageSize: 20,
        sort: airNetAuditSortingColumn,
        order: airNetAuditSortingOrder,
        auditId: airNetAuditFilterAuditId ? airNetAuditFilterAuditId : "",
        status: airNetAuditFilterStatus
          ? statusDropDownData.filter(
              (data) => data.valueInt === airNetAuditFilterStatus
            )[0].value
          : "",
        LeadId: airNetAuditFilterLeadId ? airNetAuditFilterLeadId : 0,
        OpportunityId: airNetAuditFilterOppurtunityId
          ? airNetAuditFilterOppurtunityId
          : 0,
        customerName: airNetAuditFilterCustomerName
          ? airNetAuditFilterCustomerName
          : "",
        salesPhase: airNetAuditFilterSalesPhase
          ? salesPhaseDropDownData.filter(
              (data) => data.valueInt === airNetAuditFilterSalesPhase
            )[0].value
          : "",
        isTotalQuotedValue: getIsTotalQuotedValue(
          airNetAuditActualSavingValueInt
        ),
        opportunityCreatedDate: airNetAuditFilterOpportunityCreateDate
          ? airNetAuditFilterOpportunityCreateDate === "clear"
            ? ""
            : airNetAuditFilterOpportunityCreateDate
          : "",
        opportunityResponsiblePerson: airNetAuditFilterOpportunityResponsible
          ? airNetAuditFilterOpportunityResponsible
          : "",
      };
    } else {
      return {
        page: energyRecoveryAuditPage,
        pageSize: 20,
        sort: energyRecoveryAuditSortingColumn,
        order: energyRecoveryAuditSortingOrder,
        auditId: energyRecoveryAuditFilterAuditId
          ? energyRecoveryAuditFilterAuditId
          : "",
        status: energyRecoveryAuditFilterStatus
          ? statusDropDownData.filter(
              (data) => data.valueInt === energyRecoveryAuditFilterStatus
            )[0].value
          : "",
        LeadId: energyRecoveryAuditFilterLeadId
          ? energyRecoveryAuditFilterLeadId
          : 0,
        OpportunityId: energyRecoveryAuditFilterOppurtunityId
          ? energyRecoveryAuditFilterOppurtunityId
          : 0,
        customerName: energyRecoveryAuditFilterCustomerName
          ? energyRecoveryAuditFilterCustomerName
          : "",
        salesPhase: energyRecoveryAuditFilterSalesPhase
          ? salesPhaseDropDownData.filter(
              (data) => data.valueInt === energyRecoveryAuditFilterSalesPhase
            )[0].value
          : "",
        isTotalQuotedValue: getIsTotalQuotedValue(
          energyRecoveryAuditActualSavingValueInt
        ),
        opportunityCreatedDate: energyRecoveryAuditFilterOpportunityCreateDate
          ? energyRecoveryAuditFilterOpportunityCreateDate === "clear"
            ? ""
            : energyRecoveryAuditFilterOpportunityCreateDate
          : "",
        opportunityResponsiblePerson:
          energyRecoveryAuditFilterOpportunityResponsible
            ? energyRecoveryAuditFilterOpportunityResponsible
            : "",
      };
    }
  };

  const getExportToExcelDetails = () => {
    let data: ExportToExcel;
    if (value == 0) {
      data = {
        url: RequestApi.GET_AIR_AUDIT_GENERATED_OPPORTUNITIES,
        filename: `AIRscan_OpporunityList`,
      };
    } else if (value == 1) {
      data = {
        url: RequestApi.GET_VIBRATION_GENERATED_OPPORTUNITIES,
        filename: `Vibration_OpporunityList`,
      };
    } else if (value == 2) {
      data = {
        url: RequestApi.GET_AIR_NET_AUDIT_GENERATED_OPPORTUNITIES,
        filename: `AIRnet_OpporunityList`,
      };
    } else {
      data = {
        url: RequestApi.GET_ENERGY_RECOVERY_AUDIT_GENERATED_OPPORTUNITIES,
        filename: `EnergyRecovery_OpporunityList`,
      };
    }

    return data;
  };

  const loadAirAuditData = useCallback((): void => {
    if (value === 0) {
      request({
        url: RequestApi.GET_AIR_AUDIT_GENERATED_OPPORTUNITIES,
        method: "post",
        data: getRequestBody(),
      })
        .then((resp) => {
          setAirAuditData(resp.data.data);
          setPageSpecificData({
            opportunityStausUpdatedOn: resp.data.opportunityStausUpdatedOn,
          });
          setAirAuditTotalRecords(resp.data.totalRecord);
        })
        .catch((err) => {
          console.log(err);
          setAlert("error", "Request Failed", true);
        });
    }
  }, [
    value,
    airAuditPage,
    airAuditSortingColumn,
    airAuditSortingOrder,
    airAuditFilterAuditId,
    airAuditFilterStatus,
    airAuditFilterLeadId,
    airAuditFilterOppurtunityId,
    airAuditFilterTypeOfInvestment,
    airAuditFilterTypeOfRecommendation,
    airAuditActualSavingValueInt,
    airAuditFilterCO2Emission,
    airAuditFilterApproximateInvestment,
    airAuditFilterMinimumEstimatedSavings,
    airAuditFilterCustomerName,
    airAuditFilterSalesPhase,
    airAuditFilterProjectSource,
    airAuditFilterOpportunityResponsible,
    airAuditFilterOpportunityCreateDate,
    request,
  ]);

  const loadVibrationAuditData = useCallback((): void => {
    if (value === 1) {
      request({
        url: RequestApi.GET_VIBRATION_GENERATED_OPPORTUNITIES,
        method: "post",
        data: getRequestBody(),
      })
        .then((resp) => {
          setVibrationAuditData(resp.data.data);
          setPageSpecificData({
            opportunityStausUpdatedOn: resp.data.opportunityStausUpdatedOn,
          });
          setVibrationAuditTotalRecords(resp.data.totalRecord);
        })
        .catch((err) => {
          console.log(err);
          setAlert("error", "Request Failed", true);
        });
    }
  }, [
    value,
    vibrationAuditPage,
    vibrationAuditSortingColumn,
    vibrationAuditSortingOrder,
    vibrationAuditFilterAuditId,
    vibrationAuditFilterStatus,
    vibrationAuditFilterLeadId,
    vibrationAuditFilterOppurtunityId,
    vibrationAuditFilterTypeOfInvestment,
    vibrationAuditFilterTypeOfRecommendation,
    vibrationAuditActualSavingValueInt,
    vibrationAuditFilterApproximateInvestment,
    vibrationAuditFilterMinimumEstimatedSavings,
    vibrationAuditFilterCustomerName,
    vibrationAuditFilterSalesPhase,
    vibrationAuditFilterOpportunityResponsible,
    vibrationAuditFilterOpportunityCreateDate,
    request,
  ]);
  useEffect(() => {
    if (value === 0) {
      if (getAirData) {
        loadAirAuditData();
      }
    } else if (value === 1) {
      if (getVibrationData) {
        loadVibrationAuditData();
      }
    }

    return () => {
      setGetAirData(false);
      setGetVibrationData(false);
    };
  }, [
    value,
    loadAirAuditData,
    getAirData,
    getVibrationData,
    loadVibrationAuditData,
    openEditSavings,
  ]);

  const handleAuditGroupForOpportunity = (
    opportunityId: string | number,
    group: string
  ) => {
    const groupLower = group.toLowerCase();
    const opportunityIdNumber = Number(opportunityId);

    switch (groupLower) {
      case ShowAuditTypesOnTabConstants.Air_Scan.toLowerCase():
        setValue(0);
        setAirAuditFilterOppurtunityId(opportunityIdNumber);
        setGetAirData(true);
        break;
      case ShowAuditTypesOnTabConstants.Vibration_Audit.toLowerCase():
        setValue(1);
        setVibrationAuditFilterOppurtunityId(opportunityIdNumber);
        setGetVibrationData(true);
        break;
      case ShowAuditTypesOnTabConstants.Airnet.toLowerCase():
        setValue(2);
        setAirNetAuditFilterOppurtunityId(opportunityIdNumber);
        setGetAirNetData(true);
        break;
      case ShowAuditTypesOnTabConstants.Enery_Recovery.toLowerCase():
        setValue(3);
        setEnergyRecoveryAuditFilterOppurtunityId(opportunityIdNumber);
        setGetEnergyRecoveryData(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (filterState?.opportunityId && filterState?.auditGroup) {
      handleAuditGroupForOpportunity(
        filterState.opportunityId,
        filterState.auditGroup
      );
    }
  }, [filterState]);

  useEffect(() => {
    if (opportunityId && group) {
      handleAuditGroupForOpportunity(opportunityId, group);
    }
  }, [group, opportunityId]);

  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  const airnetActionButtons = actionButtons.slice(1);
  const energyRecoveryActionButtons = actionButtons.slice(1);

  const SetFollowUpDate = (
    auditRecommendationId: number,
    followUpDate?: string
  ) => {
    if (followUpDate === "") {
      setFollowUp((prev) => ({ ...prev, isOpen: false }));
      setAlert(
        "error",
        "You need to select a date to set follow up date",
        true
      );
      return;
    }
    request({
      url: RequestApi.UPDATE_OPPORTUNITY_FOLLOW_UP_DATE,
      method: "post",
      data: {
        auditRecommendationId,
        followUpDate,
      },
    })
      .then((resp) => {
        let message =
          followUpDate && followUpDate.length > 0
            ? "Follow up  is added successfully"
            : "Follow up  is acknowledged successfully";
        setAlert("success", message, true);
        setFollowUp({ ...FollowUp, isOpen: false });
        if (value == 0) {
          setGetAirData(true);
        } else if (value == 1) {
          setGetVibrationData(true);
        } else if (value === 2) {
          setGetAirNetData(true);
        } else {
          setGetEnergyRecoveryData(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setAlert("error", "Request Failed", true);
      });
  };

  const handleExportExcel = () => {
    var exportDetails = getExportToExcelDetails();
    const formattedDatetime = new Date()
      .toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      })
      .replace(",", "")
      .split("/");

    const [month, day, yearAndTime] = formattedDatetime;
    const [year, time] = yearAndTime.split(" ");

    const reformattedDatetime = `${day}-${month}-${year} ${time.replace(
      /:/g,
      "-"
    )}`;

    request({
      url: exportDetails.url,
      method: "post",
      responseType: "blob",
      data: { ...getRequestBody(), isExportToExcel: true },
    })
      .then((response) => {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${exportDetails.filename} ${reformattedDatetime}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setAlert("success", "Downloaded successfully", true);
        }
      })
      .catch((err) => {
        console.log(err, "errr");
        setAlert("error", "Request Failed", true);
      });
  };

  const getPageSpecificContent = () => {
    return (
      <>
        {pageSpecificData?.opportunityStausUpdatedOn && (
          <span
            style={{
              fontWeight: 400,
              fontSize: "0.875rem",
              lineHeight: 1.43,
            }}
          >
            Opportunity status updated on :{" "}
            {pageSpecificData.opportunityStausUpdatedOn}
          </span>
        )}
        <Tooltip
          title="Export To Excel"
          placement="left"
        >
          <Button
            sx={{
              lineHeight: "unset",
              padding: "unset",
              color: "#0099CC",
              marginLeft: "8px",
            }}
            onClick={handleExportExcel}
          >
            Export To Excel
          </Button>
        </Tooltip>
      </>
    );
  };

  return (
    <>
      <CustomAlertTest
        severity={alertSeverity}
        open={alertOpen}
        message={alertMessage}
        onClose={handleAlertClose}
        hideTime={5000}
      />

      <Tabs
        sx={tab_Style}
        value={value}
        onChange={handleTabChange}
        aria-label="basic tabs example"
      >
        <Tab
          sx={{ textTransform: "none" }}
          label={ShowAuditTypesOnTabConstants.Air_Scan}
          {...a11yProps(0)}
        />
        <Tab
          sx={{ textTransform: "none", display: "none" }}
          label={ShowAuditTypesOnTabConstants.Vibration_Audit}
          {...a11yProps(1)}
        />
        <Tab
          sx={{ textTransform: "none" }}
          label={ShowAuditTypesOnTabConstants.Airnet}
          {...a11yProps(2)}
        />
        <Tab
          sx={{ textTransform: "none" }}
          label={ShowAuditTypesOnTabConstants.Enery_Recovery}
          {...a11yProps(3)}
        />
      </Tabs>

      <Grid
        md={12}
        style={{ height: "13px", backgroundColor: "#F3F3F3" }}
      ></Grid>

      <CustomTabPanel
        value={value}
        index={0}
      >
        <CustomTable
          isInsideTab={true}
          data={airAuditData}
          filterChange={handleAirFilterChange}
          actionButtons={actionButtons}
          columns={salesAirColumns}
          stickyActionColumn={true}
          clearAll={clearAllAirAuditSorting}
          achievedSales={true}
          setPage={setAirAuditPage}
          page={airAuditPage}
          actionColumnWidth={"155px"}
          loadDataFunction={loadAirAuditData}
          totalRecords={airAuditTotalRecords}
          filterObject={airAuditfilterObject}
          sortColumn={airAuditSortingColumn}
          setSortColumn={setAirAuditSortingColumn}
          sortColumnOrder={airAuditSortingOrder}
          setSortColumnOrder={setAirAuditSortingOrder}
          setGetData={setGetAirData}
          pageSpecificData={getPageSpecificContent()}
          pageName={PageConstants.GeneratedOpportunities}
        />
      </CustomTabPanel>

      <CustomTabPanel
        value={value}
        index={1}
      >
        <CustomTable
          isInsideTab={true}
          data={vibrationAuditData}
          filterChange={handleVibrationFilterChange}
          setPage={setVibrationAuditPage}
          page={vibrationAuditPage}
          achievedSales={true}
          clearAll={clearAllVibrationSorting}
          actionButtons={actionButtons}
          columns={SalesVibrationColumns}
          stickyActionColumn={true}
          actionColumnWidth={"155px"}
          loadDataFunction={loadVibrationAuditData}
          totalRecords={vibrationAuditTotalRecords}
          filterObject={vibrationAuditfilterObject}
          sortColumn={vibrationAuditSortingColumn}
          setSortColumn={setVibrationAuditSortingColumn}
          sortColumnOrder={vibrationAuditSortingOrder}
          setSortColumnOrder={setVibrationAuditSortingOrder}
          setGetData={setGetVibrationData}
          pageSpecificData={getPageSpecificContent()}
          pageName={PageConstants.GeneratedOpportunities}
        />
      </CustomTabPanel>

      <CustomTabPanel
        value={value}
        index={2}
      >
        <AirNetOpportunities
          airNetAuditFilters={airNetAuditFilters}
          airNetSorting={airNetSorting}
          actionButtons={airnetActionButtons}
          pageSpecificData={getPageSpecificContent()}
        />
      </CustomTabPanel>
      <CustomTabPanel
        value={value}
        index={3}
      >
        <EnergyRecoveryOpportunities
          energyRecoveryAuditFilters={energyRecoveryAuditFilters}
          energyRecoverySorting={energyRecoverySorting}
          actionButtons={energyRecoveryActionButtons}
          pageSpecificData={getPageSpecificContent()}
        />
      </CustomTabPanel>

      <EditSavingsInformation
        open={openEditSavings}
        totalQuotedValue={totalQuotedValue}
        handleClose={handleEditSavingsClose}
        auditRecommendationId={auditRecommendationId}
        auditId={auditId}
        auditNumber={auditNumber}
      />
      <CustomDatePicker
        state={FollowUp}
        setState={setFollowUp}
        SetFollowUpDate={SetFollowUpDate}
      />
    </>
  );
};

export default GeneratedOpportunities;
