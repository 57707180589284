import { Dialog, Typography } from "@mui/material";
import classes from "./Notification.module.css";
import CloseIcon from "../../assets/icons/close.svg";
import FollowUpBlue from "../../assets/icons/FollowUpBlue.svg";
import FollowUpRed from "../../assets/icons/FollowUpRed.svg";
import ResponsiblePerson from "../../assets/icons/ResponsiblePerson.svg";
import NewProjectData from "../../assets/icons/NewProjectData.svg";
import OpportunityRed from "../../assets/icons/OpportunityRed.svg";
import OpportunityOrange from "../../assets/icons/OpportunityOrange.svg";
import OpportunityGreen from "../../assets/icons/OpportunityGreen.svg";
import LeadRed from "../../assets/icons/LeadRed.svg";
import LeadYellow from "../../assets/icons/LeadYellow.svg";
import LeadLightGreen from "../../assets/icons/LeadLightGreen.svg";
import LeadOrange from "../../assets/icons/LeadOrange.svg";
import LeadDarkGreen from "../../assets/icons/LeadDarkGreen.svg";
import { UIEvent, useEffect, useRef, useState } from "react";
import NotificationCard from "./NotificationCard";
import Exclamation from "../../assets/icons/Red_Exclaimation_mark.svg";
import { CustomConfirmationDialogProps } from "../LinkProjectUpload";
import { useAppDispatch } from "../../common/hooks/hooks";
import { showConfirmation } from "../../common/store/confirmationBoxDataSlice";
import { hideLoader, showLoader } from "../../common/store/loaderDataSlice";
import { DeleteSuccessDailog } from "../UpdateOngoingProject";
import { useRequest } from "../../services/agent";
import { RequestApi } from "../../common/Constant";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { RoutingConstants } from "../../common/RoutingConstants";

interface NotificationProps {
  setAlert: (severity: any, message: string, OCflag: boolean) => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  notificationCount: INotificationCount;
  getNotificationCount: () => void;
}

interface INotificationCount {
  totalUnreadCount: number;
  actionUnreadCount: number;
  followUpUnreadCount: number;
  updateUnreadCount: number;
}
enum NotificationGroup {
  Actions = "actions",
  FollowUp = "followUp",
  Updates = "updates",
}

export enum NotificationTypes {
  StatusUpdateOpportunity = "Status Update - Opportunity",
  StatusUpdateLead = "Status Update - Lead",
  NewProjectData = "New Project Data",
  StatusNotChanged = "Status Not Changed",
  FollowUpDateAchieved = "Follow Up - Date Achieved",
  LeadFollowUp = "Follow Up - Lead",
  OpportunityFollowUp = "Follow Up - Opportunity",
  ResponsiblePersonAdded = "Responsible Person Added",
  LeadStatusNotChanged = "Status Not Changed - Lead",
  OpportunityStatusNotChanged = "Status Not Changed - Opportunity",
}

export enum NotificationGroups {
  Action = "Action",
  FollowUp = "Follow Up",
  Update = "Update",
}

interface INotification {
  id: number;
  type: string;
  group: string;
  content: string;
  isRead: boolean;
  daysAgo: number;
}

interface ITabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export default function NotificationDialog(props: NotificationProps) {
  const { open, setOpen, notificationCount, getNotificationCount, setAlert } =
    props;
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const dispatchCancelPopup = useAppDispatch();
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();
  const pageSize = 10;
  const request = useRequest();

  const notificationTabs = [
    {
      id: 0,
      label: `All Notifications${
        notificationCount.totalUnreadCount > 0
          ? `(${notificationCount.totalUnreadCount})`
          : ""
      }`,
      group: null,
    },
    {
      id: 1,
      label: `Actions${
        notificationCount.actionUnreadCount > 0
          ? `(${notificationCount.actionUnreadCount})`
          : ""
      }`,
      group: NotificationGroup.Actions,
    },
    {
      id: 2,
      label: `Follow Ups${
        notificationCount.followUpUnreadCount > 0
          ? `(${notificationCount.followUpUnreadCount})`
          : ""
      }`,
      group: NotificationGroup.FollowUp,
    },
    {
      id: 3,
      label: `Updates${
        notificationCount.updateUnreadCount > 0
          ? `(${notificationCount.updateUnreadCount})`
          : ""
      }`,
      group: NotificationGroup.Updates,
    },
  ];

  const handleTabChange = (id: number) => {
    setSelectedTab(id);
    if (selectedTab === id) return;
    setHasMore(true);
    setPage(1);
    setNotifications([]);
  };
  const handleClose = () => {
    setOpen(false);
    setHasMore(true);
    setPage(1);
    setNotifications([]);
    setSelectedTab(0);
  };

  function CustomTabPanel(props: ITabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        style={{ overflow: "auto" }}
        className="notification"
      >
        {value === index && (
          <div style={{ padding: 0, margin: "12px 0px" }}>
            <Typography component={"span"}>{children}</Typography>
          </div>
        )}
      </div>
    );
  }
  function getMonthAndYearDaysAgo(daysAgo: number): {
    month: string;
    year: number;
  } {
    const date = new Date();
    date.setDate(date.getDate() - daysAgo);

    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    return { month, year };
  }

  const groups = [
    "",
    NotificationGroups.Action,
    NotificationGroups.FollowUp,
    NotificationGroups.Update,
  ];

  const fetchNotifications = async () => {
    await request({
      url: RequestApi.POST_GET_ALL_NOTIFICATION,
      method: "post",
      data: { group: groups[selectedTab] },
    })
      .then((resp) => {
        if (resp.status === 204) resp.data = [];
        const newNotifications = resp.data;
        setNotifications((prev) => [...prev, ...newNotifications]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const markNotificationAsRead = async (id: number) => {
    await request({
      url: RequestApi.POST_MARK_NOTIFICATION_AS_READ,
      method: "post",
      data: { id },
    })
      .then((resp) => {
        console.log(resp);
        getNotificationCount();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const clearAllNotification = async () => {
    await request({
      url: RequestApi.DELETE_CLEAR_ALL_NOTIFICATION,
      method: "delete",
    })
      .then((resp) => {
        getNotificationCount();
        setNotifications([]);
        console.log(resp);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleExportExcel = (isLead: boolean) => {
    var url = isLead
      ? RequestApi.DOWNLOAD_LEADS_WITH_NO_STATUS_CHANGE
      : RequestApi.DOWNLOAD_OPPORTUNITIES_WITH_NO_STATUS_CHANGE;
    var fileName = isLead
      ? "LeadsReportForNoStatusChange"
      : "OpportunitiesReportForNoStatusChange";
    const formattedDate = new Date()
      .toLocaleString("en-US", {
        year: "numeric",
        month: "long",
      })
      .replace(" ", "-");
    request({
      url: url,
      method: "get",
      responseType: "blob",
    })
      .then((response) => {
        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${fileName} ${formattedDate}.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setAlert("success", "Report downloaded successfully", true);
        }
      })
      .catch((err) => {
        console.log(err, "errr");
        setAlert("error", "Request Failed", true);
      });
  };

  function onCloseCancelSave() {
    dispatchCancelPopup(hideLoader());
  }

  function onConfirmCancelSave() {
    onCloseCancelSave();
  }
  const handleCancelClickOpen = (event: any) => {
    let customConfirmationDialogProps: CustomConfirmationDialogProps = {
      title: "Are You Sure!",
      content: "If you proceed, All Notifications will be deleted.",
      icon: Exclamation,
      onClose: onCloseCancelSave,
      onConfirm: async () => {
        await clearAllNotification();
        onConfirmCancelSave();
      },
    };
    dispatchCancelPopup(showConfirmation(customConfirmationDialogProps));
    dispatchCancelPopup(showLoader());
  };

  useEffect(() => {
    if (open) fetchNotifications();
  }, [selectedTab, open]);

  useEffect(() => {
    if (open) getNotificationCount();
  }, [open]);

  const getLeadIcon = (status: string) => {
    switch (status?.toLocaleLowerCase()) {
      case "converted":
        return LeadDarkGreen;
      case "accepted":
        return LeadLightGreen;
      case "qualified":
        return LeadYellow;
      case "declined":
        return LeadRed;
      case "deleted":
        return LeadOrange;
      default:
        return LeadYellow;
    }
  };

  const getOpportunityIcon = (status: string) => {
    switch (status?.toLocaleLowerCase()) {
      case "lost":
        return OpportunityRed;
      case "won":
      case "open":
        return OpportunityGreen;
      case "stopped":
        return OpportunityOrange;
      default:
        return OpportunityOrange;
    }
  };

  const getNotificationCard = (item: INotification) => {
    let icon = "";
    let message = "";
    const createdDate = getMonthAndYearDaysAgo(item.daysAgo);
    const content = item?.content ? JSON.parse(item?.content) : "";
    let navigation: () => void;

    switch (item?.type) {
      case NotificationTypes.StatusUpdateLead:
        message = `Lead ID ${content?.LeadId} - ${content?.LeadStatus}.`;
        icon = getLeadIcon(content?.LeadStatus);
        navigation = () => {
          navigate(`/${RoutingConstants.GeneratedLeads}`, {
            state: { leadId: content?.LeadId, auditGroup: content?.AuditGroup },
          });
        };
        break;

      case NotificationTypes.StatusUpdateOpportunity:
        message = `Opportunity ID ${content?.OpportunityId} - ${content?.OpportunityStatus}.`;
        icon = getOpportunityIcon(content?.OpportunityStatus);
        navigation = () => {
          navigate(`/${RoutingConstants.GeneratedOpportunities}`, {
            state: {
              opportunityId: content?.OpportunityId,
              auditGroup: content?.AuditGroup,
            },
          });
        };

        break;

      case NotificationTypes.NewProjectData:
        icon = NewProjectData;
        message = `AIRScan Project Data Added by ${content?.CreatedBy}.`;
        navigation = () => {
          navigate(`/${RoutingConstants.OngoingProject}`, {
            state: content?.OngoingProjectId,
          });
        };

        break;

      case NotificationTypes.ResponsiblePersonAdded:
        icon = ResponsiblePerson;
        message = `You are assigned as a Responsible Person for Ongoing Project ID ${content?.OngoingProjectId}. `;
        navigation = () => {
          navigate(`/${RoutingConstants.OngoingProject}`, {
            state: content?.OngoingProjectId,
          });
        };

        break;

      case NotificationTypes.LeadFollowUp:
        message = `Follow Up for lead ${content?.LeadId} is achieved.`;
        icon = FollowUpRed;
        navigation = () => {
          navigate(`/${RoutingConstants.GeneratedLeads}`, {
            state: { leadId: content?.LeadId, auditGroup: content?.AuditGroup },
          });
        };
        break;

      case NotificationTypes.OpportunityFollowUp:
        message = `Follow Up for opportunity ${content?.OpportunityId} is achieved.`;
        icon = FollowUpRed;
        navigation = () => {
          navigate(`/${RoutingConstants.GeneratedOpportunities}`, {
            state: {
              opportunityId: content?.OpportunityId,
              auditGroup: content?.AuditGroup,
            },
          });
        };
        break;

      case NotificationTypes.LeadStatusNotChanged:
        message = `Leads didn’t move report ${createdDate.month} ${createdDate.year}.`;
        icon = LeadRed;
        navigation = () => {
          handleExportExcel(true);
        };
        break;

      case NotificationTypes.OpportunityStatusNotChanged:
        icon = OpportunityRed;
        message = `Opportunities didn’t move report ${createdDate.month} ${createdDate.year}.`;
        navigation = () => {
          handleExportExcel(false);
        };

        break;
    }

    return (
      <NotificationCard
        title={item?.type}
        message={message}
        onClick={() => {
          if (!item?.isRead) markNotificationAsRead(item?.id);
          navigation();
          setOpen(false);
        }}
        isRead={item?.isRead}
        daysAgo={item?.daysAgo}
        icon={icon}
      />
    );
  };

  return (
    <Dialog
      sx={{
        "& .MuiDialog-container.MuiDialog-scrollPaper": {
          position: "absolute !important",
          right: "0 !important",
          top: "0 !important",
        },
        "& .MuiDialog-paper": {
          height: "100vh",
          borderRadius: "6px 0 0 6px",
          marginTop: "0",
          marginInline: "0",
          maxHeight: "calc(100% - 32px)",
          width: "450px",
          padding: "12px",
        },
      }}
      onClose={handleClose}
      open={open}
    >
      <div className={classes["dialog-title-container"]}>
        <div className={classes["add-recommendation-title"]}>Notifications</div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <p
            className={classes["clearAll"]}
            onClick={handleCancelClickOpen}
          >
            Clear All
          </p>

          <img
            className={classes["close-icon"]}
            onClick={handleClose}
            src={CloseIcon}
            alt="Back"
            loading="lazy"
            decoding="async"
          ></img>
        </div>
      </div>

      <div className={classes["tabs-container"]}>
        {notificationTabs.map((tab) => (
          <div
            key={tab.id}
            className={`${classes["tab-item"]} ${
              selectedTab === tab.id ? classes["active"] : ""
            }`}
            onClick={() => {
              setNotifications([]);
              handleTabChange(tab.id);
            }}
          >
            {tab.label}
          </div>
        ))}
      </div>
      {groups.map((item, index) => (
        <CustomTabPanel
          value={selectedTab}
          index={index}
        >
          {notifications.length > 0 ? (
            notifications?.map((item, index) => getNotificationCard(item))
          ) : (
            <div className={classes["empty-notification"]} />
          )}
        </CustomTabPanel>
      ))}
      <DeleteSuccessDailog
        open={openSuccessDialog}
        onClose={() => {
          setOpenSuccessDialog(false);
        }}
        message={"If you proceed, All Notifications will be deleted."}
      />
    </Dialog>
  );
}
