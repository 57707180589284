import { Box, Dialog, Grid, Tab, Tabs, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CustomTable from "../../common/components/CustomComponents/CustomTable";
import AirNet, { IAirNetAuditData } from "./AirNet";
import {
  DatePickerMode,
  TableActionButtons,
  TableColumn,
} from "../../common/interface/TableColumn";
import classes from "./FinishedReports.module.css";
import generateLeadIcon from "../../assets/icons/generated_leads_green.svg";
import downloadIcon from "../../assets/icons/download.svg";
import fileEditIcon from "../../assets/icons/file_edit.svg";
import DropdownModel from "../../common/interface/DropdownModel";
import { RequestApi } from "../../common/Constant";
import { useRequest } from "../../services/agent";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../common/hooks/hooks";
import SavingsSaved from "../../assets/images/savings_saved.svg";
import {
  setUploadFinishedReportsData,
  UploadFinishedReportDataModel,
} from "../../common/store/uploadFinishedReportsDataSlice";
import CustomAlertTest from "../../common/components/CustomComponents/CustomAlert";
import deleteIcon from "../../assets/icons/delete-icon.svg";
import Exclamation from "../../assets/icons/Red_Exclaimation_mark.svg";
import {
  AuditTypes,
  ShowAuditTypesOnTabConstants,
} from "../../common/AuditTypeConstants";
import { CustomConfirmationDialogProps } from "../../common/components/CustomComponents/ConfirmationDialog";
import { showConfirmation } from "../../common/store/confirmationBoxDataSlice";
import { hideLoader, showLoader } from "../../common/store/loaderDataSlice";
import ConfirmDelete from "../../common/components/CustomComponents/ConfirmDelete";
import { OperationPermissions } from "../../common/models/operationPermission";
import { PageConstants } from "../../common/PageConstants";
import PermissionService from "../../services/PermissionService";
import { RoutingConstants } from "../../common/RoutingConstants";
import { tab_Style } from "../../common/TabStyleConstants";
import { ProjectSourceConstants } from "../../common/ProjectSourceConstants";
import EnergyRecovery from "./EnergyRecovery";

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: 0, borderRadius: "8px", background: "#fff" }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export type tableColumns =
  | "id"
  | "auditId"
  | "projectSource"
  | "uploadedDate"
  | "c4CId"
  | "customer"
  | "auditName"
  | "typeOfAudit"
  | "country"
  | "city"
  | "responsible";

// interface Column {
//   id: tableColumns; //keyof typeof tableColumns;
//   label: string;
//   minWidth?: number;
//   align?: "right" | "left" | "center";
//   format?: (value: number) => string;
//   filter: true | false;
// }

const resultData: DropdownModel[] = [
  {
    text: "Low",
    value: "low",
    valueInt: 1,
  },
  {
    text: "Medium",
    value: "medium",
    valueInt: 2,
  },
  {
    text: "Critical",
    value: "critical",
    valueInt: 3,
  },
];

const columns: TableColumn[] = [
  {
    id: "auditId" as tableColumns,
    label: "Project ID",
    align: "left",
    minWidth: 150,
    filter: true,
    filterType: "text",
    sorting: true,
  },
  {
    id: "uploadedDate",
    label: "Upload Date",
    minWidth: 155,
    align: "left",
    format: (value: number) => value.toLocaleString("en-US"),
    filter: true,
    filterType: "datepicker",
    sorting: true,
    datePickerMode: DatePickerMode.MonthYear,
  },
  {
    id: "c4CId",
    label: "Account ID",
    minWidth: 100,
    align: "left",
    format: (value: number) => value.toLocaleString("en-US"),
    filter: true,
  },
  {
    id: "customerName",
    label: "Customer Name",
    minWidth: 200,
    align: "left",
    format: (value: number) => value.toFixed(2),
    filter: true,
    sorting: true,
  },
  {
    id: "auditName",
    label: "Project Name",
    minWidth: 170,
    align: "left",
    format: (value: number) => value.toFixed(2),
    filter: true,
    sorting: true,
  },
  {
    id: "auditType",
    label: "Type Of Project",
    minWidth: 170,
    align: "left",
    format: (value: number) => value.toFixed(2),
    filter: true,
  },
  {
    id: "country",
    label: "Country",
    minWidth: 100,
    align: "left",
    format: (value: number) => value.toFixed(2),
    filter: true,
    sorting: true,
  },
  {
    id: "city",
    label: "City",
    minWidth: 170,
    align: "left",
    format: (value: number) => value.toFixed(2),
    filter: true,
    sorting: true,
  },

  {
    id: "responsible",
    label: "Responsible",
    minWidth: 170,
    align: "left",
    format: (value: number) => value.toFixed(2),
    filter: true,
    sorting: true,
  },
];

export interface IAirAuditData {
  id: number;
  auditId: number;
  status: string;
  uploadedDate: string;
  c4CId: number;
  customerName: string;
  auditName: string;
  typeOfAudit: string;
  country: string;
  city: string;
  responsible: string;
  auditTypeId: number;
}

const vibrationColumns: TableColumn[] = [
  {
    id: "auditId" as tableColumns,
    label: "Project ID",
    align: "left",
    minWidth: 150,
    filter: true,
    sorting: true,
  },
  // {
  //   id: "projectSource" as tableColumns,
  //   label: "Source",
  //   align: "left",
  //   minWidth: 150,
  //   filter: true,
  //   sorting: true,
  // },
  {
    id: "rangeResult",
    label: "Result",
    align: "left",
    minWidth: 150,
    filter: true,
    filterType: "dropdown",
    dropdownData: resultData,
  },
  // {
  //   id: 'status',
  //   label: 'Status',
  //   align: 'left',
  //   minWidth: 150,
  //   filter: false,
  // },

  {
    id: "c4CId",
    label: "Account ID",
    minWidth: 100,
    align: "left",
    format: (value: number) => value.toLocaleString("en-US"),
    filter: true,
  },
  {
    id: "customerName",
    label: "Customer Name",
    minWidth: 200,
    align: "left",
    format: (value: number) => value.toFixed(2),
    filter: true,
    sorting: true,
  },

  {
    id: "uploadedDate",
    label: "Upload Date",
    minWidth: 155,
    align: "left",
    //format: (value: number) => value.toLocaleString("en-US"),
    filter: true,
    filterType: "datepicker",
    sorting: true,
    datePickerMode: DatePickerMode.MonthYear,
  },
  {
    id: "auditName",
    label: "Project Name",
    minWidth: 170,
    align: "left",
    format: (value: number) => value.toFixed(2),
    filter: true,
    sorting: true,
  },
  {
    id: "serialNumber",
    label: "Serial Number",
    minWidth: 180,
    align: "left",
    //format: (value: number) => value.toFixed(2),
    filter: true,
    sorting: true,
  },
  {
    id: "machineModel",
    label: "Machine Model",
    minWidth: 180,
    align: "left",
    //format: (value: number) => value.toFixed(2),
    filter: true,
    sorting: true,
  },
  // {
  //   id: "city",
  //   label: "City",
  //   minWidth: 170,
  //   align: "left",
  //   format: (value: number) => value.toFixed(2),
  //   filter: true,
  // },

  {
    id: "responsible",
    label: "Responsible",
    minWidth: 170,
    align: "left",
    format: (value: number) => value.toFixed(2),
    filter: true,
    sorting: true,
  },
];

interface IVibrationAuditData {
  id: number;
  auditId: number;
  auditNumber: number;
  criticalResult: number;
  mediumResult: number;
  lowResult: number;
  status: string;
  c4CId: number;
  customerName: string;
  uploadedDate: string;
  auditName: string;
  serialNumber: number;
  machineModel: string;
  responsible: string;
}

//Vibration audit end

const FinishedReports = () => {
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedTabName, setSelectedTabName] = useState("");
  const request = useRequest();
  const navigation = useNavigate();
  const dispatch = useAppDispatch();

  // Air Audit useState Start
  const [airAuditPage, setAirAuditPage] = useState(0);
  const [airAuditFilterC4cId, setAirAuditFilterC4cId] = useState<string>("");
  const [airAuditFilterAuditId, setAirAuditFilterAuditId] =
    useState<string>("");
  // const [airAuditFilterStatus, setAirAuditFilterStatus] = useState<string>('');
  const [airAuditFilterUploadedDate, setAirAuditFilterUploadedDate] =
    useState<string>("");
  const [airAuditFilterAuditType, setAirAuditFilterAuditType] =
    useState<string>("");
  const [airAuditFilterResponsible, setAirAuditFilterResponsible] =
    useState<string>("");
  const [airAuditFilterAuditName, setAirAuditFilterAuditName] =
    useState<string>("");
  const [airAuditData, setAirAuditData] = useState<Array<IAirAuditData>>([]);
  const [airAuditTotalRecords, setAirAuditTotalRecords] = useState<number>(0);
  const [airAuditSortingColumn, setAirAuditSortingColumn] =
    useState<string>("");
  const [airAuditSortingOrder, setAirAuditSortingOrder] = useState<string>("");
  const [airFilterLocalDate, setAirFilterLocalDate] = useState<string>("");

  const [airAuditFilterCustomer, setAirAuditFilterCustomer] =
    useState<string>("");
  const [airAuditFilterCity, setAirAuditFilterCity] = useState<string>("");
  const [airAuditFilterCountry, setAirAuditFilterCountry] =
    useState<string>("");
  const [airAuditFilterProjectSource, setAirAuditFilterProjectSource] =
    useState<string>("");
  const [getAirData, setGetAirData] = useState(false);

  //AIRNET STATE start
  const [airNetAuditFilterC4cId, setAirNetAuditFilterC4cId] =
    useState<string>("");
  const [airNetAuditFilterUploadedDate, setAirNetAuditFilterUploadedDate] =
    useState<string>("");
  const [airNetFilterLocalDate, setAirNetFilterLocalDate] =
    useState<string>("");
  const [airNetAuditFilterAuditId, setAirNetAuditFilterAuditId] =
    useState<string>("");
  const [airNetAuditFilterAuditType, setAirNetAuditFilterAuditType] =
    useState<string>("");
  const [airNetAuditFilterProjectSource, setAirNetAuditFilterProjectSource] =
    useState<string>("");
  const [
    airNetAuditFilterAuditClassification,
    setAirNetAuditFilterAuditClassification,
  ] = useState<string>("");
  const [airNetAuditFilterResponsible, setAirNetAuditFilterResponsible] =
    useState<string>("");
  const [airNetAuditFilterAuditName, setAirNetAuditFilterAuditName] =
    useState<string>("");
  const [airNetAuditFilterCustomer, setAirNetAuditFilterCustomer] =
    useState<string>("");
  const [airNetAuditFilterCity, setAirNetAuditFilterCity] =
    useState<string>("");
  const [airNetAuditFilterCountry, setAirNetAuditFilterCountry] =
    useState<string>("");

  const [airNetAuditSortingColumn, setAirNetAuditSortingColumn] =
    useState<string>("");
  const [airNetAuditSortingOrder, setAirNetAuditSortingOrder] =
    useState<string>("");
  const [airNetAuditPage, setAirNetAuditPage] = useState(0);

  const airNetAuditFilters = {
    airNetAuditFilterC4cId,
    setAirNetAuditFilterC4cId,
    airNetAuditFilterUploadedDate,
    setAirNetAuditFilterUploadedDate,
    airNetFilterLocalDate,
    setAirNetFilterLocalDate,
    airNetAuditFilterAuditId,
    setAirNetAuditFilterAuditId,
    airNetAuditFilterAuditType,
    setAirNetAuditFilterAuditType,
    airNetAuditFilterProjectSource,
    setAirNetAuditFilterProjectSource,
    airNetAuditFilterAuditClassification,
    setAirNetAuditFilterAuditClassification,
    airNetAuditFilterResponsible,
    setAirNetAuditFilterResponsible,
    airNetAuditFilterAuditName,
    setAirNetAuditFilterAuditName,
    airNetAuditFilterCustomer,
    setAirNetAuditFilterCustomer,
    airNetAuditFilterCity,
    setAirNetAuditFilterCity,
    airNetAuditFilterCountry,
    setAirNetAuditFilterCountry,
    airNetAuditSortingColumn,
    setAirNetAuditSortingColumn,
    airNetAuditSortingOrder,
    setAirNetAuditSortingOrder,
    airNetAuditPage,
    setAirNetAuditPage,
  };
  //AIRNET STATE END

  //ENERGY RECOVERY START
  const [energyRecoveryAuditFilterC4cId, setEnergyRecoveryAuditFilterC4cId] =
    useState<string>("");
  const [
    energyRecoveryAuditFilterUploadedDate,
    setEnergyRecoveryAuditFilterUploadedDate,
  ] = useState<string>("");
  const [energyRecoveryFilterLocalDate, setEnergyRecoveryFilterLocalDate] =
    useState<string>("");
  const [
    energyRecoveryAuditFilterAuditId,
    setEnergyRecoveryAuditFilterAuditId,
  ] = useState<string>("");
  const [
    energyRecoveryAuditFilterAuditType,
    setEnergyRecoveryAuditFilterAuditType,
  ] = useState<string>("");
  const [
    energyRecoveryAuditFilterProjectSource,
    setEnergyRecoveryAuditFilterProjectSource,
  ] = useState<string>("");
  const [
    energyRecoveryAuditFilterEnergySource,
    setEnergyRecoveryAuditFilterEnergySource,
  ] = useState<string>("");
  const [
    energyRecoveryAuditFilterResponsible,
    setEnergyRecoveryAuditFilterResponsible,
  ] = useState<string>("");
  const [
    energyRecoveryAuditFilterAuditName,
    setEnergyRecoveryAuditFilterAuditName,
  ] = useState<string>("");
  const [
    energyRecoveryAuditFilterCustomer,
    setEnergyRecoveryAuditFilterCustomer,
  ] = useState<string>("");
  const [energyRecoveryAuditFilterCity, setEnergyRecoveryAuditFilterCity] =
    useState<string>("");
  const [
    energyRecoveryAuditFilterCountry,
    setEnergyRecoveryAuditFilterCountry,
  ] = useState<string>("");

  const [
    energyRecoveryAuditSortingColumn,
    setEnergyRecoveryAuditSortingColumn,
  ] = useState<string>("");
  const [energyRecoveryAuditSortingOrder, setEnergyRecoveryAuditSortingOrder] =
    useState<string>("");
  const [energyRecoveryAuditPage, setEnergyRecoveryAuditPage] = useState(0);

  const energyRecoveryAuditFilters = {
    energyRecoveryAuditFilterC4cId,
    setEnergyRecoveryAuditFilterC4cId,
    energyRecoveryAuditFilterUploadedDate,
    setEnergyRecoveryAuditFilterUploadedDate,
    energyRecoveryFilterLocalDate,
    setEnergyRecoveryFilterLocalDate,
    energyRecoveryAuditFilterAuditId,
    setEnergyRecoveryAuditFilterAuditId,
    energyRecoveryAuditFilterAuditType,
    setEnergyRecoveryAuditFilterAuditType,
    energyRecoveryAuditFilterProjectSource,
    setEnergyRecoveryAuditFilterProjectSource,
    energyRecoveryAuditFilterEnergySource,
    setEnergyRecoveryAuditFilterEnergySource,
    energyRecoveryAuditFilterResponsible,
    setEnergyRecoveryAuditFilterResponsible,
    energyRecoveryAuditFilterAuditName,
    setEnergyRecoveryAuditFilterAuditName,
    energyRecoveryAuditFilterCustomer,
    setEnergyRecoveryAuditFilterCustomer,
    energyRecoveryAuditFilterCity,
    setEnergyRecoveryAuditFilterCity,
    energyRecoveryAuditFilterCountry,
    setEnergyRecoveryAuditFilterCountry,
    energyRecoveryAuditSortingColumn,
    setEnergyRecoveryAuditSortingColumn,
    energyRecoveryAuditSortingOrder,
    setEnergyRecoveryAuditSortingOrder,
    energyRecoveryAuditPage,
    setEnergyRecoveryAuditPage,
  };

  //ENERGY RECOVERY END

  const airAuditfilterObject: any = useMemo(
    () => ({
      c4CId: airAuditFilterC4cId,
      auditId: airAuditFilterAuditId,
      // status: airAuditFilterStatus,
      uploadedDate: dayjs(airFilterLocalDate).isValid()
        ? dayjs(airFilterLocalDate)
        : "",
      auditType: airAuditFilterAuditType,
      responsible: airAuditFilterResponsible,
      auditName: airAuditFilterAuditName,
      customerName: airAuditFilterCustomer,
      city: airAuditFilterCity,
      country: airAuditFilterCountry,
      projectSource: airAuditFilterProjectSource,
    }),
    [
      airAuditFilterAuditId,
      airAuditFilterAuditName,
      airAuditFilterAuditType,
      airAuditFilterC4cId,
      airAuditFilterResponsible,
      // airAuditFilterStatus,
      airFilterLocalDate,
      airAuditFilterCustomer,
      airAuditFilterCity,
      airAuditFilterCountry,
      airAuditFilterProjectSource,
    ]
  );

  // Air Audit useState End

  // Vibration Audit useState Start
  const [vibrationAuditPage, setVibrationAuditPage] = useState(0);
  const [vibrationAuditFilterC4cId, setVibrationAuditFilterC4cId] =
    useState<string>("");
  const [vibrationAuditFilterAuditId, setVibrationAuditFilterAuditId] =
    useState<string>("");
  // const [vibrationAuditFilterStatus, setVibrationAuditFilterStatus] =
  //   useState<string>('');
  const [
    vibrationAuditFilterUploadedDate,
    setVibrationAuditFilterUploadedDate,
  ] = useState<string>("");
  const [vibrationAuditFilterAuditType, setVibrationAuditFilterAuditType] =
    useState<string>("");
  const [vibrationAuditFilterResponsible, setVibrationAuditFilterResponsible] =
    useState<string>("");
  const [vibrationAuditFilterAuditName, setVibrationAudiFiltertAuditName] =
    useState<string>("");
  const [
    vibrationAuditFilterSerialNumber,
    setVibrationAuditFilterSerialNumber,
  ] = useState<string>("");
  const [
    vibrationAuditFilterMachineModel,
    setVibrationAuditFilterMachineModel,
  ] = useState<string>("");
  const [vibrationAuditFilterRangeResult, setVibrationAuditFilterRangeResult] =
    useState<string>("");
  const [vibrationAuditData, setVibrationAuditData] = useState<
    Array<IVibrationAuditData>
  >([]);
  const [vibrationAuditTotalRecords, setVibrationAuditTotalRecords] =
    useState<number>(0);
  const [vibrationAuditSortingColumn, setVibrationAuditSortingColumn] =
    useState<string>("");
  const [vibrationAuditSortingOrder, setVibrationAuditSortingOrder] =
    useState<string>("");
  const [vibrationAuditLocalDate, setVibrationAuditLocalDate] =
    useState<string>("");

  const [vibrationAuditFilterCustomer, setVibrationAuditFilterCustomer] =
    useState<string>("");
  const [
    vibrationAuditFilterProjectSource,
    setVibrationAuditFilterProjectSource,
  ] = useState<string>("");
  const [getVibrationData, setGetVibrationData] = useState(false);

  const vibrationAuditfilterObject: any = useMemo(() => {
    let range;
    if (vibrationAuditFilterRangeResult === "low") {
      range = 1;
    } else if (vibrationAuditFilterRangeResult === "medium") {
      range = 2;
    } else if (vibrationAuditFilterRangeResult === "critical") {
      range = 3;
    } else {
      range = "";
    }

    return {
      c4CId: vibrationAuditFilterC4cId,
      auditId: vibrationAuditFilterAuditId,
      // status: vibrationAuditFilterStatus,
      uploadedDate: dayjs(vibrationAuditLocalDate).isValid()
        ? dayjs(vibrationAuditLocalDate)
        : "",
      auditType: vibrationAuditFilterAuditType,
      responsible: vibrationAuditFilterResponsible,
      auditName: vibrationAuditFilterAuditName,
      serialNumber: vibrationAuditFilterSerialNumber,
      machineModel: vibrationAuditFilterMachineModel,
      rangeResult: range,
      customerName: vibrationAuditFilterCustomer,
      projectSource: vibrationAuditFilterProjectSource,
    };
  }, [
    vibrationAuditFilterAuditId,
    vibrationAuditFilterAuditName,
    vibrationAuditFilterAuditType,
    vibrationAuditFilterC4cId,
    vibrationAuditFilterMachineModel,
    vibrationAuditFilterRangeResult,
    vibrationAuditFilterResponsible,
    vibrationAuditFilterSerialNumber,
    // vibrationAuditFilterStatus,
    vibrationAuditLocalDate,
    vibrationAuditFilterCustomer,
    vibrationAuditFilterProjectSource,
  ]);

  // Vibration Audit useState End
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState<"success" | "error">(
    "success"
  );
  const [alertOpen, setAlertOpen] = useState(false);
  const dispatchRecommendations = useAppDispatch();
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const finishedReportPermission: OperationPermissions = PermissionService(
    PageConstants.FinishedReports
  );

  function setAlert(severity: any, message: string, OCflag: boolean) {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(OCflag);
    // handleCloseDialog();
  }

  function deleteReportConfirm(auditId: any, typeOfAudit: any) {
    let customConfirmationDialogProps: CustomConfirmationDialogProps = {
      title: "Are You Sure! ",
      content: "The project will be deleted permanently.",
      icon: Exclamation,
      onClose: onCloseCancelSave,
      onConfirm: () => deleteReport(auditId, typeOfAudit),
      isConfirmForDelete: true,
    };
    dispatchRecommendations(showConfirmation(customConfirmationDialogProps));
    dispatchRecommendations(showLoader());
  }
  function onCloseCancelSave() {
    dispatchRecommendations(hideLoader());
  }

  const handleSuccessDialogClose = (): void => {
    setOpenSuccessDialog(false);
  };

  interface DeleteSuccessDailogProps {
    open: boolean;
    onClose: () => any;
  }

  function DeleteSuccessDailog(props: DeleteSuccessDailogProps) {
    const { onClose, open } = props;

    const close = () => {
      onClose();
    };

    return (
      <>
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "20px",
            },
          }}
          onClose={close}
          open={open}
        >
          <div
            className="iconimg"
            style={{ paddingTop: "1rem" }}
          >
            <img
              onClick={close}
              src={SavingsSaved}
              height="35"
              alt="saved-icon"
              loading="lazy"
              decoding="async"
            />
          </div>

          <div style={{ padding: "10px 20px 20px 20px", textAlign: "center" }}>
            <ConfirmDelete
              message={`Project deleted successfully!`}
              textCss="bold-message-report"
              CloseDeleteDailog={close}
              CloseRecommendationDailog={close}
              btnText="Okay"
              btnCss="cancel-button custom-buttons"
              hideCancel={false}
              variantCSS="outlined"
            ></ConfirmDelete>
          </div>
        </Dialog>
      </>
    );
  }

  const loadAirAuditData = useCallback((): void => {
    if (value === 0) {
      let body = {
        page: airAuditPage,
        pageSize: 20,
        sort: airAuditSortingColumn,
        order: airAuditSortingOrder,
        c4CId: airAuditFilterC4cId ? airAuditFilterC4cId : "",
        auditId: airAuditFilterAuditId ? airAuditFilterAuditId : "",
        // status: airAuditFilterStatus ? airAuditFilterStatus : '',
        auditType: airAuditFilterAuditType ? airAuditFilterAuditType : "",
        responsible: airAuditFilterResponsible ? airAuditFilterResponsible : "",
        uploadedDate: airAuditFilterUploadedDate
          ? airAuditFilterUploadedDate === "clear"
            ? ""
            : airAuditFilterUploadedDate
          : "",
        auditName: airAuditFilterAuditName ? airAuditFilterAuditName : "",
        customerName: airAuditFilterCustomer ? airAuditFilterCustomer : "",
        city: airAuditFilterCity ? airAuditFilterCity : "",
        country: airAuditFilterCountry ? airAuditFilterCountry : "",
        projectSource: airAuditFilterProjectSource
          ? airAuditFilterProjectSource
          : "",
      };

      request({
        url: RequestApi.GET_AIR_AUDIT_FINISHED_REPORT_LIST,
        method: "post",
        data: body,
      })
        .then((resp) => {
          setAirAuditData(resp.data.data);
          setAirAuditTotalRecords(resp.data.totalRecord);
        })
        .catch((err) => {
          if (err.code === "ERR_BAD_RESPONSE") {
            console.log(err);
            setAlert("error", "Request Failed", true);
          } else {
            console.log(err);
            setAlert("error", "Request Failed", true);
          }
        });
    }
  }, [
    airAuditFilterAuditId,
    airAuditFilterAuditName,
    airAuditFilterAuditType,
    airAuditFilterC4cId,
    airAuditFilterCity,
    airAuditFilterCountry,
    airAuditFilterCustomer,
    airAuditFilterResponsible,
    // airAuditFilterStatus,
    airAuditFilterUploadedDate,
    airAuditPage,
    airAuditSortingColumn,
    airAuditSortingOrder,
    airAuditFilterProjectSource,
    request,
    value,
  ]);

  const loadVibrationAuditData = useCallback((): void => {
    if (value === 1) {
      let body = {
        page: vibrationAuditPage,
        pageSize: 20,
        sort: vibrationAuditSortingColumn,
        order: vibrationAuditSortingOrder,
        c4CId: vibrationAuditFilterC4cId ? vibrationAuditFilterC4cId : "",
        auditId: vibrationAuditFilterAuditId ? vibrationAuditFilterAuditId : "",
        // status: vibrationAuditFilterStatus ? vibrationAuditFilterStatus : '',
        auditType: vibrationAuditFilterAuditType
          ? vibrationAuditFilterAuditType
          : "",
        responsible: vibrationAuditFilterResponsible
          ? vibrationAuditFilterResponsible
          : "",
        uploadedDate: vibrationAuditFilterUploadedDate
          ? vibrationAuditFilterUploadedDate === "clear"
            ? ""
            : vibrationAuditFilterUploadedDate
          : "",
        auditName: vibrationAuditFilterAuditName
          ? vibrationAuditFilterAuditName
          : "",
        machineModel: vibrationAuditFilterMachineModel
          ? vibrationAuditFilterMachineModel
          : "",
        serialNumber: vibrationAuditFilterSerialNumber
          ? vibrationAuditFilterSerialNumber
          : "",
        rangeResult: vibrationAuditFilterRangeResult
          ? vibrationAuditFilterRangeResult
          : "",
        customerName: vibrationAuditFilterCustomer
          ? vibrationAuditFilterCustomer
          : "",
        projectSource: vibrationAuditFilterProjectSource
          ? vibrationAuditFilterProjectSource
          : "",
      };

      request({
        url: RequestApi.GET_VIBRATION_AUDIT_FINISHED_REPORT_LIST,
        method: "post",
        data: body,
      })
        .then((resp) => {
          setVibrationAuditData(resp.data.data);
          setVibrationAuditTotalRecords(resp.data.totalRecord);
        })
        .catch((err) => {
          if (err.code === "ERR_BAD_RESPONSE") {
            console.log(err);
            setAlert("error", "Request Failed", true);
          } else {
            console.log(err);
            setAlert("error", "Request Failed", true);
          }
        });
    }
  }, [
    request,
    value,
    vibrationAuditFilterAuditId,
    vibrationAuditFilterAuditName,
    vibrationAuditFilterAuditType,
    vibrationAuditFilterC4cId,
    vibrationAuditFilterCustomer,
    vibrationAuditFilterMachineModel,
    vibrationAuditFilterRangeResult,
    vibrationAuditFilterResponsible,
    vibrationAuditFilterSerialNumber,
    // vibrationAuditFilterStatus,
    vibrationAuditFilterUploadedDate,
    vibrationAuditPage,
    vibrationAuditSortingColumn,
    vibrationAuditSortingOrder,
    vibrationAuditFilterProjectSource,
  ]);

  useEffect(() => {
    setGetAirData(true);
    setSelectedTabName(ShowAuditTypesOnTabConstants.Air_Scan);
  }, []);

  useEffect(() => {
    if (value === 0) {
      if (getAirData) {
        loadAirAuditData();
      }
    } else if (value === 1) {
      if (getVibrationData) {
        loadVibrationAuditData();
      }
    }
    return () => {
      setGetAirData(false);
      // setGetVibrationData(false);
    };
  }, [
    value,
    loadAirAuditData,
    getAirData,
    getVibrationData,
    loadVibrationAuditData,
  ]);

  const clearAllAirAuditSorting = useCallback(() => {
    setAirAuditPage(0);
    setAirAuditFilterAuditId("");
    setAirAuditFilterAuditName("");
    setAirAuditFilterAuditType("");
    setAirAuditFilterC4cId("");
    setAirAuditFilterResponsible("");
    // setAirAuditFilterStatus('');
    setAirAuditSortingColumn("");
    setAirAuditSortingOrder("");
    setAirFilterLocalDate("");
    setAirAuditFilterUploadedDate("");
    setAirAuditFilterCustomer("");
    setAirAuditFilterCity("");
    setAirAuditFilterCountry("");
    setAirAuditFilterProjectSource("");
    setGetAirData(true);
  }, []);

  // useEffect(() => {
  //   if (clearAllFilters) {
  //     console.log('clr');
  //     setPage(0);
  //     loadAirAuditData();
  //   }
  //   return () => {
  //     setClearAllFilters(false);
  //   };
  // }, [clearAllAirAuditSorting, clearAllFilters, loadAirAuditData]);

  const clearAllVibrationAuditSorting = useCallback(() => {
    setVibrationAuditPage(0);
    setVibrationAuditFilterAuditId("");
    setVibrationAudiFiltertAuditName("");
    setVibrationAuditFilterAuditType("");
    setVibrationAuditFilterC4cId("");
    setVibrationAuditFilterResponsible("");
    // setVibrationAuditFilterStatus('');
    setVibrationAuditSortingColumn("");
    setVibrationAuditSortingOrder("");
    setVibrationAuditLocalDate("");
    setVibrationAuditFilterUploadedDate("");
    setVibrationAuditFilterCustomer("");
    setVibrationAuditFilterCustomer("");
    setVibrationAuditFilterMachineModel("");
    setVibrationAuditFilterRangeResult("");
    setVibrationAuditFilterSerialNumber("");
    setVibrationAuditFilterProjectSource("");
    setGetVibrationData(true);
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);

    if (newValue === 0) {
      setGetAirData(true);
      setSelectedTabName(ShowAuditTypesOnTabConstants.Air_Scan);
    } else if (newValue === 1) {
      setGetVibrationData(true);
      setSelectedTabName(ShowAuditTypesOnTabConstants.Vibration_Audit);
    } else if (newValue === 2) {
      setGetVibrationData(true);
      setSelectedTabName(ShowAuditTypesOnTabConstants.Airnet);
    } else if (newValue === 3) {
      setGetVibrationData(true);
      setSelectedTabName(ShowAuditTypesOnTabConstants.Enery_Recovery);
    }
  };

  const handleGenerateLeadClick = (event: React.SyntheticEvent) => {};
  const handleDownloadClick = (
    auditId: number,
    auditNumber: string,
    event: React.SyntheticEvent
  ) => {
    request({
      url: `${RequestApi.GET_AUDIT_REPORT_LIST}?AuditId=${auditId}`,
      method: "get",
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${selectedTabName}-${auditNumber} reports.zip`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setAlert("success", "Downloaded successfully", true);
      })
      .catch((err) => {
        console.log(err, "errr");
        setAlert("error", "Request Failed", true);
      });
  };

  const handleEditClick = (
    auditId: number,
    auditNumber: string,
    event: any,
    row: any
  ) => {
    setLoading(true);
    var data: UploadFinishedReportDataModel = {
      customerName: "",
      c4cId: "",
      auditId: auditId,
    };

    dispatch(setUploadFinishedReportsData(data));

    window.sessionStorage.setItem("con", row.country);
    if (row?.ongoingProjectId && row?.ongoingProjectId > 0) {
      navigation(
        `/${RoutingConstants.OngoingProject}/${RoutingConstants.UpdateOngoingProject}/${row?.ongoingProjectId}`
      );
      return;
    }
    if (
      row.projectSource.replace(/ /g, "").toLowerCase() ===
      ProjectSourceConstants.LinkProject.replace(/ /g, "").toLowerCase()
    ) {
      navigation(
        "/" +
          RoutingConstants.NewProject +
          "/" +
          RoutingConstants.LinkProjectUpdate +
          "/" +
          auditId
      );
    } else {
      navigation(
        "/" +
          RoutingConstants.NewProject +
          "/" +
          RoutingConstants.UpdateFinishedReport +
          "/" +
          auditId
      );
    }
  };

  const handleDeleteClick = (
    auditId: number,
    auditNumber: string,
    event: any,
    row: any,
    typeOfAudit: string
  ) => {
    deleteReportConfirm(auditId, typeOfAudit);
  };

  const deleteReport = (auditId: any, typeOfAudit: any) => {
    let body = {
      AuditId: auditId,
    };

    request({
      url: RequestApi.DELETE_AUDIT_DETAILS_BY_AUDITID,
      method: "delete",
      data: body,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((resp) => {
        if (typeOfAudit === AuditTypes.Air_Audit) {
          loadAirAuditData();
        } else if (typeOfAudit === AuditTypes.Vibration_Audit) {
          loadVibrationAuditData();
        }
        onCloseCancelSave();
        setTimeout(() => {
          setOpenSuccessDialog(true);
        }, 1000);
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_RESPONSE") {
          console.log(err);
          setAlert("error", "Request Failed", true);
        } else {
          console.log(err);
          setAlert("error", "Request Failed", true);
        }
      });
  };
  const handleForwardClick = (event: React.SyntheticEvent) => {};
  const handleFilterChange = (column: string, event: any) => {
    if (column === "c4CId") {
      setAirAuditFilterC4cId(event.target.value);
    } else if (column === "status") {
      // setAirAuditFilterStatus(event.target.value);
    } else if (column === "uploadedDate") {
      if (event && event.$y > 1800 && event.$d !== "Invalid Date") {
        setAirAuditFilterUploadedDate(dayjs(event.$d).format("DD/MM/YYYY"));
        setAirFilterLocalDate(event.$d);
      } else {
        setAirAuditFilterUploadedDate("");
        setAirFilterLocalDate("");
      }
    } else if (column === "auditId") {
      setAirAuditFilterAuditId(event.target.value);
    } else if (column === "auditType") {
      setAirAuditFilterAuditType(event.target.value);
    } else if (column === "responsible") {
      setAirAuditFilterResponsible(event.target.value);
    } else if (column === "auditName") {
      setAirAuditFilterAuditName(event.target.value);
    } else if (column === "customerName") {
      setAirAuditFilterCustomer(event.target.value);
    } else if (column === "city") {
      setAirAuditFilterCity(event.target.value);
    } else if (column === "country") {
      setAirAuditFilterCountry(event.target.value);
    } else if (column === "projectSource") {
      setAirAuditFilterProjectSource(event.target.value);
    }
  };

  const handleVibrationFilterChange = (column: string, event: any) => {
    if (column === "c4CId") {
      setVibrationAuditFilterC4cId(event.target.value);
    } else if (column === "status") {
      // setVibrationAuditFilterStatus(event.target.value);
    } else if (column === "uploadedDate") {
      if (event && event.$y > 1800 && event.$d !== "Invalid Date") {
        setVibrationAuditFilterUploadedDate(
          dayjs(event.$d).format("DD/MM/YYYY")
        );
        setVibrationAuditLocalDate(event.$d);
      } else {
        setVibrationAuditFilterUploadedDate("");
        setVibrationAuditLocalDate("");
      }
    } else if (column === "auditType") {
      setVibrationAuditFilterAuditType(event.target.value);
    } else if (column === "responsible") {
      setVibrationAuditFilterResponsible(event.target.value);
    } else if (column === "auditName") {
      setVibrationAudiFiltertAuditName(event.target.value);
    } else if (column === "auditId") {
      setVibrationAuditFilterAuditId(event.target.value);
    } else if (column === "serialNumber") {
      setVibrationAuditFilterSerialNumber(event.target.value);
    } else if (column === "machineModel") {
      setVibrationAuditFilterMachineModel(event.target.value);
    } else if (column === "rangeResult") {
      if (event.target.value === 1) {
        setVibrationAuditFilterRangeResult("low");
      } else if (event.target.value === 2) {
        setVibrationAuditFilterRangeResult("medium");
      } else if (event.target.value === 3) {
        setVibrationAuditFilterRangeResult("critical");
      } else {
        setVibrationAuditFilterRangeResult("");
      }
    } else if (column === "customerName") {
      setVibrationAuditFilterCustomer(event.target.value);
    } else if (column === "projectSource") {
      setVibrationAuditFilterProjectSource(event.target.value);
    }
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const actionButtons: TableActionButtons[] = [
    {
      text: "RecommendationList",
      name: "Open Product Recommendation List",
      icon: generateLeadIcon,
      handleClick: handleGenerateLeadClick,
      permission: finishedReportPermission.update,
    },
    {
      text: "DownloadProject",
      name: "Download Project",
      icon: downloadIcon,
      handleClick: handleDownloadClick,
      permission: null,
    },
    {
      text: "EditProject",
      name: "Edit Project",
      icon: fileEditIcon,
      handleClick: handleEditClick,
      permission: finishedReportPermission.update,
    },
    {
      text: "DeleteProject",
      name: "Delete Project",
      icon: deleteIcon,
      handleClick: handleDeleteClick,
      permission: finishedReportPermission.delete,
    },
    // {
    //   text: "SendToSmartLink",
    //   name: "Send Report To SmartLink",
    //   icon: forwardIcon,
    //   handleClick: handleForwardClick,
    // },
  ];

  const airNetActionButtons = actionButtons.filter(
    (x) => x.text != "RecommendationList" && x.text != "DeleteProject"
  );

  const energyRecoveryActionButtons = actionButtons.filter(
    (x) => x.text != "RecommendationList" && x.text != "DeleteProject"
  );

  return (
    <>
      <CustomAlertTest
        severity={alertSeverity}
        open={alertOpen}
        message={alertMessage}
        onClose={handleAlertClose}
      />
      <Box sx={{ borderBottom: 1, borderColor: "divider", border: "none" }}>
        <Tabs
          sx={tab_Style}
          value={value}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab
            sx={{ textTransform: "none" }}
            label={ShowAuditTypesOnTabConstants.Air_Scan}
            {...a11yProps(0)}
          />
          <Tab
            sx={{ textTransform: "none", display: "none" }}
            label={ShowAuditTypesOnTabConstants.Vibration_Audit}
            {...a11yProps(1)}
          />
          <Tab
            sx={{ textTransform: "none" }}
            label={ShowAuditTypesOnTabConstants.Airnet}
            {...a11yProps(2)}
          />
          <Tab
            sx={{ textTransform: "none" }}
            label={ShowAuditTypesOnTabConstants.Enery_Recovery}
            {...a11yProps(3)}
          />
        </Tabs>
      </Box>
      <Grid
        md={12}
        style={{ height: "13px", backgroundColor: "#F3F3F3" }}
      ></Grid>
      <CustomTabPanel
        value={value}
        index={0}
      >
        <CustomTable
          isInsideTab={true}
          data={airAuditData}
          filterChange={handleFilterChange}
          actionButtons={actionButtons}
          columns={columns}
          setPage={setAirAuditPage}
          clearAll={clearAllAirAuditSorting}
          page={airAuditPage}
          sortColumn={airAuditSortingColumn}
          setSortColumn={setAirAuditSortingColumn}
          sortColumnOrder={airAuditSortingOrder}
          setSortColumnOrder={setAirAuditSortingOrder}
          filterObject={airAuditfilterObject}
          stickyActionColumn={true}
          actionColumnWidth={"155px"}
          loadDataFunction={loadAirAuditData}
          totalRecords={airAuditTotalRecords}
          setGetData={setGetAirData} // sortingChangeHandler={airAuditSortingChangeHandler}
          typeOfAudit={AuditTypes.Air_Audit}
          pageName={PageConstants.FinishedReports}
        />
      </CustomTabPanel>

      <CustomTabPanel
        value={value}
        index={1}
      >
        <CustomTable
          isInsideTab={true}
          data={vibrationAuditData}
          filterChange={handleVibrationFilterChange}
          actionButtons={actionButtons}
          columns={vibrationColumns}
          filterObject={vibrationAuditfilterObject}
          setPage={setVibrationAuditPage}
          sortColumn={vibrationAuditSortingColumn}
          setSortColumn={setVibrationAuditSortingColumn}
          sortColumnOrder={vibrationAuditSortingOrder}
          setSortColumnOrder={setVibrationAuditSortingOrder}
          page={vibrationAuditPage}
          clearAll={clearAllVibrationAuditSorting}
          finishedReportsPage={true}
          stickyActionColumn={true}
          actionColumnWidth={"155px"}
          loadDataFunction={loadVibrationAuditData}
          totalRecords={vibrationAuditTotalRecords}
          setGetData={setGetVibrationData}
          typeOfAudit={AuditTypes.Vibration_Audit}
          pageName={PageConstants.FinishedReports}
        />
      </CustomTabPanel>
      <CustomTabPanel
        value={value}
        index={2}
      >
        <AirNet
          actionButtons={airNetActionButtons}
          airNetAuditFilters={airNetAuditFilters}
        />
      </CustomTabPanel>
      <CustomTabPanel
        value={value}
        index={3}
      >
        <EnergyRecovery
          actionButtons={energyRecoveryActionButtons}
          energyRecoveryAuditFilters={energyRecoveryAuditFilters}
        />
      </CustomTabPanel>
      <DeleteSuccessDailog
        open={openSuccessDialog}
        onClose={handleSuccessDialogClose}
      />
    </>
  );
};

export default FinishedReports;
