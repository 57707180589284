import { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import classes from "./FinishedReports/FinishedReports.module.css";
import CustomInput from "../common/components/CustomComponents/CustomInput";
import {
  Controller,
  FormProvider,
  UseFormSetValue,
  useFieldArray,
  useForm,
} from "react-hook-form";
import search_icon from "../assets/icons/search_icon.svg";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { CustomConfirmationDialogProps } from "../common/components/CustomComponents/ConfirmationDialog";
import Exclamation from "../assets/icons/Red_Exclaimation_mark.svg";
import Note from "../assets/icons/note.svg";
import RedNote from "../assets/icons/Note_Red.svg";
import linkProjectIcon from "../assets/icons/vectorlinkproject.svg";
import ContriesAutoComplete from "../common/components/CustomComponents/CountriesAutoComplete";
import {
  TableActionButtons,
  TableColumn,
} from "../common/interface/TableColumn";
import CustomTable from "../common/components/CustomComponents/CustomTable";
import CustomRibbon from "../common/components/CustomComponents/CustomRibbon";
import CustomMessageDialog from "../common/components/CustomComponents/CustomMessageDialog";
import DropdownModel from "../common/interface/DropdownModel";
import CustomSelect from "../common/components/CustomComponents/CustomSelect";
import React from "react";
import { PageConstants } from "../common/PageConstants";
import { Navigate, useNavigate } from "react-router";
import { RequestApi } from "../common/Constant";
import { useRequest } from "../services/agent";
import CustomAlertTest from "../common/components/CustomComponents/CustomAlert";
import { RoutingConstants } from "../common/RoutingConstants";
import { useCountries } from "../services/countries";

export interface LinkProjectData {
  c4CId: string;
  customerName: string;
  country: string;
  city?: string;
  address?: string;
  famCode: string;
  soldTo?: string;
  leadId: number;
  leadStatus?: string;
  leadCreatedOn?: string;
  opportunityId: number;
  opportunityStatus?: string;
  totalAmountCurrencyCode?: string;
  totalAmount: number;
  totalExpectedValue: number;
  opportunityCreatedOn?: string;
  salesPhase?: string;
  leadModifiedOn?: string;
}

export enum Id {
  LeadId = "LeadId",
  opportunityId = "opportunityId",
}

export enum SearchById {
  LeadId = "1",
  opportunityId = "2",
}
export enum OpportunityStatus {
  Won = "Won",
  Lost = "Lost",
  Open = "Open",
  InProcess = "InProcess",
  Stopped = "Stopped",
}

export enum LeadStatus {
  Open = "Open",
  Converted = "Converted",
  Qualified = "Qualified",
  Accepted = "Accepted",
  Declined = "Declined",
  Deleted = "Deleted",
}

const defaultRibbonStyle = {
  ribbonStyle: {
    bgColor: "#F2F7FB",
    borderColor: "#0078A1",
    paraColor: "#0078A1",
    icon: Note,
  },
  content: [],
};

export type tableColumns =
  | "opportunityId"
  | "leadId"
  | "c4CId"
  | "customerName"
  | "opportunityStatus"
  | "leadStatus"
  | "country"
  | "city"
  | "address"
  | "soldTo";
export default function LinkProject(props: any) {
  const [getData, setData] = useState(true);
  const [label, setLabel] = useState<"Lead ID" | "Opportunity ID">(
    "Opportunity ID"
  );
  const navigation = useNavigate();
  const [linkProjectData, setLinkProjectData] = useState<
    Array<LinkProjectData>
  >([]);
  const [ribbonContent, setRibbonContent] = useState<any>(defaultRibbonStyle);
  const [searchId, setSearchId] = useState<Id>();
  const [tableColumns, setTableColumns] = useState<TableColumn[]>([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState<"success" | "error">(
    "success"
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };
  const request = useRequest();
  const countries = useCountries();
  const validationSchema = Yup.object().shape({
    country: Yup.string().required("This field is required"),
    ValueOfId: Yup.string().required("This field is required"),
    SearchBy: Yup.string().required("This field is required"),
  });

  const methods = useForm<any>({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      SearchBy: "2",
    },
  });

  const { control, setValue, getValues } = methods;

  const options = [
    { valueInt: 1, text: "Lead ID" },
    { valueInt: 2, text: "Opportunity ID" },
  ] as DropdownModel[];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        // marginRight: 10,
      },
    },
  };

  function setAlert(severity: any, message: string, OCflag: boolean) {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(OCflag);
    handleCloseDialog();
  }

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const paraErrorMessges: any[] = [
    <p>
      {" "}
      <b>ERROR</b> Server Down
    </p>,
    <p>
      Intelligence Hub server is currently down. Please try again after some
      time.{" "}
    </p>,
  ];

  useEffect(() => {
    let IsOpportunity = true;

    if (
      !linkProjectData[0]?.opportunityId ||
      linkProjectData[0]?.opportunityId == 0
    ) {
      IsOpportunity = false;
    }

    if (linkProjectData?.length > 0) {
      let columns: TableColumn[] = [
        {
          id: (IsOpportunity ? "opportunityId" : "leadId") as tableColumns,
          label: IsOpportunity ? "Opportunity ID" : "Lead ID",
          align: "left",
          minWidth: 70,
          filter: false,
          sorting: false,
        },
        {
          id: (IsOpportunity
            ? "opportunityStatus"
            : "leadStatus") as tableColumns,
          label: IsOpportunity ? "Opportunity Status" : "Lead Status",
          align: "left",
          minWidth: 80,
          filter: false,
          sorting: false,
        },
        {
          id: "c4CId" as tableColumns,
          label: "Account ID",
          align: "left",
          minWidth: 64,
          filter: false,
          sorting: false,
        },
        {
          id: "customerName" as tableColumns,
          label: "Customer Name",
          align: "left",
          minWidth: 230,
          filter: false,
          sorting: false,
        },

        {
          id: "country" as tableColumns,
          label: "Country",
          align: "left",
          minWidth: 93,
          filter: false,
          sorting: false,
        },
        {
          id: "city" as tableColumns,
          label: "City",
          align: "left",
          minWidth: 85,
          filter: false,
          sorting: false,
        },
        {
          id: "address" as tableColumns,
          label: "Address",
          align: "left",
          minWidth: 245,
          filter: false,
          sorting: false,
        },
        {
          id: "soldTo" as tableColumns,
          label: "External ID (Sold To)",
          align: "left",
          minWidth: 70,
          filter: false,
          sorting: false,
        },
      ];
      setTableColumns([...columns]);
    }
  }, [linkProjectData]);

  function handleLinkProjectClick(...args: any[]) {
    const linkProjectDetails: LinkProjectData = args[3];
    console.log(args[3]);
    window.sessionStorage.setItem("cn", linkProjectDetails?.customerName);
    window.sessionStorage.setItem("ci", linkProjectDetails?.c4CId);

    window.sessionStorage.setItem(
      "oid",
      linkProjectDetails?.opportunityId?.toString() || ""
    );
    window.sessionStorage.setItem(
      "lid",
      linkProjectDetails?.leadId?.toString() || ""
    );
    window.sessionStorage.setItem(
      "tqv",
      linkProjectDetails?.totalAmount?.toString()
    );
    window.sessionStorage.setItem(
      "tev",
      linkProjectDetails?.totalExpectedValue?.toString()
    );
    window.sessionStorage.setItem("ls", linkProjectDetails?.leadStatus || "");
    window.sessionStorage.setItem(
      "os",
      linkProjectDetails?.opportunityStatus || ""
    );
    window.sessionStorage.setItem("st", linkProjectDetails?.soldTo || "");
    window.sessionStorage.setItem("con", linkProjectDetails?.country);
    window.sessionStorage.setItem("ct", linkProjectDetails?.city || "");
    window.sessionStorage.setItem("ad", linkProjectDetails?.address || "");
    window.sessionStorage.setItem("fam", linkProjectDetails?.famCode || "");
    navigation(
      "/" +
        RoutingConstants.NewProject +
        "/" +
        RoutingConstants.LinkProjectUpload
    );
  }

  const actionButtons: TableActionButtons[] = [
    {
      name: "",
      icon: linkProjectIcon,
      handleClick: handleLinkProjectClick,
      permission: true,
      textInsideButton: <span style={{ marginLeft: "5px" }}>Link Project</span>,
    },
  ];

  function renderParaMessges(data: any) {
    let convertedRibbonStyle = {
      bgColor: "#F2F7FB",
      borderColor: "#0078A1",
      paraColor: "#0078A1",
      icon: Note,
    };

    let warningRibbonStyle = {
      bgColor: "#ffe6e6",
      borderColor: "#ff9999",
      paraColor: "#b30000",
      icon: RedNote,
    };

    const {
      leadStatus,
      leadId,
      opportunityId,
      opportunityStatus,
      leadModifiedOn,
      leadCreatedOn,
    } = data;

    const SearchBy = methods.getValues("SearchBy");
    if (SearchBy == SearchById.LeadId) {
      const twoYearAgo = new Date().setFullYear(new Date().getFullYear() - 2);

      const isActive =
        (leadModifiedOn && new Date(leadModifiedOn).getTime() > twoYearAgo) ||
        (leadCreatedOn && new Date(leadCreatedOn).getTime() > twoYearAgo);

      if (leadStatus == LeadStatus.Converted) {
        return {
          ribbonStyle: convertedRibbonStyle,
          content: [
            <p>
              {" "}
              <b>UPDATE</b> Lead Converted
            </p>,
            <p>
              The lead is already converted to Opportunity (
              <b>Opportunity ID - {opportunityId}</b>). You can go ahead and
              link project to opportunity.
            </p>,
          ],
        };
      } else if (
        leadStatus == LeadStatus.Deleted ||
        leadStatus == LeadStatus.Declined
      ) {
        return {
          ribbonStyle: warningRibbonStyle,
          content: [
            <p>
              Lead ID
              <b style={{ margin: "0 0.25em" }}>{leadId}</b>is in
              <b style={{ margin: "0 0.25em" }}>{leadStatus}</b>status{" "}
              {!isActive && " and has been inactive for more than 2 years."}
            </p>,
            <p>If you would like to continue, please proceed.</p>,
          ],
        };
      } else {
        if (!isActive) {
          return {
            ribbonStyle: warningRibbonStyle,
            content: [
              <p>
                Lead ID
                <b style={{ margin: "0 0.25em" }}>{leadId}</b>has been inactive
                for more than 2 years.
              </p>,
              <p>If you would like to continue, please proceed.</p>,
            ],
          };
        }
      }
    } else {
      if (
        opportunityStatus == OpportunityStatus.Stopped ||
        opportunityStatus == OpportunityStatus.Lost
      ) {
        return {
          ribbonStyle: warningRibbonStyle,
          content: [
            <p>
              Opportunity ID
              <b style={{ margin: "0 0.25em" }}>{opportunityId}</b>is in
              <b style={{ margin: "0 0.25em" }}>{opportunityStatus}</b>status
            </p>,
            <p>If you would like to continue, please proceed.</p>,
          ],
        };
      }
    }
    return defaultRibbonStyle;
  }

  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value;
    setLabel(value === "1" ? "Lead ID" : "Opportunity ID");
    setValue("SearchBy", value);
  };

  function submitData(data: any) {
    let typeOfId = data.SearchBy == 1 ? Id.LeadId : Id.opportunityId;
    setSearchId(typeOfId);
    setRibbonContent({ ...defaultRibbonStyle });
    request({
      url: RequestApi.GET_LINK_PROJECT_LIST,
      method: "post",
      data: { ...data, [typeOfId]: data.ValueOfId.trim() },
    })
      .then((resp) => {
        let { lead, opportunity, customer } = resp.data;
        customer = {
          ...customer,
          country: countries.getCountryName(customer.country),
        };
        setLinkProjectData([{ ...lead, ...opportunity, ...customer }]);
        setRibbonContent(renderParaMessges({ ...lead, ...opportunity }));
      })
      .catch((err) => {
        setLinkProjectData([]);
        if (err.response?.status === 400) {
          let responseArr = err.response.data;

          if (Array.isArray(responseArr.error)) {
            responseArr?.error?.forEach((element: any) => {
              setAlert("error", element, true);
            });
          }

          for (let key in responseArr.result) {
            if (Array.isArray(responseArr.result[key])) {
              responseArr.result[key].forEach((value: any) => {
                setAlert("error", value, true);
              });
            }
          }
        }
      });
  }

  return (
    <>
      <CustomAlertTest
        severity={alertSeverity}
        open={alertOpen}
        message={alertMessage}
        onClose={handleAlertClose}
        hideTime={5000}
      />
      <Box
        sx={{
          boxShadow: 0,
          borderBottom: 0,
          backgroundColor: "#fff",
          borderRadius: "8px",
          border: "none",
          paddingTop: "14px",
          paddingBottom: "7px",
        }}
        minWidth={"600px"}
        px={1}
        className={classes["form-container"]}
      >
        <FormProvider {...methods}>
          <form
            style={{ height: "auto" }}
            onSubmit={methods.handleSubmit(submitData)}
          >
            <Grid
              className="grid-container"
              container
              spacing={4}
              rowSpacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 2 }}
            >
              <ContriesAutoComplete
                setValue={methods.setValue}
                data={props.countries.countriesData}
              />
            </Grid>
            <Grid
              container
              py={0.85}
              columnSpacing={2}
              sx={{ paddingBottom: "14px !important" }}
            >
              <Grid
                item
                xs={12}
                md={4}
                xl={4}
                direction="row"
              >
                <FormControl
                  variant="outlined"
                  fullWidth
                >
                  <FormLabel className="labels">
                    {"Search By"}
                    {<span style={{ color: "red", marginLeft: "3px" }}>*</span>}
                  </FormLabel>
                  <Controller
                    name={"SearchBy"}
                    control={control}
                    render={({ field }) => (
                      <Select
                        fullWidth
                        value={field.value ? String(field.value) : ""}
                        MenuProps={MenuProps}
                        name={"SearchBy"}
                        displayEmpty
                        onBlur={field.onBlur}
                        onChange={(e) => {
                          field.onChange(e);
                          handleChange(e);
                        }}
                        id={"SearchBy"}
                        renderValue={(selected) => {
                          return <p>{label}</p>;
                        }}
                      >
                        {options?.map((option) => (
                          <MenuItem
                            key={option.valueInt}
                            value={option?.valueInt?.toString()}
                            disableRipple
                          >
                            {option.text}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <CustomInput
                md={4}
                isRequired={true}
                label={label}
                name="ValueOfId"
                variant="outlined"
                numberOnly={true}
                fullWidth
              />
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  startIcon={
                    <img
                      src={search_icon}
                      alt="search-icon"
                      style={{
                        width: "16px",
                        height: "16px",
                      }}
                      loading="lazy"
                      decoding="async"
                    />
                  }
                  sx={{
                    padding: "10px 12px !important",
                    color: "#FFF",
                    fontSize: 18,
                    fontWeight: 400,
                    height: "35px !important",
                    lineHeight: "20px",
                    textTransform: "none",
                    boxShadow: "none !important",
                    gap: "6px !important",
                    marginTop: "20px",
                  }}
                  disableRipple
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
        {ribbonContent?.content?.length > 0 && (
          <CustomRibbon
            messagesInHtmlArray={ribbonContent.content}
            md={12}
            style={{
              height: "auto",
              border: `1px solid ${ribbonContent.ribbonStyle.borderColor}`,
              backgroundColor: ribbonContent.ribbonStyle.bgColor,
              borderRadius: "5px",
              marginBottom: "8px",
            }}
            icon={ribbonContent.ribbonStyle.icon}
            iconHeight={18}
            paraColor={ribbonContent.ribbonStyle.paraColor}
          />
        )}
      </Box>
      {tableColumns?.length > 0 && linkProjectData?.length > 0 && (
        <Box
          sx={{
            maxHeight: ribbonContent?.content?.length > 0 ? "60vh" : "70vh",
            overflowY: "hidden",
          }}
        >
          <CustomTable
            data={linkProjectData}
            isInsideTab={true}
            actionButtons={actionButtons}
            columns={tableColumns}
            setGetData={setData}
            showPagination={false}
            stickyActionColumn={false}
            showFilters={false}
            pageName={PageConstants.LinkProject}
          />
        </Box>
      )}
    </>
  );
}
