import {
  Grid,
  Accordion,
  AccordionSummary,
  SvgIcon,
  Typography,
  AccordionDetails,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
} from "@mui/material";
import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  RcommendationsColumn,
  recommendationTableColumns,
} from "../../../features/UploadFinishedReports_New";
import { useAppDispatch } from "../../hooks/hooks";
import { RootState } from "../../store";
import {
  AuditRecommendations,
  hideSaveAlert,
  deleteRecommendations,
} from "../../store/addRecommendationsDataSlice";
import { showConfirmation } from "../../store/confirmationBoxDataSlice";
import { showLoader, hideLoader } from "../../store/loaderDataSlice";
import { CustomConfirmationDialogProps } from "./ConfirmationDialog";
import RecommendationClasses from "../../../features/UploadFinishedReports.module.css";
import CustomAlertTest from "./CustomAlert";
import Exclamation from "../../../assets/icons/Red_Exclaimation_mark.svg";

import { ReactComponent as ExpandMoreIcon } from "../../../assets/icons/down-arrow.svg";
import EditIcon from "../../../assets/icons/edit-icon.svg";
import DeleteIcon from "../../../assets/icons/delete-icon.svg";
import AddIcon from "../../../assets/icons/Add_Circle_icon.svg";
import { TableColumn } from "../../interface/TableColumn";

export function AddProductRecommendation({
  open,
  setOpen,
  isEdit,
  setIsEdit,
  isEditReportPage,
  needMarginTop = true,
  isOngoingEditPage = false,
  setRecommendationIndex = null,
  currencyText = "",
}: any) {
  const [expandRecommendationList, setExpandRecommendationList] =
    useState(true);
  const [recommendationList, setRecommendationList] = useState<
    AuditRecommendations[]
  >([]);

  const dispatchRecommendations = useAppDispatch();
  let recommendationData = useSelector(
    (state: RootState) => state.auditRecommendationsReducer.recommendations
  );
  let showRecommendationsSaveAlert = useSelector(
    (state: RootState) => state.auditRecommendationsReducer.showSavedAlert
  );

  const columns: readonly RcommendationsColumn[] = useMemo(() => {
    const temp: RcommendationsColumn[] = [
      {
        id: "investmentName" as recommendationTableColumns,
        label: "Category of Product",
        align: "left",
        minWidth: 170,
      },
      {
        id: "recommendationName",
        label: "Type of Product",
        align: "left",
        minWidth: 120,
      },
      {
        id: "description",
        label: "Description of Product",
        minWidth: 250,
        align: "left",
        format: (value: number) => value.toLocaleString("en-US"),
      },
      {
        id: "minimumEstimatedSaving",
        label: "Minimum estimated savings(kWh/annum)",
        minWidth: 170,
        align: "left",
        format: (value: number) => value.toFixed(2),
      },
      {
        id: "comments",
        label: "Comments",
        minWidth: 250,
        align: "left",
      },
    ];
    if (isOngoingEditPage)
      temp.splice(3, 0, {
        id: "aproxInvestment",
        label: `Approx. Investment(${currencyText})`,
        minWidth: 170,
        align: "left",
        format: (value: number) => value.toLocaleString("en-US"),
      } as RcommendationsColumn);
    return temp;
  }, [currencyText, isOngoingEditPage]);

  const [alertSeverity, setAlertSeverity] = useState<"success" | "error">(
    "success"
  );
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  function setAlert(severity: any, message: string, OCflag: boolean) {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(OCflag);
  }

  useEffect(() => {
    setRecommendationList(recommendationData);
    setExpandRecommendationList(true);
    if (showRecommendationsSaveAlert) {
      const toasterMsg = `Recommendation Details ${
        isEdit ? "Updated" : "Saved"
      } Successfully`;
      setAlert("success", toasterMsg, true);
      dispatchRecommendations(hideSaveAlert());
    }
  }, [recommendationData]);

  function deleteRecommendationConfirm(recommendation: AuditRecommendations) {
    let customConfirmationDialogProps: CustomConfirmationDialogProps = {
      title: "Are You Sure!",
      content: "If you proceed, the recommendation will be deleted.",
      icon: Exclamation,
      onClose: onCloseCancelSave,
      onConfirm: () => deleteRecommendation(recommendation),
      isConfirmForDelete: true,
    };
    dispatchRecommendations(showConfirmation(customConfirmationDialogProps));
    dispatchRecommendations(showLoader());
  }

  const deleteRecommendation = (recommendation: AuditRecommendations): void => {
    dispatchRecommendations(deleteRecommendations(recommendation));
    dispatchRecommendations(hideLoader());
    setAlert("error", "Recommendations Deleted Successfully", true);
  };

  function onCloseCancelSave() {
    dispatchRecommendations(hideLoader());
  }

  return (
    <>
      <CustomAlertTest
        severity={alertSeverity}
        open={alertOpen}
        message={alertMessage}
        onClose={() => setAlertOpen(false)}
      />
      <Grid
        marginTop={needMarginTop ? "25px" : "unset"}
        item
        xs={12}
        sm={12}
        md={12}
        xl={12}
        lg={12}
        direction="row"
      >
        <Accordion
          expanded={expandRecommendationList}
          sx={{
            "& .MuiAccordionSummary-root.Mui-expanded": {
              minHeight: "49px",
            },
          }}
        >
          <AccordionSummary
            onClick={() => {
              setExpandRecommendationList((x) => !x);
            }}
            sx={{
              "& .Mui-expanded": {
                margin: "10px 0 !important",
              },
            }}
            className={RecommendationClasses["accordion-div"]}
            expandIcon={<SvgIcon component={ExpandMoreIcon}></SvgIcon>}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={RecommendationClasses["title"]}>
              Add Product Recommendation
            </Typography>
            <img
              src={AddIcon}
              height="22"
              alt="Back"
              loading="lazy"
              decoding="async"
              onClick={(event) => {
                event.stopPropagation();
                if (!isOngoingEditPage && recommendationList.length > 0) {
                  setAlert(
                    "error",
                    "Only one recommendation can be added.",
                    true
                  );
                  return;
                }
                setIsEdit(false);
                setOpen(true);
              }}
            ></img>
          </AccordionSummary>
          <AccordionDetails style={{ padding: "0px" }}>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer
                className={RecommendationClasses["table-container-inside-tab"]}
              >
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  sx={{
                    "& .MuiTableRow-hover:hover": {
                      "background-color": "transparent !important",
                    },
                    "& .MuiTableCell-root": {
                      borderLeft: "1px solid #E6E6E6",
                    },
                  }}
                >
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          className={`${
                            RecommendationClasses["table-header"]
                          } ${
                            column.label === "Name"
                              ? RecommendationClasses["stickyHeader-left"]
                              : ""
                          } ${
                            column.label === "action"
                              ? RecommendationClasses["stickyHeader-right"]
                              : ""
                          }`}
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          <span
                            className={RecommendationClasses["table-title"]}
                          >
                            {column.label}
                          </span>
                        </TableCell>
                      ))}
                      <TableCell
                        style={{ minWidth: "100px" }}
                        className={`${RecommendationClasses["table-header"]} ${RecommendationClasses["stickyHeader-right"]}`}
                      >
                        <span className={RecommendationClasses["table-title"]}>
                          Action
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {recommendationList.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                        >
                          {columns.map((column) => {
                            const columnName =
                              column.id as recommendationTableColumns;
                            const value = row[columnName];

                            return (
                              <TableCell
                                className={`${
                                  column.label === "action"
                                    ? RecommendationClasses["sticky-right"]
                                    : ""
                                }`}
                              >
                                {column.id === "description" ||
                                column.id === "comments" ? (
                                  <Tooltip
                                    title={value}
                                    placement="right"
                                    slotProps={{
                                      popper: {
                                        modifiers: [
                                          {
                                            name: "offset",
                                            options: {
                                              offset: [0, -25],
                                            },
                                          },
                                        ],
                                      },
                                    }}
                                  >
                                    <p
                                      className={
                                        RecommendationClasses[
                                          "description-overflow"
                                        ]
                                      }
                                    >
                                      {value}
                                    </p>
                                  </Tooltip>
                                ) : column.format &&
                                  typeof value === "number" ? (
                                  column.format(value)
                                ) : (
                                  value
                                )}
                              </TableCell>
                            );
                          })}
                          <TableCell
                            key={row.id}
                            className={RecommendationClasses["sticky-right"]}
                          >
                            <img
                              style={{ marginRight: "10px", cursor: "pointer" }}
                              src={EditIcon}
                              className={
                                isOngoingEditPage
                                  ? row?.sentToC4C ||
                                    (row?.leadId !== undefined &&
                                      (row.leadId > 0 ||
                                        (row.opportunityId !== undefined &&
                                          row.opportunityId > 0)))
                                    ? "disabled-icons"
                                    : "action-icons"
                                  : ""
                              }
                              height="24"
                              alt="Request Project"
                              loading="lazy"
                              decoding="async"
                              onClick={() => {
                                setIsEdit(true);
                                setOpen(true);
                                if (
                                  typeof setRecommendationIndex === "function"
                                ) {
                                  setRecommendationIndex(index);
                                }
                              }}
                            ></img>
                            <img
                              className={
                                !isOngoingEditPage
                                  ? isEditReportPage
                                    ? "disabled-icons"
                                    : "action-icons"
                                  : row?.sentToC4C ||
                                    (row?.leadId !== undefined &&
                                      (row.leadId > 0 ||
                                        (row.opportunityId !== undefined &&
                                          row.opportunityId > 0)))
                                  ? "disabled-icons"
                                  : "action-icons"
                              }
                              src={DeleteIcon}
                              height="24"
                              alt="Add New"
                              loading="lazy"
                              decoding="async"
                              style={{ cursor: "pointer" }}
                              onClick={() => deleteRecommendationConfirm(row)}
                            ></img>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </>
  );
}
